<template>
  <v-card>
    <v-dialog v-if="download" v-model="internalDialog" max-width="500">
      <v-card>
        <v-container>
          <v-select
            v-model="locale"
            :items="locales"
            data-vv-name="type"
            label="language"
          />
          <v-layout row justify-space-between>
            <v-btn color="primary" :loading="loading" @click="download('FULL')"
              ><v-icon left dark>fad fa-file-pdf</v-icon> FULL</v-btn
            >
            <v-btn
              color="primary"
              :loading="loading"
              @click="download('OVERVIEW')"
              ><v-icon left dark>fad fa-file-pdf</v-icon> OVERVIEW</v-btn
            >
            <v-btn
              color="primary"
              :loading="loading"
              @click="download('DETAILS')"
              ><v-icon left dark>fad fa-file-pdf</v-icon> DETAILS</v-btn
            >
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
export default {
  name: "download-invoice-dialog",
  props: {
    downloadDialog: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      locale: "nl",
      locales: ["nl", "fr"]
    };
  },
  computed: {
    internalDialog: {
      get() {
        return this.downloadDialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    async download(type) {
      this.loading = true;
      await this.$store.dispatch("invoice/pdf", {
        number: this.number,
        type: type,
        locale: this.locale
      });
      this.loading = false;
    }
  }
};
</script>
