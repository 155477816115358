<template>
  <company-filter
    :id="id"
    :company-id="companyId"
    :loading="isLoading"
    @exit="
      $router.push({
        name: 'companies.filters',
        params: { companyId: companyId }
      })
    "
    @save="save"
  />
</template>

<script type="text/babel">
import CompanyFilter from "@/components/filter/Filter";

export default {
  name: "company-filter-view",
  components: { CompanyFilter },
  props: {
    id: Number,
    companyId: Number
  },
  data: () => ({
    isLoading: false
  }),
  methods: {
    async save({ filter }) {
      this.isLoading = true;
      await this.$store.dispatch("entities/updateFilter", { filter });
      this.isLoading = false;
      this.$router.push({
        name: "management.companies.filters",
        params: { id: this.companyId }
      });
    }
  }
};
</script>
