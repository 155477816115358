<template>
  <v-layout row wrap>
    <v-flex xs12 sm6>
      <b-date-picker v-model="date" />
    </v-flex>
    <v-flex xs12 sm6>
      <b-time-picker v-model="time" />
    </v-flex>
  </v-layout>
</template>

<script type="text/babel">
import BDatePicker from "@/components/generic/input/dates/BDatePicker";
import BTimePicker from "@/components/generic/input/dates/BTimePicker";
import moment from "moment";
export default {
  name: "date-time-input",
  components: { BTimePicker, BDatePicker },
  props: {
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    model: {
      get() {
        if (!this.value) {
          return null;
        }
        return moment(this.value).toISOString();
      },
      set(value) {
        this.$emit("input", moment(value).toISOString());
      }
    },

    date: {
      get() {
        if (!this.model) {
          return null;
        }
        return moment(this.value).format("YYYY-MM-DD");
      },
      set(value) {
        if (this.time) {
          this.model = `${value} ${this.time}`;
        } else {
          this.model = value;
        }
      }
    },
    time: {
      get() {
        if (!this.value) {
          return null;
        }
        return moment(this.value).format("HH:mm:ss");
      },
      set(value) {
        if (this.date) {
          this.model = `${this.date} ${value}`;
        } else {
          this.model = moment(value, "HH:mm:ss").toISOString();
        }
      }
    }
  }
};
</script>
