<template>
  <div>
    <v-data-table
      :items="ids"
      :pagination.sync="pagination"
      :rows-per-page-items="[20, 50, 100]"
    >
      <invoice-item-offers-table-row
        slot="items"
        slot-scope="props"
        :offer-id="props.item"
        :invoice-item-id="invoiceItemId"
        :is-odd="props.index % 2 !== 0"
        :is-highlighted="highlightedOffer == props.item"
      />
    </v-data-table>
  </div>
</template>

<script type="text/babel">
import {
  IDS as GETTER_IDS,
  PAGINATION as GETTER_PAGINATION
} from "../../store/templates/table/getter-types";
import { SET_PAGINATION as DISPATCH_SET_PAGINATION } from "../../store/templates/table/action-types";
import InvoiceItemOffersTableRow from "./InvoiceItemOffersTableRow";
import { isChanged } from "@/store/helpers";

export default {
  name: "invoice-item-offers",
  components: { InvoiceItemOffersTableRow },
  props: {
    invoiceItemId: {
      type: Number,
      required: true
    },
    highlightedOffer: {
      type: Number,
      required: false
    }
  },
  data: () => ({}),
  computed: {
    pagination: {
      get() {
        return this.$store.getters[
          `invoice/itemOffers${this.invoiceItemId}/${GETTER_PAGINATION}`
        ];
      },
      set(pagination) {
        if (isChanged(this.pagination, pagination)) {
          this.$store.dispatch(
            `invoice/itemOffers${this.invoiceItemId}/${DISPATCH_SET_PAGINATION}`,
            {
              pagination
            }
          );
        }
      }
    },
    ids() {
      return this.$store.getters[
        `invoice/itemOffers${this.invoiceItemId}/${GETTER_IDS}`
      ];
    }
  },
  methods: {}
};
</script>
<style scoped>
>>> .v-table__overflow table thead {
  display: none !important;
}
>>> .v-table__overflow table tbody tr {
  border-bottom: none !important;
}
</style>
