<template>
  <div class="delivery-method-form-container">
    <v-card>
      <crud-header
        :title="name"
        type="Delivery method"
        store="deliveryMethod"
        @save-success="
          $router.push({
            name: 'company.delivery-methods',
            params: { companyId: company.id }
          })
        "
      />

      <company-delivery-method-form />
    </v-card>
  </div>
</template>

<script type="text/babel">
import deliveryMethodModule from "@/store/modules/company/delivery-method";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../../helpers";
import CompanyDeliveryMethodForm from "@/components/company/delivery-methods/CompanyDeliveryMethodForm";

const { mapGetters: mapDeliveryMethodGetters } = createNamespacedHelpers(
  "deliveryMethod"
);
const { mapGetters: mapCompanyGetters } = createNamespacedHelpers("company");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "deliveryMethod",
  deliveryMethodModule
);

export default {
  name: "company-delivery-method-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: { CompanyDeliveryMethodForm, CrudHeader },
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapDeliveryMethodGetters(["name", "type", "recipients"]),
    ...mapCompanyGetters(["company"])
  },
  ...detailViewLifecycleMethods
};
</script>
<style scoped>
.delivery-method-form-container {
  width: 800px;
  margin: 0 auto;
  padding: 24px 0;
}
</style>
