<template>
  <span
    v-if="hasRolesOrPermissions('user_password_reset') && isCustomer"
    class="log-add-button"
  >
    <v-btn fab bottom right dark fixed color="red" @click.stop="dialog = true">
      <v-icon>far fa-key</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="500">
      <template v-if="dialog">
        <v-card ma-0>
          <v-alert :value="true" type="warning">
            Are you certain you want to reset the password for
            {{ company.name }}?
          </v-alert>
          <div style="text-align: center">
            <v-btn
              depressed
              small
              color="success"
              :disabled="isLoading"
              :loading="isLoading"
              class="log-add-button"
              @click="resetPassword"
            >
              Yes
            </v-btn>
            <v-btn
              depressed
              small
              color="error"
              :disabled="isLoading"
              :loading="isLoading"
              class="log-add-button"
              @click="dialog = false"
            >
              No
            </v-btn>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </span>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-reset-password",
  data: () => ({
    isLoading: false,
    dialog: false,
    category: {
      categoryId: null,
      price: null
    }
  }),
  computed: {
    ...mapGetters(["company", "primaryUser", "isCustomer"])
  },
  methods: {
    async resetPassword() {
      this.$store.dispatch("company/resetPassword", {
        email: this.primaryUser.email
      });
    },
    hasRolesOrPermissions(permission) {
      return this.$store.getters["auth/hasRolesOrPermissions"](permission);
    }
  }
};
</script>
<style scoped>
.log-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
}
.log-add-button {
  margin: 10px;
  max-width: 10%;
}
</style>
