<template>
  <v-card>
    <v-dialog v-model="internalDialog" max-width="500">
      <v-card>
        <div class="pt-3 px-5">
          <p>
            Finalizing this draft invoice is an
            <strong>irreversible</strong> action and the affiliate's billing
            contacts will be notified.
          </p>
          <p>Please make sure everything is in order.</p>
          <div
            v-if="company.state === 'BLACKLISTED'"
            class="pa-1 blacklisted mb-5"
          >
            <p class="ma-0">
              The affiliate you are trying to create an invoice for is
              blacklisted
            </p>
          </div>
          <v-layout row justify-space-around>
            <v-btn
              color="white"
              depressed
              :loading="loading"
              @click="internalDialog = false"
              >CANCEL</v-btn
            >
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              @click="finalize"
              >Finalize</v-btn
            >
          </v-layout>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapGetters } = createNamespacedHelpers("invoice");

export default {
  name: "finalize-invoice-dialog",
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      "id",
      "company",
      "hasRejectableOffers",
      "hasRejectionRequestedOffers"
    ]),
    internalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    async finalize() {
      this.loading = true;
      await this.$store.dispatch("invoice/confirm", {
        id: this.id,
        force: this.hasRejectableOffers || this.hasRejectionRequestedOffers
      });
      this.$emit("close");
      this.loading = false;
    }
  }
};
</script>
<style scoped>
p {
  text-align: center;
  font-size: 16px;
}
.blacklisted {
  background-color: var(--v-warning-base);
  border: solid #bc5a01 2px !important;
  color: #bc5a01;
}
</style>
