<template>
  <v-dialog v-model="deleteDialog" width="500px">
    <v-card>
      <v-toolbar :color="'red'" dark>
        <v-toolbar-title>
          Are you sure?
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div>{{ deliveryMethod.type }}</div>
        <div>{{ deliveryMethod.name }}</div>
        <div>
          <v-chip
            v-for="(recipient, index) in maxRecipients"
            :key="index"
            label
            small
          >
            {{ recipient.name }}
          </v-chip>
          <v-tooltip v-if="recipients.length > maxChips" bottom>
            <v-chip slot="activator" v-html="'...'" />
            <span>
              <v-chip
                v-for="(recipient, index) in recipients"
                :key="index"
                label
              >
                {{ recipient.name }}
              </v-chip>
            </span>
          </v-tooltip>
        </div>
        <div>{{ deliveryMethod.url }}</div>
      </v-card-text>
      <v-card-actions>
        <v-btn flat class="cancelClass" @click="deleteDialog = false">
          Cancel
        </v-btn>
        <v-btn
          :disabled="loading"
          :loading="loading"
          dark
          color="red"
          @click="deleteDeliveryMethod"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const {
  mapGetters: mapDeliveryMethodGetters,
  mapState: mapDeliveryMethodState
} = createNamespacedHelpers("company/deliveryMethods");

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "company-delivery-method-delete-dialog",
  props: {
    deliveryMethodId: {
      type: Number,
      required: true
    },
    dialog: { type: Boolean, default: false }
  },
  data: function() {
    return {
      maxChips: 5,
      loading: false
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapDeliveryMethodGetters([FIND_BY_ID]),
    ...mapDeliveryMethodState(["company/deliveryMethod"]),
    deliveryMethod() {
      return this[FIND_BY_ID](this.deliveryMethodId);
    },
    deleteDialog: {
      get: function() {
        return this.dialog;
      },
      set: function(newValue) {
        this.$emit("update:dialog", newValue);
      }
    },
    recipients() {
      return this.$store.getters["company/deliveryMethods/recipients"](
        this.deliveryMethodId
      );
    },
    maxRecipients() {
      return this.recipients.slice(0, this.maxChips);
    }
  },
  methods: {
    async deleteDeliveryMethod() {
      this.loading = true;
      await this.$store.dispatch(
        `company/deliveryMethod/delete`,
        this.deliveryMethodId
      );
      this.$emit("success");
      this.loading = false;
      this.deleteDialog = false;
    }
  }
};
</script>

<style scoped></style>
