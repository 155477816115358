<script type="text/babel">
import { isNil } from "lodash";
import {
  IS_LOADING,
  DATA_POINTS,
  IS_FETCHABLE
} from "@/store/templates/chart/getter-types";
import { INIT } from "@/store/templates/chart/action-types";
import { SET_FILTER } from "@/store/templates/chart/mutation-types";

export default {
  inject: ["$validator"],
  props: {},
  data: () => ({}),
  created() {
    this.init();
  },
  computed: {
    isStoreRegistered() {
      return !isNil(this.$store.state[this.store]);
    },
    data() {
      if (!this.isStoreRegistered) {
        return undefined;
      }
      return this.$store.getters[`${this.store}/${DATA_POINTS}`];
    },
    isLoading() {
      return (
        this.isStoreRegistered &&
        this.$store.getters[`${this.store}/${IS_LOADING}`]
      );
    },
    fetchable() {
      return (
        this.isStoreRegistered &&
        this.$store.getters[`${this.store}/${IS_FETCHABLE}`]
      );
    }
  },
  methods: {
    async initStore() {
      if (isNil(this.store)) {
        throw Error("No store name defined.");
      }
      if (isNil(this.$store.state[this.store])) {
        const module = await import(`@/store/modules/${this.store}`);
        if (isNil(this.$store.state[this.store])) {
          // Double check here, because of the race condition introduced by the async import.
          this.$store.registerModule(this.store, module.default);
          console.debug(`[VUEX] Registering ${this.store} module.`);
        }
      }
    },
    async init() {
      await this.initStore();
      if (!isNil(this.id))
        this.$store.commit(`${this.store}/${SET_FILTER}`, {
          property: "id",
          value: this.id
        });
      if (this.fetchable) {
        this.$store.dispatch(`${this.store}/${INIT}`);
      }
    }
  }
};
</script>
