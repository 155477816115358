<template>
  <v-card :class="cardClass" :style="cardStyle">
    <v-container fluid>
      <v-layout style="min-height: 500px">
        <v-flex xs12 md6>
          <gmap-map
            ref="mapRef"
            :center="{ lat: 50.477620774049164, lng: 4.484564937499954 }"
            :zoom="8"
            :options="mapOptions"
            :draggable="false"
            :clickable="false"
            :zoomable="false"
            map-type-id="roadmap"
            style="width: 100%; height: 100%"
          >
            <template v-if="!isEntireCountry">
              <gmap-polygon
                v-for="(polygon, index) in includedPolygonGmapObjects"
                :key="`inc-polygon-${index}`"
                :path="polygon.coordinates"
                :editable="selectedShape === polygon"
                :options="includedPolygonsOptions"
                @click="selectShape({ shape: polygon })"
                @path_changed="
                  $store.commit(
                    'filter/setSelectedShapeCoordinates',
                    arguments[0]
                  )
                "
              />
              <gmap-polygon
                v-for="(polygon, index) in excludedPolygonGmapObjects"
                :key="`exc-polygon-${index}`"
                :path="polygon.coordinates"
                :editable="selectedShape === polygon"
                :options="excludedPolygonsOptions"
                @click="selectShape({ shape: polygon })"
                @path_changed="
                  $store.commit(
                    'filter/setSelectedShapeCoordinates',
                    arguments[0]
                  )
                "
              />
              <gmap-circle
                v-for="(circle, index) in includedCircleGmapObjects"
                :key="`inc-circle-${index}`"
                :center="circle.center"
                :radius="circle.radius"
                :editable="selectedShape === circle"
                :options="includedPolygonsOptions"
                @click="selectShape({ shape: circle })"
                @radius_changed="
                  $store.commit('filter/setSelectedShapeRadius', arguments[0])
                "
                @center_changed="
                  $store.commit('filter/setSelectedShapeCenter', arguments[0])
                "
              />
              <gmap-circle
                v-for="(circle, index) in excludedCircleGmapObjects"
                :key="`exc-circle-${index}`"
                :center="circle.center"
                :radius="circle.radius"
                :editable="selectedShape === circle"
                :options="excludedPolygonsOptions"
                @click="selectShape({ shape: circle })"
                @radius_changed="
                  $store.commit('filter/setSelectedShapeRadius', arguments[0])
                "
                @center_changed="
                  $store.commit('filter/setSelectedShapeCenter', arguments[0])
                "
              />
            </template>
            <gmap-polygon
              v-for="polygon in visibleCityPolygons"
              :key="`city-polygon-${polygon.id}`"
              :path="polygon.coordinates"
              :options="
                isEntireCountry || isCityPolygonSelected(polygon)
                  ? includedPolygonsOptions
                  : selectablePolygonOptions
              "
              @click="$store.commit('filter/selectCity', polygon)"
            />
          </gmap-map>
        </v-flex>
        <v-flex xs12 md6 class="pa-3">
          <v-layout row>
            <v-flex grow>
              <v-autocomplete-country v-model="country" />
            </v-flex>
            <v-flex shrink>
              <v-checkbox v-model="isEntireCountry" hide-details />
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex md3>
              <v-btn
                block
                :color="drawMode === 'DRAW_MODE_CITY' ? 'primary' : undefined"
                :disabled="isEntireCountry"
                @click="setDrawMode('DRAW_MODE_CITY')"
              >
                City
              </v-btn>
            </v-flex>
            <v-flex md3>
              <v-btn
                block
                :color="
                  drawMode === 'DRAW_MODE_POLYGON' ? 'primary' : undefined
                "
                :disabled="isEntireCountry"
                @click.native="setDrawMode('DRAW_MODE_POLYGON')"
              >
                POLYGON
              </v-btn>
            </v-flex>
            <v-flex md3>
              <v-btn
                block
                :color="drawMode === 'DRAW_MODE_CIRCLE' ? 'primary' : undefined"
                :disabled="isEntireCountry"
                @click="setDrawMode('DRAW_MODE_CIRCLE')"
              >
                CIRCLE
              </v-btn>
            </v-flex>
            <v-flex md3>
              <v-btn
                block
                :color="isDrawingExcluded ? 'primary' : ''"
                :disabled="isEntireCountry"
                @click="isDrawingExcluded = !isDrawingExcluded"
              >
                <v-icon>
                  {{
                    isDrawingExcluded
                      ? "add_circle_outline"
                      : "remove_circle_outline"
                  }} </v-icon
                >&nbsp;EXCLUDE
              </v-btn>
            </v-flex>
          </v-layout>
          <v-autocomplete-city
            v-if="isCitiesShown && drawMode === 'DRAW_MODE_CIRCLE'"
            v-model="cityCircles"
            label="City Circles"
            :country="country"
            :disabled="isEntireCountry || isCitiesLoading"
            multiple
          />
          <v-autocomplete-province
            v-if="isProvincesShown"
            v-model="provinces"
            :country="country"
            :disabled="isEntireCountry || isProvincesLoading"
            multiple
          />
          <v-autocomplete-city
            v-if="isCitiesShown"
            v-model="cities"
            :country="country"
            :disabled="isEntireCountry || isCitiesLoading"
            multiple
          />
          <v-layout
            v-if="!isEntireCountry"
            row
            :class="{ 'filter-shapes--disabled': isEntireCountry }"
          >
            <v-flex xs12 md6>
              <filter-geographic-shape-list
                :shapes="includedPolygonGmapObjects"
                :selected-shape="selectedShape"
                heading="Included Polygons"
                key-prefix="inc-polygon"
                title-prefix="Included Polygon"
                @select-shape="selectShape"
                @remove-shape="
                  $store.commit('filter/removePolygon', arguments[0])
                "
              />
            </v-flex>
            <v-flex xs12 md6>
              <filter-geographic-shape-list
                :shapes="excludedPolygonGmapObjects"
                :selected-shape="selectedShape"
                heading="Excluded Polygons"
                key-prefix="exc-polygon"
                title-prefix="Excluded Polygon"
                @select-shape="selectShape"
                @remove-shape="
                  $store.commit('filter/removePolygon', arguments[0])
                "
              />
            </v-flex>
          </v-layout>
          <v-layout
            v-if="!isEntireCountry"
            row
            :class="{ 'filter-shapes--disabled': isEntireCountry }"
          >
            <v-flex xs12 md6>
              <filter-geographic-shape-list
                :shapes="includedCircleGmapObjects"
                :selected-shape="selectedShape"
                heading="Included Circles"
                key-prefix="inc-circle"
                title-prefix="Included Circle"
                @select-shape="selectShape"
                @remove-shape="removeShape"
              />
            </v-flex>
            <v-flex xs12 md6>
              <filter-geographic-shape-list
                :shapes="excludedCircleGmapObjects"
                :selected-shape="selectedShape"
                heading="Excluded Circle"
                key-prefix="exc-circle"
                title-prefix="Excluded Circle"
                @select-shape="selectShape"
                @remove-shape="removeShape"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-alert :value="isShowingWarning && !valid" type="warning">
        No region selected for this filter! You'll need at least one!
      </v-alert>
    </v-container>
  </v-card>
</template>

<script type="text/babel">
import { find, isNil, map, difference } from "lodash";
import { gmapApi } from "vue2-google-maps";
import geoModule from "@/store/modules/geo";

import FilterGeographicShapeList from "./FilterGeographicShapeList";
import FilterStepperMixin from "./FilterStepperMixin.vue";
import { createNamespacedHelpers as createNamespacedComputedHelpers } from "../../store/helpers";
import { createNamespacedHelpers } from "vuex";
import VAutocompleteCity from "./VAutocompleteCity";
import VAutocompleteProvince from "./VAutocompleteProvince";
import VAutocompleteCountry from "../country/VAutocompleteCountry";

const { mapGetters } = createNamespacedHelpers("filter");
const { mapGetters: mapGeoGetters } = createNamespacedHelpers("geo");
const { mapComputed } = createNamespacedComputedHelpers("filter");

const DRAW_MODE_CITY = "DRAW_MODE_CITY";
const DRAW_MODE_POLYGON = "DRAW_MODE_POLYGON";
const DRAW_MODE_CIRCLE = "DRAW_MODE_CIRCLE";

const SHAPE_OPTIONS = {
  strokeColor: "#000000",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#8888FF",
  fillOpacity: 0.3,
  clickable: true,
  editable: true
};

export default {
  name: "company-filter-geographic",
  components: {
    VAutocompleteCountry,
    VAutocompleteProvince,
    VAutocompleteCity,
    FilterGeographicShapeList
  },
  mixins: [FilterStepperMixin],
  data: () => {
    return {
      map: undefined,
      drawingManager: undefined,
      drawMode: null,
      selectedCities: {},
      selectedPolygons: [],
      selectedCircles: [],

      includedPolygonsOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: "0.8",
        strokeWeight: 0,
        fillColor: "#00FF00",
        fillOpacity: "0.35"
      },

      excludedPolygonsOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: "0.8",
        strokeWeight: 0,
        fillColor: "#FF0000",
        fillOpacity: "0.35"
      },

      selectablePolygonOptions: {
        strokeColor: "#000000",
        strokeOpacity: "0.8",
        strokeWeight: 2,
        fillColor: "#8888FF",
        fillOpacity: "0.3"
      },

      mapOptions: {
        //                    mapTypeControl: false,
        //                    zoomControl: false,
        //                    scaleControl: false,
        //                    streetViewControl: false,
        disableDoubleClickZoom: true,
        scrollWheel: true,
        disableDefaultUI: true
      },

      isShowingWarning: false,
      isDrawingExcluded: false,
      cityCircles: []
    };
  },
  computed: {
    ...mapGeoGetters({
      geoCountry: "country",
      countryCities: "countryCities"
    }),
    ...mapGetters([
      "includedCircleGmapObjects",
      "excludedCircleGmapObjects",
      "includedPolygonGmapObjects",
      "excludedPolygonGmapObjects",
      "cityPolygons"
    ]),
    ...mapComputed([
      "selectedShape",
      "country",
      "isEntireCountry",
      "provinces",
      "cities",
      "includedPolygons",
      "includedCircles",
      "excludedPolygons",
      "excludedCircles"
    ]),
    google: gmapApi,
    isProvincesShown() {
      return !this.isEntireCountry;
    },
    isProvincesLoading() {
      return isNil(this.$store.state["province-select"]);
    },
    isCitiesShown() {
      return !this.isEntireCountry;
    },
    isCitiesLoading() {
      return isNil(this.$store.state["city-select"]);
    },
    visibleCityPolygons() {
      return this.countryCities(this.country).filter(cityPolygon =>
        this.isCityPolygonVisible(cityPolygon)
      );
    },
    countryGeo() {
      return isNil(this.$store.state.geo)
        ? null
        : this.geoCountry(this.country);
    }
  },
  watch: {
    cityCircles: {
      deep: true,
      handler(newVal, oldVal) {
        const newCityId = difference(newVal, oldVal)[0];
        const removedCityId = difference(oldVal, newVal)[0];
        if (!isNil(newCityId)) {
          const city = this.$store.getters["city-select/getCity"](newCityId);
          this.$store.commit("filter/addCircle", {
            center: {
              lat: parseFloat(city.lat),
              lng: parseFloat(city.lng)
            },
            radius: 20000,
            cityId: newCityId,
            isExcluded: this.isDrawingExcluded
          });
        }
        if (!isNil(removedCityId)) {
          const removedCircle = this.includedCircleGmapObjects.filter(
            circleGmap => circleGmap.cityId === removedCityId
          )[0];
          if (!isNil(removedCircle)) {
            this.$store.commit("filter/removeCircle", { shape: removedCircle });
          }
        }
      }
    },
    countryGeo(country) {
      if (!isNil(country)) {
        this.map.setZoom(country.zoom);
        this.map.panTo(country.center);
      }
    },
    drawMode() {
      switch (this.drawMode) {
        case DRAW_MODE_POLYGON:
          this.drawingManager.setMap(this.map);
          this.drawingManager.setOptions({
            drawingMode: this.google.maps.drawing.OverlayType.POLYGON
          });
          break;
        case DRAW_MODE_CIRCLE:
          this.drawingManager.setMap(this.map);
          this.drawingManager.setOptions({
            drawingMode: this.google.maps.drawing.OverlayType.CIRCLE
          });
          break;
        default:
          this.drawingManager.setOptions({
            drawingMode: null
          });
          this.drawingManager.setMap(null);
          break;
      }
    }
  },
  created() {
    if (isNil(this.$store.state.geo)) {
      this.$store.registerModule("geo", geoModule);
      console.debug("Registering geo module");
      if (!this.$store.getters["geo/isCountryLoaded"](this.country)) {
        this.$store.dispatch("geo/fetchCountry", this.country);
      }
    }
  },
  mounted() {
    this.registerMap();
    // this.fetchProvinces();
    // this.fetchCities();
  },
  methods: {
    onPathChanged(changedCoordinatesArray) {
      const shape = find(
        this.selectedShape.isExcluded
          ? this.excludedPolygons
          : this.includedPolygons,
        shape => shape.id === this.selectedShape.id
      );
      shape.coordinates = map(
        changedCoordinatesArray.getArray(),
        coordinates => {
          return [coordinates.lat(), coordinates.lng()];
        }
      );
    },
    selectCity({ id }) {
      const cities = this.cities.slice(0);
      const index = cities.indexOf(id);
      if (index > 1) {
        cities.splice(index, 1);
      } else {
        cities.push(id);
      }
      this.cities = cities;
    },
    isCityPolygonVisible(city) {
      return (
        city.coordinates.length > 0 &&
        (this.isEntireCountry ||
          this.isCityPolygonSelected(city) ||
          this.drawMode === DRAW_MODE_CITY)
      );
    },
    isCityPolygonSelected({ id }) {
      return this.cities.includes(id);
    },
    registerMap() {
      this.$refs.mapRef.$mapPromise.then(map => {
        this.map = map;
        this.registerDrawingManager();
        this.registerMapListeners();
      });
    },
    registerDrawingManager() {
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
          drawingModes: ["polygon", "circle"]
        },
        polygonOptions: SHAPE_OPTIONS,
        circleOptions: SHAPE_OPTIONS
      });
    },
    registerMapListeners() {
      this.google.maps.event.addListener(
        this.drawingManager,
        "circlecomplete",
        shape => {
          this.$store.commit("filter/addCircle", {
            center: {
              lat: shape.getCenter().lat(),
              lng: shape.getCenter().lng()
            },
            radius: shape.getRadius(),
            isExcluded: this.isDrawingExcluded
          });

          shape.setEditable(false);
          shape.setVisible(false);
          shape = null;

          this.setDrawMode(null);
        }
      );

      this.google.maps.event.addListener(
        this.drawingManager,
        "polygoncomplete",
        shape => {
          const vertices = shape.getPath();
          const internalPolygon = [];

          for (let i = 0; i < vertices.getLength(); i++) {
            const xy = vertices.getAt(i);
            internalPolygon.push([xy.lat(), xy.lng()]);
          }

          this.$store.commit("filter/addPolygon", {
            coordinates: internalPolygon,
            isExcluded: this.isDrawingExcluded
          });

          shape.setEditable(false);
          shape.setVisible(false);
          shape = null;

          this.setDrawMode(null);
        }
      );
    },
    convertCoordinate(coordinate) {
      return { lat: coordinate[0], lng: coordinate[1] };
    },
    selectShape({ shape }) {
      if (this.selectedShape === shape) {
        this.selectedShape = null;
      } else {
        this.selectedShape = shape;
      }
    },
    setDrawMode(drawMode) {
      // If setting existing state, toggle it off
      this.drawMode = drawMode === this.drawMode ? null : drawMode;
    },
    removeShape(shape) {
      if (!isNil(shape.shape.cityId)) {
        this.cityCircles = this.cityCircles.filter(
          city => city !== shape.shape.cityId
        );
      }
      this.$store.commit("filter/removeCircle", { shape: shape.shape });
    }
  }
};
</script>
