<template>
  <v-dialog v-if="showDialog" v-model="internalDialog" max-width="500">
    <v-card>
      <div class="pa-3">
        <div class="pa-2 mb-2 warning">
          <p class="ma-0">
            There {{ amountRejectionRequestedOffers === 1 ? "is" : "are" }}
            {{ amountRejectionRequestedOffers }}
            {{ amountRejectionRequestedOffers === 1 ? "offer" : "offers" }} whit
            an open rejection request.
            <br />
            There {{ amountRejectableOffers === 1 ? "is" : "are" }}
            {{ amountRejectableOffers }}
            {{ amountRejectableOffers === 1 ? "offer" : "offers" }} that are
            still in there rejectable period.
            <br />
            Are you sure you want toe create the invoice items?
          </p>
        </div>
        <v-layout row justify-space-around>
          <v-btn color="white" depressed @click="internalDialog = false"
            >CANCEL</v-btn
          >
          <v-btn
            color="primary"
            :loading="isUpdatingItem"
            depressed
            @click="createItems"
            >CREATE ITEM(S)</v-btn
          >
        </v-layout>
      </div>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import { isNil } from "lodash";
export default {
  name: "confirm-item-creation-dialog",
  props: {
    amountRejectableOffers: {
      type: Number,
      required: true
    },
    amountRejectionRequestedOffers: {
      type: Number,
      required: true
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    internalDialog: {
      get() {
        return this.showDialog;
      },
      set() {
        this.$emit("close");
      }
    },
    isUpdate() {
      return !isNil(this.$store.state.invoice.model.id);
    },
    isUpdatingItem() {
      return this.$store.getters["invoice/isUpdatingItem"];
    }
  },
  methods: {
    async createItems() {
      if (this.isUpdate) {
        this.$store.commit("invoice/setUpdatingItem", true);
        await this.$store.dispatch("invoice/addOfferItemsToInvoice");
        this.$store.commit("invoice/setUpdatingItem", false);
      } else {
        this.$store.commit("invoice/createItemsFromOffers");
      }
      this.$emit("items-created");
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.toolbar-title {
  font-size: 16px !important;
}
.warning {
  background-color: var(--v-warning-base);
  border: solid #bc5a01 2px !important;
  color: #bc5a01;
  text-align: center;
}
</style>
