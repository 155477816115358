<template>
  <tr v-bind:class="{ odd: isOdd, highlighted: isHighlighted }">
    <div class="grid-row">
      <p class="py-1 ma-0">
        <strong>#{{ offer.id }}</strong>
        -
        {{ submission.firstName }}
        {{ submission.lastName }} -
        {{ postalCode }}
        {{ city }}
      </p>
      <v-btn
        class="reject-btn"
        flat
        color="error"
        :disabled="isCredited || offer.isCredited || offer.isCreditingIssued"
        @click="showOfferDialog = !showOfferDialog"
        >{{
          isCredited || offer.isCredited || offer.isCreditingIssued
            ? "CREDITED"
            : "REJECT"
        }}</v-btn
      >
      <p class="py-1 ma-0 text-sm-right">
        {{ formatPrice(offer.price) }}
      </p>
    </div>
    <invoice-item-offer-dialog
      :invoice-item-id="invoiceItemId"
      :offer-id="offerId"
      :show-dialog="showOfferDialog"
      @credited="isCredited = true"
      @close="showOfferDialog = !showOfferDialog"
    />
  </tr>
</template>
<script type="text/babel">
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import { isNil } from "lodash";
import InvoiceItemOfferDialog from "./InvoiceItemOfferDialog";

export default {
  name: "invoice-item-offers-table-row",
  components: { InvoiceItemOfferDialog },
  props: {
    offerId: {
      type: Number,
      required: true
    },
    invoiceItemId: {
      type: Number,
      required: true
    },
    isOdd: {
      type: Boolean,
      required: true
    },
    isHighlighted: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showOfferDialog: false,
    isCredited: false
  }),
  computed: {
    offer() {
      return this.$store.getters[
        `invoice/itemOffers${this.invoiceItemId}/${FIND_BY_ID}`
      ](this.offerId);
    },
    submission() {
      return this.$store.getters[
        `invoice/itemOffers${this.invoiceItemId}/submission`
      ](this.offerId);
    },
    address() {
      return this.$store.getters[
        `invoice/itemOffers${this.invoiceItemId}/address`
      ](this.offerId);
    },
    postalCode() {
      return this.address?.postalCode;
    },
    city() {
      return this.address?.cityName;
    },
    invoiceState() {
      return this.$store.getters["invoice/state"];
    }
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    }
  }
};
</script>
<style scoped>
.odd {
  background-color: #e8e8e8;
}
.odd:hover {
  background-color: #e0e0e0 !important;
}
.highlighted {
  background-color: #e3f2fd;
}
.highlighted:hover {
  background-color: #d3e1eb !important;
}
.grid-row {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.offer-link {
  color: black;
}
.reject-btn {
  height: 15px;
  max-width: 70px;
  font-size: 10px;
  padding: 0 !important;
  border-radius: 0 !important;
  min-width: 60px;
}
</style>
