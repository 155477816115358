<template>
  <span class="edit-button">
    <v-btn
      class="edit-button"
      depressed
      small
      color="primary lighten-2"
      @click.stop="dialog = true"
    >
      Edit
    </v-btn>
    <persistent-when-changed-dialog
      v-model="dialog"
      title="Bedrijfsgegevens"
      :changed="isChanged"
      :loading="isSaving"
      width="900px"
      @cancel="revert"
      @confirm="validate(save)"
    >
      <v-text-field
        v-model="name"
        v-validate="'required|max:255'"
        autocomplete="not-my-name"
        :error-messages="errors.collect('name')"
        data-vv-name="name"
        name="name"
        label="Name"
      />

      <div class="list-container">
        <div class="list-item-2">
          <v-icon class="list-icon">
            location_on
          </v-icon>

          <div class="list-content">
            <div class="address">
              <v-text-field
                v-model="street"
                v-validate="'max:255'"
                autocomplete="not-my-street"
                :error-messages="errors.collect('street')"
                data-vv-name="street"
                name="street"
                label="Street"
              />
              <v-text-field
                v-model="streetNumber"
                v-validate="'max:255'"
                autocomplete="not-my-street-number"
                :error-messages="errors.collect('streetNumber')"
                data-vv-name="street-number"
                name="streetNumber"
                label="Number"
              />
              <v-text-field
                v-model="box"
                v-validate="'max:255'"
                autocomplete="not-my-box"
                :error-messages="errors.collect('box')"
                data-vv-name="box"
                name="box"
                label="Box"
              />
            </div>
            <div class="city">
              <v-text-field
                v-model="postalCode"
                v-validate="'max:255'"
                autocomplete="not-my-postal-code"
                :error-messages="errors.collect('postalCode')"
                data-vv-name="postalCode"
                name="postalCode"
                label="Postal"
              />
              <v-text-field
                v-model="cityName"
                v-validate="'max:255'"
                autocomplete="not-my-city-name"
                :error-messages="errors.collect('cityName')"
                data-vv-name="cityName"
                name="cityName"
                label="City"
              />
            </div>
            <div>
              <v-autocomplete-country v-model="country" />
            </div>
            <div>
              <v-autocomplete-language v-model="language" />
            </div>
          </div>
        </div>
        <div class="list-item-3">
          <v-icon class="list-icon">
            phone
          </v-icon>
          <div class="list-content">
            <v-text-field
              v-for="(telephoneNumber, index) in telephoneNumbers"
              :key="index"
              v-model="telephoneNumbers[index]"
              v-validate="'max:255'"
              autocomplete="not-my-telephoneNumber"
              :error-messages="errors.collect('telephoneNumber')"
              data-vv-name="telephoneNumber"
              name="telephoneNumber"
              label="Telephone Number"
            />
          </div>
          <v-btn
            @click="addTelephoneNumber"
            class="list-icon"
            fab
            dark
            small
            color="primary"
          >
            <v-icon>
              far fa-fw fa-plus
            </v-icon>
          </v-btn>
        </div>
        <div class="list-item-3">
          <v-icon class="list-icon">
            email
          </v-icon>
          <div class="list-content">
            <v-text-field
              v-model="emailAddresses[0]"
              v-validate="'max:255|required|email'"
              autocomplete="not-my-emailAddress"
              :error-messages="errors.collect('email address')"
              data-vv-name="email address"
              name="emailAddress"
              label="Email Address"
            />
            <template v-for="(emailAddress, index) in emailAddresses">
              <v-text-field
                v-if="index !== 0"
                :key="index"
                v-model="emailAddresses[index]"
                v-validate="'max:255|email'"
                autocomplete="not-my-emailAddress"
                :error-messages="errors.collect(`email address ${index + 1}`)"
                :data-vv-name="`email address ${index + 1}`"
                name="emailAddress"
                label="Email Address"
              />
            </template>
          </div>
          <v-btn
            @click="addEmailAddress"
            class="list-icon"
            fab
            dark
            small
            color="primary"
          >
            <v-icon>
              far fa-fw fa-plus
            </v-icon>
          </v-btn>
        </div>
        <div class="list-item-3">
          <v-icon class="list-icon">
            link
          </v-icon>
          <div class="list-content">
            <div class="urls" v-for="(url, index) in urls" :key="index">
              <v-text-field
                v-model="url.url"
                v-validate="'max:255'"
                maxlength="255"
                autocomplete="not-my-url"
                :error-messages="errors.collect('url')"
                data-vv-name="url"
                name="url"
                label="URL"
              />
              <v-select
                v-model="url.type"
                :items="types"
                data-vv-name="type"
                label="Type"
              />
              <v-switch
                v-model="url.public"
                :label="url.public ? 'Public' : 'Private'"
                color="primary"
                default="true"
              ></v-switch>
            </div>
          </div>
          <v-btn
            @click="addUrl"
            class="list-icon"
            fab
            dark
            small
            color="primary"
          >
            <v-icon>
              far fa-fw fa-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
    </persistent-when-changed-dialog>
  </span>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import CanValidate from "@/components/mixins/validation/CanValidate";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import PersistentWhenChangedDialog from "@/components/generic/dialog/PersistentWhenChangedDialog";
import VAutocompleteCountry from "@/components/country/VAutocompleteCountry";
const { mapComputed } = createNamespacedHelpers("company");
import { cloneDeep } from "lodash";
import VAutocompleteLanguage from "../../language/VAutocompleteLanguage";
import { IS_CHANGED } from "@/store/templates/crud/getter-types";
import { REVERT } from "@/store/templates/crud/mutation-types";
import { SAVE } from "@/store/templates/crud/action-types";

export default {
  name: "company-edit-company-data",
  components: {
    VAutocompleteLanguage,
    VAutocompleteCountry,
    PersistentWhenChangedDialog
  },
  mixins: [CanValidate, IsValidationRoot],
  data() {
    return {
      dialog: false,
      isSaving: false,
      initUrl: { public: true, type: "other" },
      emailAddresses: [""],
      telephoneNumbers: [""],
      urls: [],
      types: [
        { text: "Website", value: "website" },
        { text: "Facebook", value: "facebook" },
        { text: "Instagram", value: "instagram" },
        { text: "Other", value: "other" }
      ]
    };
  },
  computed: {
    ...mapComputed([
      "name",
      "vatNumber",
      "street",
      "streetNumber",
      "box",
      "cityName",
      "postalCode",
      "country",
      "language"
    ]),
    isChanged() {
      return this.$store.getters[`company/${IS_CHANGED}`];
    }
  },
  watch: {
    telephoneNumbers: {
      handler(newVal) {
        this.$store.commit("company/setTelephoneNumbers", cloneDeep(newVal));
      },
      deep: true
    },
    emailAddresses: {
      handler(newVal) {
        this.$store.commit("company/setEmailAddresses", cloneDeep(newVal));
      },
      deep: true
    },
    urls: {
      handler(newVal) {
        this.$store.commit("company/setUrls", cloneDeep(newVal));
      },
      deep: true
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.telephoneNumbers = cloneDeep(
        this.$store.getters["company/telephoneNumbers"]
      );
      this.emailAddresses = cloneDeep(
        this.$store.getters["company/emailAddresses"]
      );
      this.urls = cloneDeep(this.$store.state.company.model.urls);
    },
    revert() {
      this.$store.commit(`company/${REVERT}`);
      this.init();
    },
    save() {
      this.$store.dispatch(`company/${SAVE}`);
    },
    addTelephoneNumber() {
      this.telephoneNumbers.push("");
    },
    addEmailAddress() {
      this.emailAddresses.push("");
    },
    addUrl() {
      this.urls.push(cloneDeep(this.initUrl));
    }
  }
};
</script>
<style scoped>
.list-container {
  --base-size: 24px;
  display: grid;
  grid-template-columns: 1fr;
  font-size: 16px;
  grid-gap: var(--base-size);
}
.list-item-2 {
  display: grid;
  grid-template-columns: var(--base-size) 1fr;
  grid-gap: inherit;
}
.list-item-3 {
  display: grid;
  grid-template-columns: var(--base-size) 1fr 40px;
  grid-gap: inherit;
}
.list-icon {
  place-self: center;
}
.address {
  display: grid;
  grid-template-columns: 5fr minmax(50px, 1fr) minmax(50px, 1fr);
  grid-gap: calc(var(--base-size) / 2);
}
.city {
  display: grid;
  grid-template-columns: minmax(50px, 1fr) 5fr;
  grid-gap: calc(var(--base-size) / 2);
}
.urls {
  display: grid;
  grid-template-columns: 5fr minmax(100px, 1.5fr) 90px;
  grid-gap: calc(var(--base-size) / 2);
}
.edit-button {
  margin: 0 0 0 auto;
}
</style>
