<template>
  <div class="grid-container">
    <v-text-field
      v-model="description"
      v-validate="'required|max:255'"
      label="Description"
      autocomplete="not-my-description"
      :counter="255"
      :error-messages="errors.collect('description')"
      data-vv-name="description"
      required
    />
    <v-text-field
      v-model="quantity"
      type="number"
      class="input-right"
      v-validate="'required|integer|min_value:1'"
      autocomplete="not-my-quantity"
      :error-messages="errors.collect('quantity')"
      data-vv-name="quantity"
      required
    />
    <v-text-field
      v-model="unitPrice"
      type="number"
      class="input-right"
      v-validate="'required|min_value:0.01'"
      autocomplete="not-my-unit-price"
      :error-messages="errors.collect('unitPrice')"
      data-vv-name="unitPrice"
      name="unitPrice"
      prefix="€"
      label="Price"
    />
    <div class="btn-grid">
      <v-btn :loading="isUpdatingItem" color="grey" @click="remove"
        ><v-icon>far fa-trash-alt</v-icon></v-btn
      >
      <v-btn :loading="isUpdatingItem" color="primary" @click="confirm"
        >CONFIRM</v-btn
      >
    </div>
  </div>
</template>

<script type="text/babel">
import { cloneDeep, isNil } from "lodash";

export default {
  name: "invoice-create-item",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  computed: {
    item() {
      return this.$store.getters["invoice/tempItem"];
    },
    unitPrice: {
      get() {
        return this.item.unitPrice;
      },
      set(val) {
        let item = cloneDeep(this.item);
        item.unitPrice = val;
        this.$store.commit("invoice/setTempItem", item);
      }
    },
    description: {
      get() {
        return this.item.description;
      },
      set(val) {
        let item = cloneDeep(this.item);
        item.description = val;
        this.$store.commit("invoice/setTempItem", item);
      }
    },
    quantity: {
      get() {
        return this.item.quantity;
      },
      set(val) {
        let item = cloneDeep(this.item);
        item.quantity = parseInt(val);
        this.$store.commit("invoice/setTempItem", item);
      }
    },
    isUpdate() {
      return !isNil(this.$store.state.invoice.model.id);
    },
    isUpdatingItem() {
      return this.$store.getters["invoice/isUpdatingItem"];
    }
  },
  methods: {
    async confirm() {
      const isValid = await this.$validator.validate();
      if (!isValid) {
        return;
      }
      if (this.isUpdate) {
        this.$store.commit("invoice/setUpdatingItem", true);
        await this.$store.dispatch("invoice/addItemToInvoice");
        this.$store.commit("invoice/setUpdatingItem", false);
        this.$store.commit("invoice/removeTempItem");
        this.$emit("items-created");
      } else {
        this.$store.commit("invoice/addItem");
        this.$store.commit("invoice/removeTempItem");
      }
    },
    remove() {
      this.$store.commit("invoice/removeTempItem");
    }
  }
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  border-bottom: 1px solid lightgray;
  align-items: center;
}
.btn-grid {
  display: grid;
  grid-template-columns: 8fr 2fr;
  align-items: center;
}
.input-right >>> input {
  text-align: right;
}
</style>
