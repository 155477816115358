<template>
  <v-autocomplete
    v-model="model"
    v-validate="internalValidationRules"
    :name="internalName"
    :data-vv-name="internalName"
    :data-vv-as="label"
    :error-messages="internalErrorMessages"
    :items="items"
    :label="internalLabel"
    :item-text="itemText"
    :item-value="itemValue"
    :readonly="isReadOnly"
    :disabled="isDisabled"
    :multiple="isMultiple"
    :clearable="clearable"
    :loading="isLoading"
    :search-input.sync="search"
    :no-filter="isAsync"
    :chips="isMultiple"
    persistent-hint
    :browser-autocomplete="browserAutocomplete"
    @change="onChange"
  >
    <template v-slot:selection="{ item, selected }" v-if="isMultiple">
      <v-chip :selected="selected" small label>
        <span v-text="item.name"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-tile-content>
        <v-list-tile-title
          v-html="addSearchHighlightMarkup(item.name)"
        ></v-list-tile-title>
        <v-list-tile-sub-title
          v-text="getCompanyName(item)"
        ></v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script type="text/babel">
import VAutocompleteMixin from "@/components/mixins/input/VAutocompleteMixin";
import { isNil } from "lodash";

export default {
  name: "v-autocomplete-filter",
  inject: ["$validator"],
  mixins: [VAutocompleteMixin],
  props: {
    companies: {
      type: [Array, Number],
      required: false
    }
  },
  data: () => ({
    store: "filter-select",
    defaultLabel: "filter",
    asyncFilters: {},
    includes: ["filters", "company"]
  }),
  watch: {
    companies: {
      handler() {
        this.$set(this.asyncFilters, "companies", this.companies);
      },
      immediate: true
    }
  },
  methods: {
    getCompany(companyId) {
      return this.$store.state["filter-select"].entities.company[companyId];
    },
    getCompanyName(item) {
      if (item.filters.length > 0) {
        const filters = item.filters.map(
          filterMapping =>
            this.$store.state["filter-select"].entities.filter[
              filterMapping.filter
            ]
        );
        const companies = filters.map(
          filter =>
            this.$store.state["filter-select"].entities.company[filter.company]
        );
        return companies.map(company => company.name).join(", ");
      }
      const company = this.getCompany(item.company);
      return isNil(company) ? "NO COMPANY" : company.name;
    }
  }
};
</script>
