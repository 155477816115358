import tableState from "../../templates/table/state";

export default {
  ...tableState(),
  country: {},
  language: {},
  categoryCompany: {},
  category: {},
  customer: {},
  filters: {
    states: ["AFFILIATED", "PAUSED"]
  }
};
