import crudActions from "@/store/templates/crud/actions";
import api from "@/api";
export default {
  ...crudActions(api.company.subject, "subject"),
  async delete(state, deliveryMethodId) {
    const { result } = await api.company.deliveryMethod.remove(
      deliveryMethodId
    );
    return result;
  }
};
