<template>
  <div class="py-3 grid-container">
    <div></div>
    <div>
      <div class="titles">
        <div class="grid-container-totals mb-3">
          <b>SUBTOTAL</b>
          <b class="grid-end">{{ formatPrice(amount) }}</b>
        </div>
        <div class="grid-container-totals mb-3">
          <b>VAT {{ vatRate }}%</b>
          <b class="grid-end">{{ formatPrice(vatAmount) }}</b>
        </div>
        <div class="grid-container-totals mb-3">
          <b>TOTAL</b>
          <b class="grid-end">{{ formatPrice(totalAmount) }}</b>
        </div>
      </div>
      <v-divider />
      <div class="titles">
        <div class="grid-container-totals my-3">
          <b>BALANCE</b>
          <b class="grid-end">{{
            formatPrice(balance + (balance / 100) * vatRate)
          }}</b>
        </div>
        <div class="grid-container-totals mb-3">
          <b>DUE</b>
          <b class="grid-end">{{ formatPrice(due + (due / 100) * vatRate) }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
import { isNil } from "lodash";
const { mapGetters } = VuexNamespacedHelpers("invoice");

export default {
  name: "invoice-totals",
  components: {},
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapGetters([
      "amount",
      "vatRate",
      "balance",
      "due",
      "vatAmount",
      "totalAmount"
    ])
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    }
  }
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-gap: 10px;
}
.grid-container-totals {
  display: grid;
  grid-template-columns: 7fr 3fr;
  font-size: 16px;
}
.grid-end {
  justify-self: end;
}
.titles {
  margin-left: 200px;
}
</style>
