<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Company"
      store="company"
      @save-success="
        ({ id }) =>
          $router.push({ name: 'company.show', params: { companyId: id } })
      "
      @revert="revert"
    />
    <company-create :is-reverted="isReverted" />
  </v-card>
</template>

<script type="text/babel">
import companyModule from "@/store/modules/company";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "@/views/helpers";
import CompanyCreate from "@/components/company/CompanyCreate";

const { mapGetters } = createNamespacedHelpers("company");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "company",
  companyModule
);

export default {
  name: "company-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CompanyCreate,
    CrudHeader
  },
  data: () => ({
    isReverted: false
  }),
  computed: {
    ...mapGetters(["name"])
  },
  methods: {
    revert() {
      this.isReverted = !this.isReverted;
    }
  },
  ...createViewLifecycleMethods
};
</script>
