<template>
  <v-flex :xs6="type === null" :xs12="isBulditFilter" class="text-xs-center">
    <v-layout row wrap>
      <v-flex xs12 class="text-xs-center">
        <v-btn
          v-if="isBulditFilter && !isPersisted"
          flat
          icon
          @click="$emit('reset')"
        >
          <v-icon>undo</v-icon>
        </v-btn>
        <v-btn
          color="accent"
          :disabled="!type === null"
          @click="$emit('set', { type: 'BULDIT' })"
        >
          Buldit Filter
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout v-if="isBulditFilter" row wrap>
      <v-flex xs11>
        <v-autocomplete-filter v-model="selectedFilter" />
      </v-flex>
      <v-flex xs1>
        <v-btn
          :disabled="selectedFilter === null"
          @click="$store.dispatch('filter/addSubFilter', selectedFilter)"
        >
          Add
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout v-if="isBulditFilter" row wrap>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="subFilters"
          hide-actions
          class="elevation-1"
        >
          <filter-type-buldit-row
            slot="items"
            slot-scope="props"
            :filter-id="props.item.filter"
            :percentage="props.item.percentage"
            :remaining-percentage="getRemainingPercentage(props.item.filter)"
            @set-percentage="
              $store.commit('filter/setSubFilterPercentage', arguments[0])
            "
            @remove="$store.commit('filter/removeSubFilter', arguments[0])"
          />
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script type="text/babel">
import Vue from "vue";
import { find, findIndex, isNil } from "lodash";
import { createUrlFromRoute } from "@/store/url";
import FilterTypeBulditRow from "./FilterTypeBulditRow";
import { createNamespacedHelpers } from "vuex";
import { createNamespacedHelpers as createNamespacedComputedHelpers } from "@/store/helpers";
import VAutocompleteFilter from "@/components/filter/VAutocompleteFilter";

const { mapComputed } = createNamespacedComputedHelpers("filter");
const { mapGetters } = createNamespacedHelpers("filter");

export default {
  name: "filter-type-company",
  components: { VAutocompleteFilter, FilterTypeBulditRow },
  props: {
    filter: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    filters: [],
    isLoading: false,
    selectedFilter: null,
    search: null,
    headers: [
      {
        text: "Company",
        align: "left",
        sortable: false,
        value: "company"
      },
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name"
      },
      { text: "Percentage (%)", sortable: false, value: "percentage" },
      { text: "Actions", value: "name", sortable: false }
    ],
    queryTimeout: null
  }),
  computed: {
    ...mapGetters(["isPersisted", "type", "isCompanyFilter", "isBulditFilter"]),
    ...mapComputed(["company", "subFilters"])
  },
  watch: {
    search(newValue) {
      clearTimeout(this.queryTimeout);
      if (!isNil(newValue) && newValue.length > 0) {
        this.queryTimeout = setTimeout(() => {
          this.getDataFromApi();
        }, 500);
      }
    }
  },
  methods: {
    async getDataFromApi() {
      clearTimeout(this.queryTimeout);
      this.isLoading = true;
      try {
        const { data } = await Vue.$http.get(
          createUrlFromRoute("api.filters.index", null, ["company"], {
            type: "COMPANY",
            search: this.search
          })
        );
        this.filters = data.data;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
    getRemainingPercentage(filterId) {
      let totalRemainingPercentage = 0;
      const filters = this.subFilters.filter(
        filter => filter.filter !== filterId
      );
      filters.forEach(filter => {
        totalRemainingPercentage += filter.percentage;
      });
      return totalRemainingPercentage;
    },
    setPercentage({ filterId, percentage }) {
      const filter = find(this.filter.filters, filter => {
        return filter.filter === filterId;
      });
      filter.percentage = percentage;
    },
    removeFilter({ filterId }) {
      const filterIndex = findIndex(this.filter.filters, filter => {
        return filter.filter === filterId;
      });
      this.filter.filters.splice(filterIndex, 1);
    }
  }
};
</script>
