<template>
  <div>
    <v-progress-linear
      :indeterminate="loading"
      background-color="white"
    ></v-progress-linear>
    <v-container style="padding-top: 0px">
      <v-layout row justify-end>
        <v-flex xs3>
          <v-autocomplete-filter
            v-model="filters"
            :companies="companyId"
            multiple
          />
        </v-flex>
        <v-flex style="max-width: 14%; margin-top: 12px;" xs2 justify-end>
          <v-btn-toggle v-model="timeframe" depressed>
            <v-btn value="7D" color="teal lighten-2">
              7D
            </v-btn>
            <v-btn value="30D" color="teal lighten-2">
              30D
            </v-btn>
            <v-btn value="3M" color="teal lighten-2">
              3M
            </v-btn>
            <v-btn value="12M" color="teal lighten-2">
              12M
            </v-btn>
            <v-btn value="ALL" color="teal lighten-2">
              ALL
            </v-btn>
          </v-btn-toggle>
        </v-flex>
        <v-flex xs4>
          <between-date-input v-model="date" />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import BetweenDateInput from "@/components/generic/input/dates/BetweenDateInput";
import { FILTERS } from "@/store/templates/chart/getter-types";
import { SET_FILTER } from "@/store/templates/chart/mutation-types";
import { INIT } from "@/store/templates/chart/action-types";
import { debounce } from "lodash";
import VAutocompleteFilter from "@/components/filter/VAutocompleteFilter";

export default {
  name: "company-chart-filters",
  components: {
    VAutocompleteFilter,
    BetweenDateInput
  },
  data: () => ({
    timeframe: "ALL"
  }),
  computed: {
    companyId() {
      return this.$store.state.company.model.id;
    },
    date: {
      get() {
        return this.$store.getters[`company-submissions-chart/${FILTERS}`].date;
      },
      set(newVal) {
        this.$store.commit(`company-submissions-chart/${SET_FILTER}`, {
          property: "date",
          value: newVal
        });
      }
    },
    filters: {
      get() {
        return this.$store.getters[`company-submissions-chart/${FILTERS}`]
          .filters;
      },
      set(newVal) {
        this.$store.commit(`company-submissions-chart/${SET_FILTER}`, {
          property: "filters",
          value: newVal
        });
      }
    },
    loading() {
      return this.$store.getters["company-submissions-chart/isLoading"];
    }
  },
  watch: {
    timeframe(newVal) {
      const date = this.getDateForTimeframe(newVal);
      this.$store.commit(`company-submissions-chart/${SET_FILTER}`, {
        property: "date",
        value: date
      });
    },
    date() {
      this.debouncedSearch(this.getChart);
    },
    filters() {
      this.debouncedSearch(this.getChart);
    }
  },
  methods: {
    debouncedSearch: debounce(searchMethod => {
      searchMethod();
    }, 750),
    getChart() {
      this.$store.dispatch(`company-submissions-chart/${INIT}`);
    },
    getDateForTimeframe(timeframe) {
      const currentDate = new Date();
      let date = {
        from: null,
        to: null
      };
      switch (timeframe) {
        case "7D":
          date.from = new Date(new Date().setDate(new Date().getDate() - 7))
            .toISOString()
            .substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        case "30D":
          date.from = new Date(new Date().setDate(new Date().getDate() - 30))
            .toISOString()
            .substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        case "3M":
          date.from = new Date(new Date().setDate(new Date().getDate() - 90));
          date.from = new Date(date.from.getFullYear(), date.from.getMonth(), 2)
            .toISOString()
            .substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        case "12M":
          date.from = new Date(new Date().setDate(new Date().getDate() - 365));
          date.from = new Date(date.from.getFullYear(), date.from.getMonth(), 2)
            .toISOString()
            .substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        case "ALL":
          date.from = new Date("2018-02-01").toISOString().substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        default:
          break;
      }

      return date;
    }
  }
};
</script>
