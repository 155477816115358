var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Companies","icon":"business","store":"companies","headers":[
    { text: 'Name', value: 'name' },
    { text: 'VAT', value: 'vatNumber', sortable: false },
    { text: 'Country', value: 'country_id' },
    { text: 'Language', value: 'language' },
    { text: 'Status', value: 'status' },
    { text: 'Categories', value: 'categories', sortable: false },
    {
      text: 'Impersonate',
      align: 'right',
      value: 'name',
      sortable: false,
      width: '100px'
    },
    {
      text: 'Actions',
      align: 'right',
      value: 'name',
      sortable: false,
      width: '100px'
    }
  ],"actions":_vm.actions,"force-initial-sort":"","searchable":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('company-table-row',{attrs:{"company-id":item}})}}])},[_c('v-layout',{attrs:{"slot":"toolbar-additional-search","justify-end":""},slot:"toolbar-additional-search"},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-checkbox',{attrs:{"label":"Reseller","color":"accent","hide-details":""},model:{value:(_vm.isReseller),callback:function ($$v) {_vm.isReseller=$$v},expression:"isReseller"}})],1)],1),_c('companies-view-filters',{attrs:{"slot":"filters","store":"companies"},slot:"filters"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }