<template>
  <v-card>
    <v-dialog v-if="download" v-model="internalDialog" max-width="500">
      <v-card>
        <v-container>
          <v-select
            v-model="locale"
            :items="locales"
            data-vv-name="type"
            label="language"
          />
          <v-layout row justify-space-around>
            <v-btn
              color="white"
              depressed
              :loading="loading"
              @click="internalDialog = false"
              >CANCEL</v-btn
            >
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              @click="download"
              >DOWNLOAD</v-btn
            >
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
export default {
  name: "download-credit-note-dialog",
  props: {
    downloadDialog: {
      type: Boolean,
      default: false
    },
    creditNoteId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      locale: "nl",
      locales: ["nl", "fr"]
    };
  },
  computed: {
    internalDialog: {
      get() {
        return this.downloadDialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    async download() {
      this.loading = true;
      await this.$store.dispatch("invoice/creditNotePdf", {
        creditNoteId: this.creditNoteId,
        locale: this.locale
      });
      this.loading = false;
    }
  }
};
</script>
