<template>
  <div>
    <company-chart-filters v-if="isStoreRegistered" />
    <vue-apex-charts
      ref="submissionsChart"
      height="350"
      type="line"
      :options="options"
      :series="series"
    />
  </div>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import ChartMixin from "@/components/mixins/chart/ChartMixin";
import CompanyChartFilters from "@/views/companies/statistics/CompanyChartFilters";

export default {
  name: "company-statistics-view",
  components: {
    CompanyChartFilters,
    vueApexCharts
  },
  mixins: [ChartMixin],
  data() {
    return {
      store: "company-submissions-chart",
      id: this.$store.state.company.model.id,
      options: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "11px",
            fontFamily: "Helvetica, Arial, sans-serif",
            colors: ["#304758"]
          }
        },
        stroke: {
          width: 2
        },
        tooltip: {
          x: {
            show: false
          }
        },
        title: {
          text: "Company offers data",
          align: "center",
          margin: 10,
          floating: false,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        xaxis: {
          type: "datetime",
          labels: {
            offsetX: 23
          },
          axisTicks: {
            offsetX: 23
          }
        },
        yaxis: [
          {
            min: 0,
            title: {
              text: "Offers"
            },
            forceNiceScale: true
          }
        ]
      },
      series: [
        {
          name: "Total offers",
          data: [],
          type: "bar"
        },
        {
          name: "Sold offers",
          data: [],
          type: "bar"
        },
        {
          name: "Rejected offers",
          data: [],
          type: "bar"
        }
      ]
    };
  },
  watch: {
    data: {
      handler(newVal) {
        this.series.forEach(e => {
          e.data = [];
        });
        newVal.forEach(e => {
          this.series[0].data.push([new Date(e.time).getTime(), e.submissions]);
          this.series[1].data.push([new Date(e.time).getTime(), e.offers]);
          this.series[2].data.push([
            new Date(e.time).getTime(),
            e.rejectedOffers
          ]);
        });
      }
    },
    deep: true
  }
};
</script>
<style scoped>
.company-lead-overview-container {
  padding: 24px;
}
</style>
