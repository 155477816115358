<template>
  <tr width="500px">
    <td>
      {{ name }}
    </td>
    <td>
      <v-edit-dialog :return-value.sync="defaultPrice">
        <template v-if="isNotNull(defaultPrice)">
          <v-chip color="accent" label small>{{
            localizedPrice(defaultPrice)
          }}</v-chip>
        </template>
        <template v-else>
          {{ localizedPrice(defaultPrice) }}
        </template>
        <template v-slot:input>
          <subject-price-dialog v-model="defaultPrice" label="Price" />
        </template>
      </v-edit-dialog>
    </td>
    <td>
      <v-edit-dialog :return-value.sync="bePrice">
        <template v-if="isNotNull(bePrice)">
          <v-chip :color="getChipColor('BE')" text-color="white" label small>{{
            localizedPrice(bePrice)
          }}</v-chip>
        </template>
        <template v-else>
          {{ localizedPrice(bePrice) }}
        </template>
        <template v-slot:input>
          <subject-price-dialog v-model="bePrice" label="Price" />
        </template>
      </v-edit-dialog>
    </td>
    <td>
      <v-edit-dialog :return-value.sync="nlPrice">
        <template v-if="isNotNull(nlPrice)">
          <v-chip :color="getChipColor('NL')" text-color="white" label small>{{
            localizedPrice(nlPrice)
          }}</v-chip>
        </template>
        <template v-else>
          {{ localizedPrice(nlPrice) }}
        </template>
        <template v-slot:input>
          <subject-price-dialog v-model="nlPrice" label="Price" />
        </template>
      </v-edit-dialog>
    </td>
    <td>
      <v-edit-dialog :return-value.sync="frPrice">
        <template v-if="isNotNull(frPrice)">
          <v-chip :color="getChipColor('FR')" text-color="white" label small>{{
            localizedPrice(frPrice)
          }}</v-chip>
        </template>
        <template v-else>
          {{ localizedPrice(frPrice) }}
        </template>
        <template v-slot:input>
          <subject-price-dialog v-model="frPrice" label="Price" />
        </template>
      </v-edit-dialog>
    </td>
    <td>
      <v-edit-dialog :return-value.sync="dePrice">
        <template v-if="isNotNull(dePrice)">
          <v-chip :color="getChipColor('DE')" text-color="white" label small>{{
            localizedPrice(dePrice)
          }}</v-chip>
        </template>
        <template v-else>
          {{ localizedPrice(dePrice) }}
        </template>
        <template v-slot:input>
          <subject-price-dialog v-model="dePrice" label="Price" />
        </template>
      </v-edit-dialog>
    </td>
    <td>
      <v-edit-dialog :return-value.sync="gbPrice">
        <template v-if="isNotNull(gbPrice)">
          <v-chip :color="getChipColor('GB')" text-color="white" label small>{{
            localizedPrice(gbPrice)
          }}</v-chip>
        </template>
        <template v-else>
          {{ localizedPrice(gbPrice) }}
        </template>
        <template v-slot:input>
          <subject-price-dialog v-model="gbPrice" label="Price" />
        </template>
      </v-edit-dialog>
    </td>
    <td>
      <v-edit-dialog :return-value.sync="luPrice">
        <template v-if="isNotNull(luPrice)">
          <v-chip :color="getChipColor('LU')" text-color="white" label small>{{
            localizedPrice(luPrice)
          }}</v-chip>
        </template>
        <template v-else>
          {{ localizedPrice(luPrice) }}
        </template>
        <template v-slot:input>
          <subject-price-dialog v-model="luPrice" label="Price" />
        </template>
      </v-edit-dialog>
    </td>
    <td>
      <v-btn icon @click="deleteSubject()">
        <v-icon>delete</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import { GET_LOCALIZED_CURRENCY } from "@/store/modules/locales/getter-types";
import { isNil } from "lodash";
import SubjectPriceDialog from "./SubjectPriceDialog";

const { mapGetters: mapSubjectGetters } = createNamespacedHelpers(
  "company/subjects"
);

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "company-subject-table-row",
  components: { SubjectPriceDialog },
  props: {
    subjectId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapSubjectGetters([FIND_BY_ID]),
    subject() {
      return this[FIND_BY_ID](this.subjectId);
    },
    name() {
      return this.$store.getters["company/subjects/name"](this.subjectId);
    },
    prices() {
      return this.$store.getters["company/subjects/prices"](this.subjectId);
    },
    defaultPrice: {
      get() {
        return this.$store.getters["company/subjects/defaultPrice"](
          this.subjectId
        );
      },
      set(value) {
        this.$store.dispatch("company/subjects/updatePrice", {
          companyId: this.$store.state.company.model.id,
          companySubjectId: this.subjectId,
          price: value
        });
      }
    },
    bePrice: {
      get() {
        return (
          this.$store.getters["company/subjects/countryPrice"](
            this.subjectId,
            "BE"
          ) ?? this.defaultCountryPrice("BE")
        );
      },
      set(value) {
        this.$store.dispatch("company/subjects/updateCountryPrice", {
          companyId: this.$store.state.company.model.id,
          companySubjectId: this.subjectId,
          countryCode: "BE",
          price: value
        });
      }
    },
    nlPrice: {
      get() {
        return (
          this.$store.getters["company/subjects/countryPrice"](
            this.subjectId,
            "NL"
          ) ?? this.defaultCountryPrice("NL")
        );
      },
      async set(value) {
        await this.$store.dispatch("company/subjects/updateCountryPrice", {
          companyId: this.$store.state.company.model.id,
          companySubjectId: this.subjectId,
          countryCode: "NL",
          price: value
        });
      }
    },
    frPrice: {
      get() {
        return (
          this.$store.getters["company/subjects/countryPrice"](
            this.subjectId,
            "FR"
          ) ?? this.defaultCountryPrice("FR")
        );
      },
      set(value) {
        this.$store.dispatch("company/subjects/updateCountryPrice", {
          companyId: this.$store.state.company.model.id,
          companySubjectId: this.subjectId,
          countryCode: "FR",
          price: value
        });
      }
    },
    dePrice: {
      get() {
        return (
          this.$store.getters["company/subjects/countryPrice"](
            this.subjectId,
            "DE"
          ) ?? this.defaultCountryPrice("DE")
        );
      },
      set(value) {
        this.$store.dispatch("company/subjects/updateCountryPrice", {
          companyId: this.$store.state.company.model.id,
          companySubjectId: this.subjectId,
          countryCode: "DE",
          price: value
        });
      }
    },
    gbPrice: {
      get() {
        return (
          this.$store.getters["company/subjects/countryPrice"](
            this.subjectId,
            "GB"
          ) ?? this.defaultCountryPrice("GB")
        );
      },
      set(value) {
        this.$store.dispatch("company/subjects/updateCountryPrice", {
          companyId: this.$store.state.company.model.id,
          companySubjectId: this.subjectId,
          countryCode: "GB",
          price: value
        });
      }
    },
    luPrice: {
      get() {
        return (
          this.$store.getters["company/subjects/countryPrice"](
            this.subjectId,
            "LU"
          ) ?? this.defaultCountryPrice("LU")
        );
      },
      set(value) {
        this.$store.dispatch("company/subjects/updateCountryPrice", {
          companyId: this.$store.state.company.model.id,
          companySubjectId: this.subjectId,
          countryCode: "LU",
          price: value
        });
      }
    }
  },
  methods: {
    localizedPrice(price) {
      return !isNil(price)
        ? this.$store.getters[`locales/${GET_LOCALIZED_CURRENCY}`](price)
        : null;
    },
    isNotNull(price) {
      return !isNil(price);
    },
    deleteSubject() {
      this.$store.dispatch("company/subjects/deleteSubject", {
        companyId: this.$store.state.company.model.id,
        companySubjectId: this.subjectId
      });
    },
    defaultCountryPrice(iso) {
      return this.$store.getters["company/subjects/defaultCountryPrice"](
        this.subject.subject,
        iso
      );
    },
    getChipColor(iso) {
      const hasCountryPrice = !isNil(
        this.$store.getters["company/subjects/countryPrice"](
          this.subjectId,
          iso
        )
      );
      return hasCountryPrice ? "secondary" : "grey";
    }
  }
};
</script>
