import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import initialState from "./state";
import offersModule from "./offers";
import categoriesModule from "./categories";
import subjectsModule from "./subjects";
import subjectModule from "./subject";
import deliveryMethodsModule from "./delivery-methods";
import deliveryMethodModule from "./delivery-method";
import companyFiltersModule from "./company-filters";
import InvoicesModule from "./invoices";
import filterLogsModule from "./filter-logs";

export default {
  namespaced: true,
  state: () => initialState(),
  getters,
  actions,
  mutations,
  modules: {
    offers: offersModule,
    categories: categoriesModule,
    deliveryMethods: deliveryMethodsModule,
    deliveryMethod: deliveryMethodModule,
    companyFilters: companyFiltersModule,
    subjects: subjectsModule,
    subject: subjectModule,
    invoices: InvoicesModule,
    filterLogs: filterLogsModule
  }
};
