import crudActions from "@/store/templates/crud/actions";
import api from "@/api";
import { SET_MODEL } from "@/store/templates/crud/mutation-types";

export default {
  ...crudActions(api.company, "company"),
  async isValidVat(context, vatNumber) {
    return await api.company.isValidVat(vatNumber);
  },
  async isVerifiedVat(context, vatNumber) {
    return await api.company.isVerifiedVat(vatNumber);
  },
  async isUniqueVat(context, vatNumber) {
    return await api.company.fetchAll({ filters: vatNumber });
  },
  async updateCompany(state, company) {
    const { result } = await api.company.update(company.id, company);
    return result;
  },
  async makeCustomer(state, { companyId, ...customerData }) {
    const { result } = await api.company.makeCustomer(companyId, customerData);
    return result;
  },
  async updateContactPersons({ state, commit }, contactPersons) {
    const { entities, result } = await api.company.updateContactPersons(
      state.model.id,
      contactPersons
    );
    commit(SET_MODEL, { entities, id: result });
    return result;
  },
  async updateCustomers({ state, commit }, customers) {
    const { entities, result } = await api.company.updateCustomers(
      state.model.id,
      customers
    );
    commit(SET_MODEL, { entities, id: result });
    return result;
  },
  async addCategory(state, { companyId, category }) {
    const { result } = await api.company.category.create({
      companyId,
      category
    });
    return result;
  },
  async updateSalesData({ commit }, companyId) {
    const { entities, result } = await api.company.updateSalesData(companyId);
    const company = entities.company[companyId];
    commit("setSalesData", company);
    return result;
  },
  async fetchLogs({ state, commit }) {
    const { result, entities } = await api.company.fetch(state.model.id, {
      includes: ["logs"]
    });
    commit("setLogs", { id: result, entities });
    return true;
  },
  async createLog({ state, commit }, log) {
    const { result, entities } = await api.company.log.create(
      state.model.id,
      log
    );
    commit("addLog", { id: result, logEntities: entities.log });
    return result;
  },
  async updateLog(context, log) {
    await api.log.update(log.id, log);
    return true;
  },
  async deleteLog(context, logId) {
    const result = await api.log.remove(logId);
    return result;
  },
  async resetPassword({ dispatch }, payload) {
    const result = await api.company.passwordReset(payload);
    dispatch(
      "snackbar/addItem",
      {
        text: result.data.message,
        color: result.status === 200 ? "success" : "error"
      },
      { root: true }
    );
  },
  async changeUserEmail({ state, dispatch }, payload) {
    const result = await api.company.newEmailRequest(payload);
    dispatch("fetch", { id: state.model.id });
    dispatch(
      "snackbar/addItem",
      {
        text: result.data.message,
        color: result.status === 200 ? "success" : "error"
      },
      { root: true }
    );
  },
  async savePausedSettings({ state, dispatch }, payload) {
    const result = await api.company.savePausedSettings(
      state.model.id,
      payload
    );
    dispatch("fetch", { id: state.model.id });
    if (result.status === 200) {
      dispatch(
        "snackbar/addItem",
        {
          text: "Settings saved",
          color: "success"
        },
        { root: true }
      );
    }
  },
  async saveGeneralSettings({ state, dispatch }, payload) {
    const result = await api.company.saveGeneralSettings(
      state.model.id,
      payload
    );
    dispatch("fetch", { id: state.model.id });
    if (result.status === 200) {
      dispatch(
        "snackbar/addItem",
        {
          text: "Settings saved",
          color: "success"
        },
        { root: true }
      );
    }
  },
  async saveFilterComment({ state, dispatch }, comment) {
    const result = await api.company.saveFilterComment(state.model.id, comment);
    dispatch("fetch", { id: state.model.id });
    dispatch(
      "snackbar/addItem",
      {
        text:
          result.status === 200
            ? "Filter comment saved"
            : "Something went wrong",
        color: result.status === 200 ? "success" : "error"
      },
      { root: true }
    );
  }
};
