<template>
  <v-container class="wrapper">
    <v-alert v-if="hasRejectableOffers" :value="true" type="warning">
      Invoice has an offer that is in it's rejectable period
    </v-alert>
    <v-alert v-if="hasRejectionRequestedOffers" :value="true" type="warning">
      Invoice has an offer that has an open reject request
    </v-alert>
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapGetters } = createNamespacedHelpers("invoice");

export default {
  name: "invoice-warnings",
  components: {},
  data: () => ({}),
  computed: {
    ...mapGetters(["hasRejectableOffers", "hasRejectionRequestedOffers"])
  },
  methods: {}
};
</script>
<style scoped>
.wrapper {
  padding-top: 50px;
  margin: 0 auto;
  min-width: 760px;
}
</style>
