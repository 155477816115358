<template>
  <v-card>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Bedrijfsgegevens
      </v-toolbar-title>
      <company-edit-company-data />
    </v-toolbar>

    <h4 class="company-name">
      {{ name }}
    </h4>

    <div class="list-container">
      <v-icon class="list-icon">
        location_on
      </v-icon>

      <div class="list-content">
        <v-list-tile-title v-if="street != null">
          {{ street }} {{ streetNumber }} {{ box }}
        </v-list-tile-title>
        <v-list-tile-title v-if="postalCode != null || cityName != null">
          {{ postalCode }} {{ cityName }}
        </v-list-tile-title>
        <v-list-tile-title v-if="countryName != null">
          {{ countryName }}
        </v-list-tile-title>
      </div>

      <template
        v-if="
          company.telephoneNumbers != null &&
            company.telephoneNumbers.length >= 1
        "
      >
        <v-icon class="list-icon">
          phone
        </v-icon>

        <div class="list-content">
          <v-list-tile-title
            v-for="(telephoneNumber, index) in company.telephoneNumbers"
            :key="index"
          >
            {{ telephoneNumber }}
          </v-list-tile-title>
        </div>
      </template>

      <template
        v-if="
          company.emailAddresses != null && company.emailAddresses.length >= 1
        "
      >
        <v-icon class="list-icon">
          email
        </v-icon>

        <div class="list-content">
          <v-list-tile-title
            v-for="(emailAddress, index) in company.emailAddresses"
            :key="index"
          >
            {{ emailAddress }}
          </v-list-tile-title>
        </div>
      </template>

      <template v-if="company.urls != null && company.urls.length >= 1">
        <v-icon class="list-icon">
          link
        </v-icon>

        <div class="list-content">
          <v-list-tile-title v-for="(url, index) in urls" :key="index">
            <a :href="nonRelativeURL(url.url)" target="_blank">
              {{ url.url }}
            </a>
            <span class="url__public">
              {{ url.public ? "Public" : "Private" }}
            </span>
          </v-list-tile-title>
        </div>
      </template>
    </div>
  </v-card>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CompanyEditCompanyData from "@/components/company/edit/CompanyEditCompanyData";
const { mapGetters } = createNamespacedHelpers("company");
import { isNil } from "lodash";

export default {
  name: "company-details-company-data",
  components: { CompanyEditCompanyData },
  computed: {
    ...mapGetters([
      "company",
      "name",
      "countryName",
      "urls",
      "street",
      "streetNumber",
      "postalCode",
      "box",
      "cityName"
    ])
  },
  methods: {
    nonRelativeURL(url) {
      let splitURL = url ? url.toLowerCase().split("/") : null;
      if (isNil(splitURL)) {
        return null;
      } else {
        if (splitURL[0] === "http:" || splitURL[0] === "https:") {
          return url;
        } else {
          return `//${url}`;
        }
      }
    }
  }
};
</script>
<style scoped>
.list-container {
  padding: 20px;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  font-size: 16px;
  grid-gap: 20px;
}
.list-icon {
  justify-self: center;
}
.company-name {
  margin: 1em 0 0 20px;
}
.url__public {
  margin-left: 16px;
}
</style>
