<template>
  <v-dialog v-model="deleteDialog" width="500px">
    <v-card>
      <v-toolbar :color="'red'" dark>
        <v-toolbar-title>
          Are you sure?
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div>{{ name }}</div>
      </v-card-text>
      <v-card-actions>
        <v-btn flat class="cancelClass" @click="deleteDialog = false">
          Cancel
        </v-btn>
        <v-btn :loading="loading" dark color="red" @click="deleteSubject">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
const { mapGetters: mapSubjectGetters } = createNamespacedHelpers(
  "company/subjects"
);

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "company-subject-delete-dialog",
  props: {
    subjectId: {
      type: Number,
      required: true
    },
    dialog: { type: Boolean, default: false }
  },
  data: function() {
    return {
      maxChips: 5,
      loading: false
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapSubjectGetters([FIND_BY_ID]),
    subject() {
      return this[FIND_BY_ID](this.categoryId);
    },
    name() {
      return this.$store.getters["company/subjects/name"](this.subjectId);
    },
    deleteDialog: {
      get: function() {
        return this.dialog;
      },
      set: function(newValue) {
        this.$emit("update:dialog", newValue);
      }
    }
  },
  methods: {
    async deleteSubject() {
      this.loading = true;
      await this.$store.dispatch(`company/categories/delete`, this.categoryId);
      this.$emit("success");
      this.loading = false;
      this.deleteDialog = false;
    }
  }
};
</script>

<style scoped></style>
