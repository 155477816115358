var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-lead-overview-container"},[_c('table-overview',{attrs:{"title":"Leads","store":"company/offers","headers":[
      { text: 'Offer ID', value: 'id', width: '100px' },
      { text: 'Name', sortable: false, value: 'name' },
      { text: 'Lead Offers', sortable: false, value: 'lead' },
      { text: 'Date offered', value: 'distributed_at' },
      {
        text: 'Open',
        align: 'center',
        value: 'actions',
        sortable: false,
        width: '100px'
      }
    ],"searchable":"","sidebar-open-by-default":true,"sidebar-sticky":true,"disable-page-reset":"","force-initial-sort":"distributed_at","descending":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('company-offer-table-row',{attrs:{"offer":item}})}}])},[_c('company-offers-export',{attrs:{"slot":"toolbar-actions","filters":_vm.filters,"pagination":_vm.pagination},slot:"toolbar-actions"}),_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":"","height":3},slot:"progress"}),_c('company-offers-view-filters',{attrs:{"slot":"filters","store":"company/offers"},slot:"filters"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }