import { render, staticRenderFns } from "./CompanyDetailsLogs.vue?vue&type=template&id=47c289ca&scoped=true&"
import script from "./CompanyDetailsLogs.vue?vue&type=script&lang=js&"
export * from "./CompanyDetailsLogs.vue?vue&type=script&lang=js&"
import style0 from "./CompanyDetailsLogs.vue?vue&type=style&index=0&id=47c289ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c289ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VSpacer,VTimeline,VToolbar,VToolbarTitle})
