var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:{
    odd: _vm.isOdd,
    warning:
      !_vm.isRejectionExpired ||
      (_vm.offer.isRejectionRequested && !_vm.offer.isCreditingIssued)
  },style:(!_vm.isRejectionExpired ||
    (_vm.offer.isRejectionRequested && !_vm.offer.isCreditingIssued)
      ? { border: 'solid #bc5a01 2px !important' }
      : {})},[_c('div',{staticClass:"grid-row"},[_c('p',{staticClass:"py-1 ma-0"},[_c('strong',[_vm._v("#"+_vm._s(_vm.offer.id))]),_vm._v(" - "+_vm._s(_vm.submission.firstName)+" "+_vm._s(_vm.submission.lastName)+" - "+_vm._s(_vm.postalCode)+" "+_vm._s(_vm.city)+" "),(!_vm.isRejectionExpired)?_c('strong',[_vm._v("!! OFFER IS STILL IN IT'S REJECTABLE PERIOD !!")]):_vm._e(),(_vm.offer.isRejectionRequested && !_vm.offer.isCreditingIssued)?_c('strong',[_vm._v("!! OFFER HAS AN OPEN REJECTION REQUEST !!")]):_vm._e()]),_c('v-btn',{staticClass:"reject-btn",attrs:{"flat":"","color":"error","disabled":_vm.offer.isCreditingIssued},on:{"click":function($event){_vm.showOfferDialog = !_vm.showOfferDialog}}},[_vm._v(_vm._s(_vm.offer.isCreditingIssued ? "CREDITING" : "REJECT"))]),_c('p',{staticClass:"py-1 ma-0 text-sm-right"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.offer.price))+" ")])],1),_c('invoice-create-item-offer-reject-dialog',{attrs:{"offer-id":_vm.offerId,"show-dialog":_vm.showOfferDialog,"is-in-item":_vm.isInItem},on:{"close":function($event){_vm.showOfferDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }