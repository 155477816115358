<template>
  <tr width="500px">
    <td>
      {{ name.nl_be }}
    </td>
    <td>
      <v-btn icon @click="$emit('delete', category.id)">
        <v-icon>delete</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapCategoryGetters } = createNamespacedHelpers(
  "company/categories"
);

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "company-category-table-row",
  props: {
    categoryId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapCategoryGetters([FIND_BY_ID]),
    category() {
      return this[FIND_BY_ID](this.categoryId);
    },
    name() {
      return this.$store.getters["company/categories/name"](this.categoryId);
    }
  }
};
</script>
