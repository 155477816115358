<template>
  <div class="company-lead-overview-container">
    <table-overview
      title="Leads"
      store="company/offers"
      :headers="[
        { text: 'Offer ID', value: 'id', width: '100px' },
        { text: 'Name', sortable: false, value: 'name' },
        { text: 'Lead Offers', sortable: false, value: 'lead' },
        { text: 'Date offered', value: 'distributed_at' },
        {
          text: 'Open',
          align: 'center',
          value: 'actions',
          sortable: false,
          width: '100px'
        }
      ]"
      searchable
      :sidebar-open-by-default="true"
      :sidebar-sticky="true"
      disable-page-reset
      force-initial-sort="distributed_at"
      descending
    >
      <company-offers-export
        slot="toolbar-actions"
        :filters="filters"
        :pagination="pagination"
      />
      <v-progress-linear slot="progress" indeterminate :height="3" />
      <company-offer-table-row
        slot="table-row"
        slot-scope="{ item }"
        :offer="item"
      />
      <company-offers-view-filters slot="filters" store="company/offers" />
    </table-overview>
  </div>
</template>
<script type="text/babel">
import TableOverview from "@/components/generic/overview/TableOverview";
import { createNamespacedHelpers } from "vuex";
import CompanyOfferTableRow from "@/components/company/offers/CompanyOfferTableRow";
import CompanyOffersViewFilters from "@/components/company/offers/CompanyOffersViewFilter";
import api from "@/api";
import FileSaver from "file-saver";
import CompanyOffersExport from "@/components/company/offers/CompanyOffersExport";
import {
  FILTERS as GETTER_FILTERS,
  PAGINATION as GETTER_PAGINATION
} from "@/store/templates/table/getter-types";

const { mapGetters } = createNamespacedHelpers("company/offers");

export default {
  name: "company-offers-view",
  components: {
    CompanyOffersExport,
    CompanyOffersViewFilters,
    CompanyOfferTableRow,
    TableOverview
  },
  computed: {
    ...mapGetters(["offer"]),
    filters() {
      return this.$store.getters[`company/offers/${GETTER_FILTERS}`];
    },
    pagination() {
      return this.$store.getters[`company/offers/${GETTER_PAGINATION}`];
    }
  },
  methods: {
    async exportLeads() {
      const { data, fileName } = await api.company.offer.exportLeads();

      // Let the user save the file.
      const blob = new Blob([data], { type: "text/csv" });
      FileSaver.saveAs(blob, `${fileName}.csv`);
    }
  }
};
</script>
<style scoped>
.company-lead-overview-container {
  padding: 24px;
}
</style>
