var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subject-table-container"},[_c('table-overview',{attrs:{"title":"Subjects","store":"company/subjects","headers":[
      { text: 'Subject', sortable: false, value: 'subject' },
      { text: 'Default price', sortable: false },
      { text: 'BE price', sortable: false },
      { text: 'NL price', sortable: false },
      { text: 'FR price', sortable: false },
      { text: 'DE price', sortable: false },
      { text: 'GB price', sortable: false },
      { text: 'LU price', sortable: false },
      {
        text: 'Delete',
        sortable: false,
        value: 'delete',
        align: 'center',
        width: '100px'
      }
    ],"items":_vm.subjects},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('company-subject-table-row',{attrs:{"subject-id":item},on:{"delete":_vm.openDeleteDialog}})}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":"","height":3},slot:"progress"})],1),_c('company-subject-add',{on:{"get-subjects":function($event){return _vm.getSubjects()}}}),(_vm.deleteDialog)?_c('company-subject-delete-dialog',{attrs:{"dialog":_vm.deleteDialog,"subject-id":_vm.deleteSubjectId},on:{"success":_vm.getSubjects,"update:dialog":function($event){_vm.deleteDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }