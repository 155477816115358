<template>
  <div class="pa-3">
    <div class="grid-container bottom-border header pb-2">
      <div class="item-start"><b>ITEM</b></div>
      <div class="item-end"><b>QUANTITY</b></div>
      <div class="item-end"><b>PRICE</b></div>
      <div class="item-end"><b>TOTAL</b></div>
    </div>
    <div v-if="!companySelected" class="action-required bottom-border pa-2">
      <p>No invoice items. Select an affiliate to continue</p>
    </div>
    <div v-else>
      <p
        v-if="!(hasItems || hasTempItem) && !selecting && !selectionOffers"
        class="action-required bottom-border pa-2 ma-0"
      >
        No invoice items.
      </p>
      <div v-if="hasItems" class="items-container">
        <div v-for="(item, i) in items" :key="i">
          <invoice-create-item-info :item="item" :index="i" />
        </div>
      </div>
      <invoice-create-item v-if="hasTempItem" />
      <b-date-picker
        v-if="selectionOffers"
        v-model="distributedUntill"
        label="Offers untill"
      />
      <invoice-create-item-offers
        v-if="selectionOffers"
        @items-created="selectionOffers = false"
      />
      <div v-if="selecting" class="option-grid bottom-border">
        <v-btn color="secondary" @click="selectOfferTimeFrame">OFFERS</v-btn>
        <v-btn color="primary" @click="addTempItem">MANUAL</v-btn>
      </div>
      <v-fab-transition>
        <v-btn
          v-show="
            !selecting && !hasTempItem && itemEdit === null && !selectionOffers
          "
          color="pink"
          fab
          dark
          small
          bottom
          class="add-button"
          @click="selecting = true"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
const { mapComputed } = createNamespacedHelpers("invoice");
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
const { mapGetters } = VuexNamespacedHelpers("invoice");
import { isNil } from "lodash";
import InvoiceCreateItem from "./InvoiceCreateItem";
import InvoiceCreateItemOffers from "./InvoiceCreateItemOffers";
import InvoiceCreateItemInfo from "./InvoiceCreateItemInfo";
import BDatePicker from "../generic/input/dates/BDatePicker";

export default {
  name: "invoice-create-items",
  components: {
    BDatePicker,
    InvoiceCreateItemInfo,
    InvoiceCreateItemOffers,
    InvoiceCreateItem
  },
  inject: ["$validator"],
  data: () => ({
    selecting: false,
    itemEdit: null,
    editing: false,
    selectionOffers: false
  }),
  computed: {
    ...mapComputed(["distributedUntill"]),
    ...mapGetters(["modelCompany"]),
    companySelected() {
      return !isNil(this.modelCompany);
    },
    items: {
      get() {
        return this.$store.getters["invoice/items"];
      },
      set(newVal) {
        return this.$store.commit("invoice/setItems", newVal);
      }
    },
    hasItems() {
      return this.items.length > 0;
    },
    hasTempItem() {
      return !isNil(this.$store.getters["invoice/tempItem"]);
    }
  },
  watch: {
    async distributedUntill(newVal) {
      if (!isNil(newVal)) {
        await this.$store.dispatch("invoice/fetchInvoiceableOffers");
      }
    },
    selectionOffers: {
      handler(newVal) {
        if (
          !newVal &&
          !this.editing &&
          this.hasItems &&
          !this.hasTempItem &&
          !this.selecting
        ) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    },
    selecting: {
      handler(newVal) {
        if (
          !newVal &&
          !this.editing &&
          this.hasItems &&
          !this.hasTempItem &&
          !this.selectionOffers
        ) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    },
    editing: {
      handler(newVal) {
        if (
          !newVal &&
          !this.selecting &&
          this.hasItems &&
          !this.hasTempItem &&
          !this.selectionOffers
        ) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    },
    hasItems: {
      handler(newVal) {
        if (
          !this.selecting &&
          !this.editing &&
          newVal &&
          !this.hasTempItem &&
          !this.selectionOffers
        ) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    },
    hasTempItem: {
      handler(newVal) {
        if (
          !this.selecting &&
          !this.editing &&
          this.hasItems &&
          !newVal &&
          !this.selectionOffers
        ) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    }
  },
  methods: {
    addItem() {
      this.$store.commit("invoice/addItem");
    },
    selectOfferTimeFrame() {
      this.selecting = false;
      this.selectionOffers = true;
    },
    addTempItem() {
      this.selecting = false;
      this.$store.commit("invoice/addTempItem");
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    },
    deleteItem(index) {
      this.$store.commit("invoice/deleteItem", index);
    },
    editItem(index) {
      this.itemEdit = index;
      this.editing = true;
    },
    itemEdited() {
      this.itemEdit = null;
      this.editing = false;
    }
  }
};
</script>
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.bottom-border {
  border-bottom: 1px solid lightgray;
}
.option-grid {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-gap: 10px;
}
.header {
  font-size: 18px;
}
.description-grid {
  display: grid;
  grid-template-columns: 9fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.items {
  font-size: 16px;
}
.item-end {
  justify-self: end;
}
.action-required {
  border-bottom: 1px solid lightgray;
  text-align: center;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }
}
.text-end {
  text-align: end;
}
.add-button {
  left: 50%;
}
.item-btn {
  background-color: #e8e8e8;
}
</style>
