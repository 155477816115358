<template>
  <v-expansion-panel-content lazy>
    <div slot="header">
      {{ category.name.nl_be }}
    </div>
    <v-card>
      <v-card-text class="text-xs-center" v-if="isLoading">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </v-card-text>
      <v-card-text v-else>
        <filter-specifier-question
          v-for="(question, i) in shownQuestions"
          :key="question.id"
          :question="question"
          :specifier="getSpecifierForQuestion(question)"
          :odd="i % 2 === 1"
          @specifier-add="
            $store.commit('filter/addSpecifier', {
              category: category,
              question
            })
          "
          @specifier-remove="
            $store.commit('filter/removeSpecifier', {
              category: category,
              question
            })
          "
          @specifier-update="
            $store.commit('filter/updateSpecifier', {
              category: category,
              question,
              specifier: arguments[0]
            })
          "
        />
      </v-card-text>
    </v-card>
  </v-expansion-panel-content>
</template>

<script type="text/babel">
import FilterSpecifierQuestion from "./FilterSpecifierQuestion";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("filter");

export default {
  name: "filter-specifier",
  components: { FilterSpecifierQuestion },
  props: {
    filterCategory: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    alwaysVisibleQuestionIds: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      "areAlwaysVisibleQuestionsLoaded",
      "areQuestionsLoadedForCategory",
      "categoryQuestions"
    ]),
    areCategoryQuestionsLoaded() {
      return this.$store.getters["filter/areQuestionsLoadedForCategory"](
        this.filterCategory.category
      );
    },
    isLoading() {
      return (
        !this.areAlwaysVisibleQuestionsLoaded ||
        !this.areQuestionsLoadedForCategory(this.filterCategory.category)
      );
    },
    category() {
      return this.$store.state.filter.category[this.filterCategory.category];
    },
    alwaysVisibleQuestions() {
      return this.$store.getters["filter/alwaysVisibleQuestions"];
    },
    questions() {
      return this.$store.getters["filter/categoryQuestions"](
        this.filterCategory.category
      );
    },
    shownQuestions() {
      return this.alwaysVisibleQuestions
        .concat(this.questions)
        .filter(question =>
          ["SELECT", "INPUT_RADIO", "INPUT_CHECKBOX"].includes(question.type)
        );
    }
  },
  watch: {
    selected(newValue) {
      if (newValue) {
        this.initCategory();
      }
    }
  },
  methods: {
    initCategory() {
      if (!this.areCategoryQuestionsLoaded) {
        this.$store.dispatch("filter/loadCategoryQuestions", {
          categoryId: this.filterCategory.category
        });
      }
    },
    getSpecifierForQuestion(question) {
      return this.filterCategory.specifiers.find(
        specifier => specifier.question === question.rootId
      );
    }
  }
};
</script>
<style scoped></style>
