import tableGetters from "@/store/templates/table/getters";
import { isNil } from "lodash";
import { denormalize } from "normalizr";
import { offer as offerSchema } from "@/store/schema";
import { IDS } from "@/store/templates/table/getter-types";

export default {
  ...tableGetters,
  [IDS]: state =>
    state.ids.map(offerId =>
      denormalize(state.entity[offerId], offerSchema, state)
    ),
  lead: state => offerId =>
    !isNil(state.entity[offerId].lead)
      ? state.lead[state.entity[offerId].lead]
      : {},
  submission: (state, getters) => offerId =>
    state.submission[getters.lead(offerId).submission],
  defaultSubject: (state, getters) => offerId =>
    state.subject
      ? state.subject[getters.lead(offerId).defaultSubject].name
      : "",
  subject: state => offerId =>
    state.subject ? state.subject[state.entity[offerId].subject].name : "",
  fullName: (state, getters) => offerId => getters.submission(offerId).fullName,
  companies: (state, getters) => offerId =>
    getters
      .lead(offerId)
      .offers.map(offerId => state.company[state.offer[offerId].company])
};
