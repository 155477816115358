<template>
  <div class="text-xs-center">
    <v-dialog v-model="internalDialog" width="500">
      <v-card>
        <v-toolbar color="primary" dense>
          <v-toolbar-title class="white--text">
            User account
          </v-toolbar-title>
        </v-toolbar>
        <v-alert :value="true" :type="alertColor">
          {{ message }}
        </v-alert>
        <v-container>
          <v-text-field
            v-model="primaryUser.email"
            label="Current E-mail"
            disabled
          />
          <v-text-field
            v-model="newEmail"
            v-validate="'required|max:255'"
            label="Requested Email"
            :disabled="disabled"
            :hint="hint"
            :persistent-hint="true"
          />
        </v-container>
        <v-layout justify-center row>
          <v-btn
            :disabled="disabled"
            color="secondary"
            class="white--text"
            @click="sentRequest"
          >
            Send request
            <v-icon right dark>email</v-icon>
          </v-btn>
          <v-btn
            v-if="disabled"
            color="secondary"
            class="white--text"
            @click="cancelRequest"
          >
            Cancel
            <v-icon right dark>far fa-ban</v-icon>
          </v-btn>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/babel">
import CanValidate from "@/components/mixins/validation/CanValidate";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import { cloneDeep } from "lodash";

export default {
  name: "company-edit-user-data",
  components: {},
  mixins: [CanValidate, IsValidationRoot],
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newEmail: null,
      disabled: false
    };
  },
  created() {
    this.newEmail = cloneDeep(this.primaryUser.requestedEmail);
    this.disabled = this.primaryUser.verificationStatus === "pending";
  },
  computed: {
    primaryUser() {
      return this.$store.getters["company/primaryUser"];
    },
    internalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    },
    message() {
      switch (this.primaryUser.verificationStatus) {
        case "pending":
          return "A request has been sent to the user to change the email.";
        case "unverified":
          return "The did user not yet verify the email.";
        default:
          return "The email verified";
      }
    },
    alertColor() {
      if (this.primaryUser.verificationStatus === "pending") {
        return "warning";
      } else if (this.primaryUser.verificationStatus === "unverified") {
        return "error";
      } else {
        return "success";
      }
    },
    hint() {
      if (this.primaryUser.verificationStatus === "pending") {
        return "pending...";
      } else if (this.primaryUser.verificationStatus === "unverified") {
        return "sent a new verification request to this e-mail?";
      } else {
        return "Enter a new email for the user";
      }
    }
  },
  methods: {
    async sentRequest() {
      await this.$store.dispatch("company/changeUserEmail", {
        oldEmail: this.primaryUser.email,
        newEmail: this.newEmail
      });
      this.disabled = true;
    },
    cancelRequest() {
      this.disabled = false;
    }
  }
};
</script>
