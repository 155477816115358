<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <v-text-field
      slot="activator"
      v-model="formattedTime"
      :label="label"
      prepend-icon="access_time"
      readonly
      clearable
    />
    <v-time-picker
      v-model="model"
      format="24hr"
      @click:minute="$refs.menu.save(model)"
    />
  </v-menu>
</template>

<script type="text/babel">
import moment from "moment";

export default {
  name: "b-time-picker",
  props: {
    label: {
      type: String,
      default: "Pick a Time"
    },
    value: {
      type: String,
      default: null
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    model: {
      get() {
        if (!this.value) {
          return null;
        }
        return moment(this.value, "HH:mm:ss").format("HH:mm:ss");
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    formattedTime: {
      get() {
        return this.value ? moment(this.value, "HH:mm:ss").format("HH:mm") : "";
      },
      set() {
        this.model = null;
      }
    }
  }
};
</script>
