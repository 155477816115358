<template>
  <v-card>
    <v-dialog v-model="internalDialog" max-width="500">
      <v-card>
        <div class="pt-3 px-5">
          <p>
            Are you sure you want to delete the draft credit note?
          </p>
          <v-layout row justify-space-around>
            <v-btn
              color="white"
              depressed
              :loading="loading"
              @click="internalDialog = false"
              >CANCEL</v-btn
            >
            <v-btn color="primary" depressed :loading="loading" @click="remove"
              >DELETE</v-btn
            >
          </v-layout>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";

const { mapGetters: mapGetters } = createNamespacedHelpers("invoice");

export default {
  name: "delete-credit-note-dialog",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    creditNoteId: {
      type: Number,
      requires: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters(["id", "company"]),
    internalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    },
    isCompanyView() {
      return !isNil(this.companyId);
    }
  },
  methods: {
    async remove() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "invoice/deleteCreditNote",
          this.creditNoteId
        );
        this.loading = false;
        this.$emit("close");
        this.$emit("delete-success");
      } catch (e) {
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped>
p {
  text-align: center;
  font-size: 16px;
}
.blacklisted {
  background-color: var(--v-warning-base);
  border: solid #bc5a01 2px !important;
  color: #bc5a01;
}
</style>
