import tableGetters from "@/store/templates/table/getters";

export default {
  ...tableGetters,
  name: state => subjectId =>
    state.subject[state.entity[subjectId].subject].name,
  defaultPrice: state => subjectId => {
    return state.entity[subjectId].price;
  },
  countryPrice: state => (subjectId, iso) => {
    const country = state.entity[subjectId].countries.filter(country => {
      return state.companySubjectCountry[country].country.iso === iso;
    });
    return country > 0 ? state.companySubjectCountry[country[0]].price : null;
  },
  defaultCountryPrice: state => (subjectId, iso) => {
    const country = state.subject[subjectId].subjectCountries.filter(
      country => country.iso === iso
    )[0];

    return country?.leadPrice;
  }
};
