<template>
  <v-card :class="cardClass" :style="cardStyle">
    <v-container fluid>
      <v-layout v-if="isLoaded" class="categories-wrapper pb-3">
        <v-flex xs4>
          <v-list dense>
            <v-list-tile
              v-for="category in firstTierCategories"
              :key="category.id"
              :class="category === selectedFirstTierCategory ? 'accent' : ''"
              ripple
              @click="selectedFirstTierCategory = category"
            >
              <v-list-tile-content>
                <v-list-tile-title>{{ category.name.nl_be }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-icon>chevron_right</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-flex>
        <v-flex xs4>
          <v-list dense>
            <v-list-tile
              v-for="category in secondTierCategories"
              :key="category.id"
              :class="category === selectedSecondTierCategory ? 'accent' : ''"
              ripple
              @click="selectedSecondTierCategory = category"
            >
              <v-list-tile-content>
                <v-list-tile-title>{{ category.name.nl_be }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-icon>chevron_right</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-flex>
        <v-flex xs4>
          <v-list dense>
            <v-list-tile
              v-for="category in thirdTierCategories"
              :key="category.id"
              ripple
              @click="
                $store.dispatch('filter/addCategory', {
                  categoryId: category.id
                })
              "
            >
              <v-list-tile-content>
                <v-list-tile-title>{{ category.name.nl_be }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-icon>check</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-autocomplete-category v-model="categories" multiple leaf />
      </v-layout>
      <v-layout row wrap>
        <v-autocomplete-language v-model="languages" multiple />
      </v-layout>
      <v-alert :value="warn && !valid" type="warning">
        No categories selected for this filter! You'll need at least one!
      </v-alert>
    </v-container>
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { difference, isEmpty, isNil } from "lodash";
import CompanyFilterStepperMixin from "./FilterStepperMixin.vue";
import VAutocompleteCategory from "@/components/category/VAutocompleteCategory";
import VAutocompleteLanguage from "@/components/language/VAutocompleteLanguage";
import { createNamespacedHelpers as createComputedNamespacedHelpers } from "@/store/helpers";

const { mapGetters } = createNamespacedHelpers("filter");
const { mapComputed } = createComputedNamespacedHelpers("filter");

export default {
  name: "company-filter-categories",
  components: { VAutocompleteLanguage, VAutocompleteCategory },
  mixins: [CompanyFilterStepperMixin],
  data() {
    return {
      selectedFirstTierCategory: null,
      selectedSecondTierCategory: null,
      isShowingWarning: false,
      rootCategoryIds: []
    };
  },
  created() {
    if (!this.areAlwaysVisibleQuestionsLoaded) {
      this.$store.dispatch("filter/fetchAlwaysVisibleQuestions");
    }
  },
  computed: {
    ...mapGetters(["areAlwaysVisibleQuestionsLoaded"]),
    ...mapComputed(["categories", "languages"]),
    firstTierCategories() {
      return this.$store.getters["category-select/rootCategories"];
    },
    secondTierCategories() {
      if (!this.selectedFirstTierCategory) return [];
      return this.$store.getters["category-select/byIds"](
        this.selectedFirstTierCategory.children
      );
    },
    thirdTierCategories() {
      if (!this.selectedSecondTierCategory) return [];
      const unselectedChildren = difference(
        this.selectedSecondTierCategory.children,
        this.categories
      );
      return this.$store.getters["category-select/byIds"](unselectedChildren);
    },
    leafCategories() {
      return this.$store.getters["entities/getLeafCategories"];
    },
    isInitialized() {
      return this.$store.getters["entities/isInitialized"]({
        name: "category"
      });
    },
    isLoading() {
      return !this.isLoaded;
    },
    isLoaded() {
      return !isNil(this.$store.state["category-select"]);
    },
    isCategoriesEmpty() {
      return isNil(this.categories) || isEmpty(this.categories);
    }
  },
  methods: {
    validate() {
      if (this.isCategoriesEmpty) {
        this.isShowingWarning = true;
        return false;
      } else {
        this.isShowingWarning = false;
        return true;
      }
    }
  }
};
</script>
<style scoped>
.categories-wrapper {
  height: 400px;
}

.categories-wrapper > .flex {
  overflow-y: auto;
}
</style>
