<template>
  <v-dialog v-model="dialog" width="500">
    <v-btn slot="activator" icon dark>
      <v-icon>far fa-file-archive</v-icon>
    </v-btn>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">
          Download PDFs for selection as zip
        </v-toolbar-title>
      </v-toolbar>
      <v-card-actions>
        <v-btn flat @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn :loading="loading" flat @click="downloadZip">
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import api from "@/api";
import { FILTERS } from "../../store/templates/table/getter-types";

export default {
  name: "invoices-pdf-zip-export",

  data: () => ({
    dialog: false,
    loading: false
  }),
  computed: {
    filters() {
      return this.$store.getters[`invoices/${FILTERS}`];
    }
  },
  methods: {
    async downloadZip() {
      this.loading = true;
      await api.invoice.downloadZip({
        filters: this.filters
      });
      this.loading = false;
    }
  }
};
</script>
