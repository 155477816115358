import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  name: null,
  vatNumber: null,

  street: null,
  streetNumber: null,
  box: null,
  postalCode: null,
  cityName: null,
  country: null,

  language: null,
  status: null,
  categories: null,
  notes: null,
  logs: null,
  filterLogs: null,
  offers: null,
  telephoneNumbers: [""],
  emailAddresses: [""],
  urls: [
    { url: "", public: true, type: "website" },
    { public: true, type: "facebook" },
    { public: true, type: "instagram" }
  ],
  isCustomer: null,
  isReseller: null,

  fiscalBox: null,
  fiscalCityName: null,
  fiscalCountry: null,
  fiscalPostalCode: null,
  fiscalStreet: null,
  fiscalStreetNumber: null,

  totalBilled: null,
  averageAmountOfLeadsPerMonth: null,
  offerRejectionRatio: null,
  averagePricePerOffer: null,
  salesDataUpdatedAt: null,
  address: {
    street: null,
    streetNumber: null,
    box: null,
    postalCode: null,
    cityName: null,
    country: null
  },
  fiscalName: null,
  fiscalUrl: null,
  fiscalEmail: null,
  fiscalTelephone: null,
  fiscalAddress: {
    street: null,
    streetNumber: null,
    box: null,
    postalCode: null,
    cityName: null,
    country: null
  },
  paymentTerm: 14,
  isFiscalDifferent: false,
  isAutoBilled: true,
  rejectableTerm: null
});

export default () => ({
  ...initialCrudState(initialModel),
  customer: {},
  user: {},
  company: {},
  country: {},
  language: {},
  contactPerson: {},
  url: {},
  log: {},
  settings: {},
  billingData: {}
});
