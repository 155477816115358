import Vue from "vue";
import {
  country as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "@/api/url";

const geoCountryResourceUrl = createApiUrl("api/v1/geo/countries");

async function fetchCountry(
  $countryId,
  { filters, pagination, includes = ["provinces.municipalities"] } = {}
) {
  const url = createApiUrl(`${geoCountryResourceUrl}/${$countryId}`, {
    filters,
    includes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

export default {
  normalize,
  fetchCountry
};
