import { isNil } from "lodash";

export const convertCoordinateArrayToCoordinateObject = coordinate =>
  isNil(coordinate)
    ? null
    : {
        lat: coordinate[0],
        lng: coordinate[1]
      };

export const mapCoordinateArraysToCoordinateObjects = coordinates =>
  isNil(coordinates)
    ? []
    : coordinates.map(coordinate =>
        convertCoordinateArrayToCoordinateObject(coordinate)
      );

export const createGmapCircle = (id, center, radius, isExcluded = false) => ({
  id,
  type: "CIRCLE",
  center: convertCoordinateArrayToCoordinateObject(center),
  isExcluded,
  radius
});

export const createGmapPolygon = (polygon, isExcluded = false) => ({
  id: polygon.id,
  type: "POLYGON",
  isExcluded,
  coordinates: mapCoordinateArraysToCoordinateObjects(polygon.coordinates)
});
