<template>
  <v-card :class="cardClass" :style="cardStyle">
    <v-container fluid>
      <v-layout style="min-height: 500px">
        <filter-type-company
          v-if="type === null || isCompanyFilter"
          @reset="resetType"
          @set="setType"
        />
        <filter-type-buldit
          v-if="type === null || isBulditFilter"
          @reset="resetType"
          @set="setType"
        />
      </v-layout>
      <v-alert
        v-for="(text, index) in warnText"
        :key="index"
        :value="true"
        type="warning"
      >
        {{ text }}
      </v-alert>
    </v-container>
  </v-card>
</template>

<script type="text/babel">
import { map, includes, sum } from "lodash";
import FilterStepperMixin from "./FilterStepperMixin.vue";
import FilterTypeCompany from "./FilterTypeCompany";
import FilterTypeBuldit from "./FilterTypeBuldit";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("filter");

export default {
  name: "filter-type",
  components: {
    FilterTypeBuldit,
    FilterTypeCompany
  },
  mixins: [FilterStepperMixin],
  computed: {
    ...mapGetters([
      "type",
      "isCompanyFilter",
      "isBulditFilter",
      "company",
      "hasSubFilters",
      "subFilters"
    ])
  },
  methods: {
    validate() {
      this.warnText = [];
      if (this.isCompanyFilter) {
        return this.validateTypeCompany();
      } else {
        return this.validateTypeBuldit();
      }
    },
    validateTypeCompany() {
      if (this.company === null) {
        this.warnText.push("Needs a company.");
        return false;
      }
      return true;
    },
    validateTypeBuldit() {
      let isValid = true;
      if (!this.hasSubFilters) {
        this.warnText.push("Needs at least one filter to distribute too.");
        isValid = false;
      }
      const percentages = map(this.subFilters, "percentage");
      if (includes(percentages, null)) {
        this.warnText.push("Distribution percentages can't be blank.");
        isValid = false;
      } else if (sum(percentages) > 100) {
        this.warnText.push("Distribution percentage total can't exceed 100.");
        isValid = false;
      }
      return isValid;
    },
    resetType() {
      this.$store.commit("filter/resetType");
    },
    setType({ type }) {
      this.$store.commit("filter/setType", type);
    }
  }
};
</script>
