<template>
  <div>
    <div v-if="editing">
      <invoice-item-edit :item="item" :index="index" @confirm="itemEdited" />
    </div>
    <div v-if="!editing" class="grid-container items pb-2 pt-2">
      <div class="description-grid">
        <p class="ma-0">{{ item.description }}</p>
        <section>
          <div v-if="item.hasOffers || item.isCredit">
            <v-btn
              v-if="!hasOffersOpen"
              class="item-btn"
              small
              icon
              @click="triggerOffers()"
              ><v-icon class="text-end" small>fas fa-caret-down</v-icon></v-btn
            >
            <v-btn
              v-if="hasOffersOpen"
              class="item-btn"
              small
              icon
              @click="triggerOffers()"
              ><v-icon class="text-end" small>fas fa-caret-up</v-icon></v-btn
            >
          </div>
          <v-btn
            v-if="!item.hasOffers && !item.isCredit && state === 'DRAFT'"
            class="item-btn"
            icon
            small
            :disabled="isUpdatingItem"
            :loading="isUpdatingItem"
            @click="editItem()"
            ><v-icon class="text-end" small>edit</v-icon></v-btn
          ><v-btn
            v-if="!item.hasOffers && !item.isCredit && state === 'DRAFT'"
            icon
            class="item-btn"
            small
            :disabled="isUpdatingItem"
            :loading="isUpdatingItem"
            @click="deleteItem()"
            ><v-icon class="text-end" small>delete</v-icon></v-btn
          >
        </section>
      </div>
      <p class="text-end ma-0">{{ item.quantity }}</p>
      <p class="text-end ma-0">
        {{ formatPrice(item.unitPrice) }}
      </p>
      <p class="text-end ma-0">
        {{ formatPrice(item.unitPrice * item.quantity) }}
      </p>
    </div>
    <v-progress-linear
      v-if="isError || isLoading"
      slot="progress"
      class="ma-0"
      :color="isError ? 'error' : 'accent'"
      indeterminate
      :height="3"
    />
    <invoice-item-offers
      v-if="hasOffersOpen"
      :invoice-item-id="item.id"
      :highlighted-offer="highlightedOffer"
    />
  </div>
</template>

<script type="text/babel">
import { isNil } from "lodash";
import itemOffersTemplateModule from "../../store/modules/invoice/item-offers-template";
import { getHash, getValuesFromURLSearchParams } from "@/api/url";
const {
  state: stateModule,
  getters,
  actions,
  mutations
} = itemOffersTemplateModule;
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import InvoiceItemOffers from "./InvoiceItemOffers";
import { SET_FILTER } from "@/store/templates/table/mutation-types";
import {
  IS_ERROR as GETTER_IS_ERROR,
  IS_LOADING as GETTER_IS_LOADING,
  PAGINATION as GETTER_PAGINATION
} from "../../store/templates/table/getter-types";
import { SET_PAGINATION as DISPATCH_SET_PAGINATION } from "../../store/templates/table/action-types";
import InvoiceItemEdit from "./InvoiceItemEdit";

export default {
  name: "invoice-item",
  components: { InvoiceItemEdit, InvoiceItemOffers },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    hasOffersOpen: false,
    highlightedOffer: null,
    editing: false
  }),
  computed: {
    isStoreRegistered() {
      return !isNil(this.$store.state["invoice"][`itemOffers${this.item.id}`]);
    },
    hasOffers() {
      return this.item.offers?.length > 0;
    },
    isError() {
      return this.isStoreRegistered
        ? this.$store.getters[
            `invoice/itemOffers${this.item.id}/${GETTER_IS_ERROR}`
          ]
        : false;
    },
    isLoading() {
      return this.isStoreRegistered
        ? this.$store.getters[
            `invoice/itemOffers${this.item.id}/${GETTER_IS_LOADING}`
          ]
        : false;
    },
    isUpdatingItem() {
      return this.$store.getters["invoice/isUpdatingItem"];
    },
    state() {
      return this.$store.getters["invoice/state"];
    }
  },
  created() {
    this.applyHash();
  },
  methods: {
    registerModule() {
      this.$store.registerModule(["invoice", `itemOffers${this.item.id}`], {
        state: stateModule,
        getters,
        actions,
        mutations,
        namespaced: true
      });
    },
    setItemFilter() {
      this.$store.commit(`invoice/itemOffers${this.item.id}/${SET_FILTER}`, {
        prop: "invoiceItem",
        value: this.item.id
      });
    },
    setCreditNoteFilter() {
      this.$store.commit(`invoice/itemOffers${this.item.id}/${SET_FILTER}`, {
        prop: "creditedInvoice",
        value: this.$store.state.invoice.model.id
      });
    },
    async fetch() {
      await this.$store.dispatch(
        `invoice/itemOffers${this.item.id}/${DISPATCH_FETCH}`
      );
    },
    triggerOffers() {
      this.hasOffersOpen = !this.hasOffersOpen;
      if (this.hasOffersOpen && !this.isStoreRegistered) {
        this.registerModule();
        // The only way an invoice item can be negative is when it's an item for the credited offers
        if (this.item.isCredit) {
          this.setCreditNoteFilter();
        } else {
          this.setItemFilter();
        }
        this.fetch();
      }
    },
    async applyHash() {
      // only do this when the invoice item has offers or is a credited item
      if (this.item.hasOffers || this.item.isCredit) {
        const hash = getHash();
        const hashParams = getValuesFromURLSearchParams(
          new URLSearchParams(hash)
        );
        if (!isNil(hashParams.offer)) {
          this.registerModule();
          // The only way an invoice item can be negative is when it's an item for the credited offers
          if (this.item.isCredit) {
            this.setCreditNoteFilter();
          } else {
            this.setItemFilter();
          }
          await this.fetch();
          const ids = this.$store.getters[
            `invoice/itemOffers${this.item.id}/ids`
          ];
          // if the offer specified in the url is in the retrieved offers we set continue to set the pagination on the correct page and highlight the offer
          if (ids.filter(id => id === hashParams.offer).length > 0) {
            const pagination = this.$store.getters[
              `invoice/itemOffers${this.item.id}/${GETTER_PAGINATION}`
            ];
            const offerIndex = ids.indexOf(hashParams.offer);
            const offerPage = Math.ceil(
              (offerIndex + 1) / pagination.rowsPerPage
            );
            this.$store.dispatch(
              `invoice/itemOffers${this.item.id}/${DISPATCH_SET_PAGINATION}`,
              {
                pagination: {
                  descending: pagination.descending,
                  lastPage: pagination.lastPage,
                  page: offerPage,
                  rowsPerPage: pagination.rowsPerPage,
                  sortBy: pagination.sortBy,
                  totalItems: pagination.totalItems
                }
              }
            );
            this.highlightedOffer = hashParams.offer;
            this.hasOffersOpen = true;
          }
        }
      }
    },
    async deleteItem() {
      this.$store.commit("invoice/setUpdatingItem", true);
      const isDeleted = await this.$store.dispatch("invoice/deleteItem", {
        invoiceId: this.$store.state.invoice.model.id,
        itemId: this.item.id
      });
      this.$store.commit("invoice/setUpdatingItem", false);
      if (isDeleted) {
        this.$router.push({ name: "invoices" });
      }
    },
    editItem() {
      this.editing = true;
    },
    itemEdited() {
      this.editing = false;
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    }
  }
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 25px;
  align-items: center !important;
}
.description-grid {
  display: grid;
  grid-template-columns: 86% 14%;
  grid-gap: 25px;
  align-items: center !important;
}
.items {
  font-size: 16px;
}
.item-end {
  justify-self: end;
}
.text-end {
  text-align: end;
}
.item-btn {
  background-color: #e8e8e8;
}
</style>
