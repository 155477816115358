import tableGetters from "@/store/templates/table/getters";

export default {
  ...tableGetters,
  submission: state => offerId => {
    return state.submission[state.lead[state.entity[offerId].lead].submission];
  },
  address: (state, getters) => offerId => {
    return state.address[getters.submission(offerId).address];
  }
};
