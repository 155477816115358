<template>
  <div class="subject-form-container">
    <v-card>
      <crud-header
        :title="name"
        type="Subject"
        store="subject"
        @save-success="
          $router.push({
            name: 'company.subjects',
            params: { companyId: company.id }
          })
        "
      />
      <company-subject />
    </v-card>
  </div>
</template>

<script type="text/babel">
import subjectModule from "@/store/modules/company/subject";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "@/store/helpers";
import { createNamespacedHelpers as createVuexNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "../../helpers";
import CompanySubject from "@/components/company/subjects/CompanySubject";
import ValidatableMixin from "@/components/mixins/validation/ValidatableMixin";

const { mapComputed } = createNamespacedHelpers("subject");

const { mapGetters: mapCompanyGetters } = createVuexNamespacedHelpers(
  "company"
);

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "subject",
  subjectModule
);

export default {
  name: "company-delivery-method-view",
  mixins: [ValidatableMixin],
  components: { CompanySubject, CrudHeader },
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapComputed(["companyId", "name", "type", "recipients"]),
    ...mapCompanyGetters(["company"])
  },
  created() {
    this.companyId = this.company.id;
  },
  ...createViewLifecycleMethods
};
</script>
<style scoped>
.subject-form-container {
  width: 1200px;
  margin: 0 auto;
  padding: 24px 0;
}
</style>
