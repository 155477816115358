<template>
  <div class="category-table-container">
    <table-overview
      title="Categories"
      store="company/categories"
      :headers="[
        { text: 'Category', sortable: false, value: 'category' },
        {
          text: 'Delete',
          sortable: false,
          value: 'delete',
          align: 'center',
          width: '100px'
        }
      ]"
      :items="categories"
    >
      <v-progress-linear slot="progress" indeterminate :height="3" />
      <company-category-table-row
        slot="table-row"
        slot-scope="{ item }"
        :category-id="item"
        @delete="openDeleteDialog"
      />
    </table-overview>
    <company-category-add @get-categories="getCategories()" />
    <company-category-delete-dialog
      @success="getCategories"
      v-if="deleteDialog"
      :dialog.sync="deleteDialog"
      :category-id="deleteCategoryId"
    />
  </div>
</template>
<script type="text/babel">
import CompanyCategoryTableRow from "@/components/company/categories/CompanyCategoryTableRow";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("company/categories");
import TableOverview from "@/components/generic/overview/TableOverview";
import CompanyCategoryAdd from "@/components/company/categories/CompanyCategoryAdd";
import CompanyCategoryDeleteDialog from "@/components/company/categories/CompanyCategoryDeleteDialog";

export default {
  name: "company-categories-view",
  components: {
    CompanyCategoryDeleteDialog,
    CompanyCategoryAdd,
    TableOverview,
    CompanyCategoryTableRow
  },
  data() {
    return {
      categories: [],
      deleteDialog: false,
      deleteCategoryId: null
    };
  },
  computed: {
    ...mapGetters(["category"])
  },
  created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      this.categories = await this.$store.dispatch(
        `company/categories/${DISPATCH_FETCH}`
      );
    },
    async openDeleteDialog(id) {
      this.deleteCategoryId = id;
      this.deleteDialog = true;
    }
  }
};
</script>
<style scoped>
.category-table-container {
  max-width: 800px; /*looks weird either way*/
  margin: 24px auto;
}
</style>
