<template>
  <v-card color="white">
    <crud-header
      :title="paused ? pausedString : name"
      :tabs="tabs"
      :color="paused ? 'warning' : 'primary'"
      type="Company"
      store="company"
      @save-success="
        ({ id }) =>
          $router.push({ name: 'company.show', params: { companyId: id } })
      "
    >
      <v-btn
        slot="toolbarActions"
        @click="generateMagicLink(primaryCustomer.id)"
        icon
        v-if="isCustomer && !magiclink"
      >
        <v-icon>far fa-user</v-icon>
      </v-btn>
      <a
        slot="toolbarActions"
        class="v-btn v-btn--icon theme--light"
        v-if="magiclink"
        :href="magiclink"
      >
        <v-icon>far fa-magic</v-icon>
      </a>
    </crud-header>
  </v-card>
</template>

<script type="text/babel">
import companyModule from "@/store/modules/company";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import moment from "moment";

const { mapGetters } = createNamespacedHelpers("company");
import { mapDetailViewLifecycleMethods } from "../helpers";
import api from "@/api";

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "company",
  companyModule
);

export default {
  name: "company-view",
  components: {
    CrudHeader
  },
  data: () => ({
    magiclink: undefined
  }),
  computed: {
    ...mapGetters([
      "name",
      "id",
      "company",
      "isCustomer",
      "isPaused",
      "pausedFrom",
      "pausedTill",
      "primaryCustomer",
      "paused"
    ]),
    pausedString() {
      if (new Date(this.pausedTill) > new Date()) {
        return `${this.name} (Paused until ${moment(this.pausedTill).format(
          "DD MMM YYYY"
        )})`;
      } else {
        return `${this.name} (Paused indefinitely)`;
      }
    },
    tabs() {
      return [
        {
          name: "Details",
          to: { name: "company.show", params: { companyId: this.id } }
        },
        {
          name: "Filters",
          to: { name: "company.filters", params: { companyId: this.id } }
        },
        {
          name: "Filters logs",
          to: { name: "company.filter-logs", params: { companyId: this.id } }
        },
        {
          name: "Categories",
          to: { name: "company.categories", params: { companyId: this.id } }
        },
        {
          name: "Subjects",
          to: { name: "company.subjects", params: { companyId: this.id } }
        },
        {
          name: "Delivery Methods",
          to: {
            name: "company.delivery-methods",
            params: { companyId: this.id }
          }
        },
        {
          name: "Leads",
          to: { name: "company.leads", params: { companyId: this.id } }
        },
        {
          name: "Statistics",
          to: { name: "company.statistics", params: { companyId: this.id } }
        },
        {
          name: "Settings",
          to: { name: "company.settings", params: { companyId: this.id } }
        },
        {
          name: "Invoices",
          to: { name: "company.invoices", params: { companyId: this.id } }
        }
      ];
    }
  },
  methods: {
    async generateMagicLink(customerId) {
      this.magiclink = await api.customer.magicLink(customerId);
    }
  },
  ...detailViewLifecycleMethods
};
</script>
