<template>
  <v-card ma-0>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Billing data
      </v-toolbar-title>
      <company-edit-fiscal-data />
    </v-toolbar>

    <h4 v-if="fiscalName != null" class="company-name">
      {{ fiscalName }}
    </h4>

    <div class="list-container">
      <v-icon class="list-icon">
        location_on
      </v-icon>

      <div class="list-content">
        <v-list-tile-title v-if="billingStreet != null">
          {{ billingStreet }} {{ billingStreetNumber }}
          {{ billingBox }}
        </v-list-tile-title>
        <v-list-tile-title
          v-if="billingPostalCode != null || billingCity != null"
        >
          {{ billingPostalCode }} {{ billingCity }}
        </v-list-tile-title>
        <v-list-tile-title v-if="billingCountry != null">
          {{ billingCountryName }}
        </v-list-tile-title>
      </div>

      <v-icon class="list-icon">
        phone
      </v-icon>

      <div class="list-content">
        <v-list-tile-title>
          {{ fiscalTelephone }}
        </v-list-tile-title>
      </div>

      <v-icon class="list-icon">
        email
      </v-icon>

      <div class="list-content">
        <v-list-tile-title>
          {{ fiscalEmail }}
        </v-list-tile-title>
      </div>

      <v-icon class="list-icon">
        far fa-wallet
      </v-icon>

      <div class="list-content">
        <v-list-tile-title>
          {{ billingVatNumber }}
        </v-list-tile-title>
      </div>
    </div>

    <div class="ml-4 mb-4">
      Payment term: <b>{{ paymentTerm }}</b> days
    </div>
    <div class="ml-4 mb-4">
      Reject term: <b>{{ rejectableTerm ? rejectableTerm : 10 }}</b> days
    </div>
    <div class="ml-4 mb-4">
      Invoice remark: <b>{{ invoiceRemark }}</b>
    </div>
    <div class="ml-4 mb-4">
      <b>{{
        isAutoBilled
          ? "Company is automatically billed"
          : "Company requires manual billing"
      }}</b>
    </div>

    <!--TODO add rekeningnummer-->
    <!--<template v-if="company.url != null">-->
    <!--<v-icon class="list-icon">-->
    <!--link-->
    <!--</v-icon>-->

    <!--<div class="list-content">-->
    <!--<v-list-tile-title>-->
    <!--<a>{{ company.url }}</a>-->
    <!--</v-list-tile-title>-->
    <!--</div>-->
    <!--</template>-->

    <template v-if="billingVatNumber != null">
      <v-layout column ma-0 px-4 pb-4>
        <a
          :href="
            `https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=${billingVatNumber}`
          "
          target="_blank"
        >
          KBO Data
        </a>
        <a
          :href="
            `http://www.ejustice.just.fgov.be/cgi_tsv/tsv_rech.pl?language=nl&btw=${billingVatNumber}&liste=Liste`
          "
          target="_blank"
        >
          Publicaties in staatsblad
        </a>
        <a
          :href="
            `https://www.checkinhoudingsplicht.be/result/${billingVatNumber}`
          "
          target="_blank"
        >
          Social debts
        </a>
        <a
          :href="
            `https://www.checkinhoudingsplicht.be/result/${billingVatNumber}`
          "
          target="_blank"
        >
          Tax debts
        </a>
      </v-layout>
    </template>
  </v-card>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CompanyEditFiscalData from "@/components/company/edit/CompanyEditFiscalData";
const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-details-fiscal-data",
  components: { CompanyEditFiscalData },
  computed: {
    ...mapGetters([
      "company",
      "billingCountry",
      "billingCountryName",
      "billingStreet",
      "billingStreetNumber",
      "billingPostalCode",
      "billingBox",
      "billingCity",
      "billingVatNumber",
      "fiscalName",
      "fiscalEmail",
      "fiscalUrl",
      "fiscalTelephone",
      "paymentTerm",
      "rejectableTerm",
      "isAutoBilled",
      "invoiceRemark"
    ])
  }
};
</script>
<style scoped>
.list-container {
  margin: 20px;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  font-size: 16px;
  grid-gap: 20px;
}
.list-icon {
  justify-self: center;
}
.company-name {
  margin: 1em 0 0 20px;
}
</style>
