<template>
  <div class="items-container">
    <div v-if="editing">
      <invoice-create-item-edit :item="item" :index="i" @confirm="itemEdited" />
    </div>
    <div v-else class="grid-container items pb-2 pt-2">
      <div class="description-grid">
        <p class="ma-0">{{ item.description }}</p>
        <section class="display-row">
          <div v-if="hasOffers">
            <v-btn
              v-if="!hasOffersOpen"
              class="item-btn"
              small
              icon
              @click="triggerOffers()"
              ><v-icon class="text-end" small>fas fa-caret-down</v-icon></v-btn
            >
            <v-btn
              v-if="hasOffersOpen"
              class="item-btn"
              small
              icon
              @click="triggerOffers()"
              ><v-icon class="text-end" small>fas fa-caret-up</v-icon></v-btn
            >
          </div>
          <v-btn
            v-if="!hasOffers"
            class="item-btn"
            icon
            small
            @click="editItem()"
            ><v-icon class="text-end" small>edit</v-icon></v-btn
          ><v-btn icon class="item-btn" small @click="deleteItem()"
            ><v-icon class="text-end" small>delete</v-icon></v-btn
          >
        </section>
      </div>
      <p class="text-end ma-0">{{ item.quantity }}</p>
      <p class="text-end ma-0">{{ formatPrice(item.unitPrice) }}</p>
      <p class="text-end ma-0">{{ formatPrice(item.totalPrice) }}</p>
    </div>
    <v-divider />
    <invoice-create-item-offers-info v-if="hasOffersOpen" :item="item" />
  </div>
</template>

<script type="text/babel">
import InvoiceCreateItemEdit from "./InvoiceCreateItemEdit";
import { isNil } from "lodash";
import InvoiceCreateItemOffersInfo from "./InvoiceCreateItemOffersInfo";

export default {
  name: "invoice-create-item-info",
  components: {
    InvoiceCreateItemOffersInfo,
    InvoiceCreateItemEdit
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  inject: ["$validator"],
  data: () => ({
    editing: false,
    hasOffersOpen: false
  }),
  computed: {
    hasOffers() {
      return this.item.offers?.length > 0;
    }
  },
  methods: {
    deleteItem() {
      this.$store.commit("invoice/deleteItem", this.index);
    },
    editItem() {
      this.editing = true;
    },
    itemEdited() {
      this.editing = false;
    },
    triggerOffers() {
      this.hasOffersOpen = !this.hasOffersOpen;
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    }
  }
};
</script>
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.bottom-border {
  border-bottom: 1px solid lightgray;
}
.option-grid {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-gap: 10px;
}
.header {
  font-size: 18px;
}
.description-grid {
  display: grid;
  grid-template-columns: 86% 14%;
  grid-gap: 25px;
  align-items: center !important;
}
.items {
  font-size: 16px;
}
.item-end {
  justify-self: end;
}
.action-required {
  border-bottom: 1px solid lightgray;
  text-align: center;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }
}
.text-end {
  text-align: end;
}
.add-button {
  left: 50%;
}
.item-btn {
  background-color: #e8e8e8;
}
.display-row {
  display: flex;
}
</style>
