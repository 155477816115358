<template>
  <div class="grid-container">
    <v-text-field
      v-model="description"
      :disabled="item.editingDisabled"
      v-validate="'required|max:255'"
      label="Description"
      autocomplete="not-my-description"
      :counter="255"
      :error-messages="errors.collect('description')"
      data-vv-name="description"
      required
    />
    <v-text-field
      v-model="quantity"
      :disabled="item.editingDisabled"
      type="number"
      class="input-right"
      v-validate="'required|integer|min_value:1'"
      autocomplete="not-my-quantity"
      :error-messages="errors.collect('quantity')"
      data-vv-name="quantity"
      required
    />
    <v-text-field
      v-model="unitPrice"
      class="input-right"
      :disabled="item.editingDisabled"
      v-validate="'required|min_value:0.01'"
      autocomplete="not-my-unit-price"
      :error-messages="errors.collect('unitPrice')"
      data-vv-name="unitPrice"
      name="unitPrice"
      prefix="€"
      label="Price"
    />
    <div class="btn-grid">
      <v-btn color="grey" @click="remove"
        ><v-icon>far fa-trash-alt</v-icon></v-btn
      >
      <v-btn color="primary" @click="confirm">CONFIRM</v-btn>
    </div>
  </div>
</template>

<script type="text/babel">
import { cloneDeep } from "lodash";
export default {
  name: "invoice-create-item-edit",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    tempItem: null
  }),
  watch: {
    item: {
      handler(newVal) {
        this.tempItem = cloneDeep(newVal);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    unitPrice: {
      get() {
        return this.tempItem.unitPrice;
      },
      set(val) {
        this.tempItem.unitPrice = val;
        this.tempItem.totalPrice = val * this.tempItem.quantity;
      }
    },
    description: {
      get() {
        return this.tempItem.description;
      },
      set(val) {
        this.tempItem.description = val;
      }
    },
    quantity: {
      get() {
        return this.tempItem.quantity;
      },
      set(val) {
        this.tempItem.quantity = val;
        this.tempItem.totalPrice = val * this.tempItem.unitPrice;
      }
    }
  },
  methods: {
    async confirm() {
      const isValid = await this.$validator.validate();
      if (!isValid) {
        return;
      }
      this.$store.commit("invoice/setItem", {
        index: this.index,
        item: this.tempItem
      });
      this.$emit("confirm");
    },
    remove() {
      this.$emit("confirm");
    }
  }
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  border-bottom: 1px solid lightgray;
  align-items: center;
}
.btn-grid {
  display: grid;
  grid-template-columns: 8fr 2fr;
  align-items: center;
}
.input-right >>> input {
  text-align: right;
}
</style>
