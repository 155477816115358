<template>
  <v-card ma-0>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Make customer
      </v-toolbar-title>
    </v-toolbar>
    <div class="log-container">
      <v-btn block depressed color="primary lighten-2" @click="dialog = true">
        Make customer
      </v-btn>
    </div>
    <company-make-customer-dialog lazy v-model="dialog" />
  </v-card>
</template>

<script type="text/babel">
import ValidatableMixin from "../../mixins/ValidatableMixin";
import CompanyMakeCustomerDialog from "./CompanyMakeCustomerDialog";

export default {
  name: "company-make-customer",
  components: { CompanyMakeCustomerDialog },
  mixins: [ValidatableMixin],
  data: () => ({
    dialog: false
  })
};
</script>
<style scoped>
.log-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
}
</style>
