<template>
  <v-container>
    <div class="vacation-mode-container">
      <v-layout justify-spaced-around class="settings-header">
        <h1>General</h1>
        <v-spacer />
        <v-btn color="primary" @click="saveGeneralSettings">SAVE</v-btn>
      </v-layout>
      <div class="settings">
        <v-checkbox v-model="isReseller" label="Is Reseller" />
      </div>
    </div>
  </v-container>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "../../../store/helpers";
const { mapComputed } = createNamespacedHelpers("company");

export default {
  name: "company-general-settings",
  computed: {
    ...mapComputed(["isReseller"])
  },
  methods: {
    async saveGeneralSettings() {
      await this.$store.dispatch("company/saveGeneralSettings", {
        isReseller: this.isReseller
      });
    }
  }
};
</script>
<style scoped>
.vacation-mode-container {
  padding: 10px 100px 10px 0px;
}
.settings-header {
  border-bottom: 0.5px solid gray;
}
.settings {
  padding: 20px;
}
</style>
