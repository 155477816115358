var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-table-container"},[_c('table-overview',{attrs:{"title":"Categories","store":"company/categories","headers":[
      { text: 'Category', sortable: false, value: 'category' },
      {
        text: 'Delete',
        sortable: false,
        value: 'delete',
        align: 'center',
        width: '100px'
      }
    ],"items":_vm.categories},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('company-category-table-row',{attrs:{"category-id":item},on:{"delete":_vm.openDeleteDialog}})}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":"","height":3},slot:"progress"})],1),_c('company-category-add',{on:{"get-categories":function($event){return _vm.getCategories()}}}),(_vm.deleteDialog)?_c('company-category-delete-dialog',{attrs:{"dialog":_vm.deleteDialog,"category-id":_vm.deleteCategoryId},on:{"success":_vm.getCategories,"update:dialog":function($event){_vm.deleteDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }