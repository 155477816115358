<template>
  <span class="edit-button">
    <v-btn
      class="edit-button"
      depressed
      small
      color="primary lighten-2"
      @click.stop="dialog = true"
    >
      Edit
    </v-btn>
    <persistent-when-changed-dialog
      v-model="dialog"
      title="Facturatiegegevens"
      :changed="isChanged"
      :loading="isSaving"
      width="900px"
      @cancel="revert"
      @confirm="validate(save)"
    >
      <v-text-field
        v-model="fiscalName"
        v-validate="'max:255'"
        autocomplete="not-my-fiscal-name"
        :error-messages="errors.collect('fiscalName')"
        data-vv-name="fiscalName"
        name="fiscalName"
        label="Fiscale naam"
      />

      <div class="list-container">
        <div class="list-item-2">
          <v-icon class="list-icon">
            location_on
          </v-icon>

          <div class="list-content">
            <div class="address">
              <v-text-field
                v-model="billingStreet"
                v-validate="'max:255'"
                autocomplete="not-my-fiscal-street"
                :error-messages="errors.collect('fiscalStreet')"
                data-vv-name="fiscal-street"
                name="fiscalStreet"
                label="Street"
              />
              <v-text-field
                v-model="billingStreetNumber"
                v-validate="'max:255'"
                autocomplete="not-my-fiscal-street-number"
                :error-messages="errors.collect('fiscalStreetNumber')"
                data-vv-name="fiscal-street-number"
                name="fiscalStreetNumber"
                label="Number"
              />
              <v-text-field
                v-model="billingBox"
                v-validate="'max:255'"
                autocomplete="not-my-fiscal-box"
                :error-messages="errors.collect('fiscalBox')"
                data-vv-name="fiscal-box"
                name="fiscalBox"
                label="Box"
              />
            </div>
            <div class="city">
              <v-text-field
                v-model="billingPostalCode"
                v-validate="'max:255'"
                autocomplete="not-my-fiscal-postal-code"
                :error-messages="errors.collect('fiscalPostalCode')"
                data-vv-name="fiscal-postal-code"
                name="fiscalPostalCode"
                label="Postal"
              />
              <v-text-field
                v-model="billingCity"
                v-validate="'max:255'"
                autocomplete="not-my-fiscal-city-name"
                :error-messages="errors.collect('fiscalCityName')"
                data-vv-name="fiscal-city-name"
                name="fiscalCityName"
                label="City"
              />
            </div>
            <v-autocomplete-country
              v-model="billingCountry"
              v-validate="'max:255'"
              autocomplete="not-my-fiscal-country"
              :error-messages="errors.collect('fiscalCountry')"
              data-vv-name="fiscal-country"
              name="fiscalCountry"
              label="Country"
              required
            />
          </div>
        </div>
      </div>
      <v-layout row>
        <v-flex xs6 style="padding-right: 10px">
          <v-text-field
            prepend-icon="phone"
            v-model="fiscalTelephone"
            v-validate="'max:255'"
            label="Fiscal Telephone"
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            prepend-icon="email"
            v-model="fiscalEmail"
            v-validate="'max:255'"
            label="Fiscal Email"
          />
        </v-flex>
      </v-layout>
      <vat-input
        :vat-number="billingVatNumber"
        @change="val => (billingVatNumber = val)"
      />
      <v-flex xs6>
        <v-text-field
          v-model="paymentTerm"
          v-validate="'numeric|max_value:90'"
          autocomplete="not-my-payment-term"
          :error-messages="errors.collect('paymentTerm')"
          data-vv-name="paymentTerm"
          name="paymentTerm"
          label="Payment term"
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="rejectableTerm"
          v-validate="'numeric|max_value:90'"
          autocomplete="not-my-rejectable-term"
          :error-messages="errors.collect('rejectableTerm')"
          data-vv-name="rejectableTerm"
          name="rejectableTerm"
          label="Reject term for offers"
        />
      </v-flex>
      <v-text-field
        v-model="invoiceRemark"
        v-validate="'max:255'"
        autocomplete="not-my-invoice-remark"
        :error-messages="errors.collect('invoiceRemark')"
        data-vv-name="invoiceRemark"
        name="invoiceRemark"
        label="Invoice remark"
      />
      <v-flex xs6>
        <v-switch
          v-model="isAutoBilled"
          label="is company automatically billed"
          color="primary"
          default="true"
        />
      </v-flex>
    </persistent-when-changed-dialog>
  </span>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import CanValidate from "@/components/mixins/validation/CanValidate";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import PersistentWhenChangedDialog from "@/components/generic/dialog/PersistentWhenChangedDialog";
const { mapComputed } = createNamespacedHelpers("company");
import { IS_CHANGED } from "@/store/templates/crud/getter-types";
import { REVERT } from "@/store/templates/crud/mutation-types";
import { SAVE } from "@/store/templates/crud/action-types";
import VatInput from "../../VatInput";
import VAutocompleteCountry from "../../country/VAutocompleteCountry";

export default {
  name: "company-edit-fiscal-data",
  components: { VAutocompleteCountry, VatInput, PersistentWhenChangedDialog },
  mixins: [CanValidate, IsValidationRoot],
  data: () => ({
    dialog: false,
    isSaving: false,
    sameAddress: true
  }),
  computed: {
    ...mapComputed([
      "billingVatNumber",
      "billingName",
      "billingTelephoneNumber",
      "billingEmailAddress",
      "billingStreet",
      "billingStreetNumber",
      "billingBox",
      "billingCity",
      "billingPostalCode",
      "billingCountry",
      "fiscalName",
      "fiscalTelephone",
      "fiscalEmail",
      "paymentTerm",
      "rejectableTerm",
      "isAutoBilled",
      "invoiceRemark"
    ]),
    isChanged() {
      return this.$store.getters[`company/${IS_CHANGED}`];
    },
    addressId() {
      return this.$store.getters["company/addressId"];
    }
  },
  watch: {
    sameAddress: function(newVal) {
      if (newVal) {
        this.fiscalAddressId = this.addressId;
      } else {
        if (
          this.addressId ===
          this.$store.state.company.initialModel.fiscalAddress.id
        ) {
          this.fiscalAddressId = null;
        } else {
          this.fiscalAddressId = this.$store.state.company.initialModel.fiscalAddress.id;
        }
      }
    }
  },
  created() {
    this.sameAddress = this.addressId === this.fiscalAddressId;
  },
  methods: {
    revert() {
      this.$store.commit(`company/${REVERT}`);
    },
    async save() {
      this.$store.dispatch(`company/${SAVE}`);
    }
  }
};
</script>
<style scoped>
.list-container {
  --base-size: 24px;
  display: grid;
  grid-template-columns: 1fr;
  font-size: 16px;
  grid-gap: var(--base-size);
}
.list-item-2 {
  display: grid;
  grid-template-columns: var(--base-size) 1fr;
  grid-gap: inherit;
}
.list-item-3 {
  display: grid;
  grid-template-columns: var(--base-size) 1fr 40px;
  grid-gap: inherit;
}
.list-icon {
  place-self: center;
}
.address {
  display: grid;
  grid-template-columns: 5fr minmax(50px, 1fr) minmax(50px, 1fr);
  grid-gap: calc(var(--base-size) / 2);
}
.city {
  display: grid;
  grid-template-columns: minmax(50px, 1fr) 5fr;
  grid-gap: calc(var(--base-size) / 2);
}
.urls {
  display: grid;
  grid-template-columns: 5fr minmax(100px, 1.5fr) 90px;
  grid-gap: calc(var(--base-size) / 2);
}
.edit-button {
  margin: 0 0 0 auto;
}
</style>
