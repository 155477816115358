<template>
  <v-container>
    <div class="vacation-mode-container">
      <v-layout justify-spaced-around class="settings-header">
        <h1>Vacation mode</h1>
        <v-spacer />
        <v-btn color="primary" @click="validate(savePausedSettings)"
          >SAVE</v-btn
        >
      </v-layout>
      <div class="settings">
        <v-switch
          v-model="isPaused"
          color="primary"
          label="Vacation mode indefinitely"
        ></v-switch>
        <between-date-input v-model="betweenDates" both-required />
      </div>
    </div>
  </v-container>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import BetweenDateInput from "@/components/generic/input/dates/BetweenDateInput";
import CanValidate from "@/components/mixins/validation/CanValidate";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import { createNamespacedHelpers } from "../../../store/helpers";
const { mapComputed } = createNamespacedHelpers("company");

export default {
  name: "company-vacation-mode-setting",
  components: { BetweenDateInput },
  mixins: [CanValidate, IsValidationRoot],
  computed: {
    betweenDates: {
      get() {
        return { from: this.pausedFrom, to: this.pausedTill };
      },
      set(dates) {
        if (!isNil(dates.from) || !isNil(dates.to)) {
          this.isPaused = false;
        }
        this.pausedFrom = dates.from;
        this.pausedTill = dates.to;
      }
    },
    isPaused: {
      get() {
        return this.$store.getters["company/isPaused"];
      },
      set(value) {
        if (value) {
          this.pausedFrom = null;
          this.pausedTill = null;
        }
        this.$store.commit("company/setIsPaused", value);
      }
    },
    ...mapComputed(["pausedFrom", "pausedTill"])
  },
  methods: {
    async savePausedSettings() {
      await this.$store.dispatch("company/savePausedSettings", {
        isPaused: this.isPaused,
        pausedFrom: this.pausedFrom,
        pausedTill: this.pausedTill
      });
    }
  }
};
</script>
<style scoped>
.vacation-mode-container {
  padding: 10px 100px 10px 0px;
}
.settings-header {
  border-bottom: 0.5px solid gray;
}
.settings {
  padding: 20px;
}
</style>
