<template>
  <v-card :class="cardClass" :style="cardStyle">
    <v-container fluid>
      <v-expansion-panel v-model="selected" inset class="py-4">
        <filter-specifier
          v-for="(filterCategory, index) in filterCategories"
          :key="filterCategory.category"
          :filter-category="filterCategory"
          :selected="selected === index"
          :always-visible-question-ids="alwaysVisibleQuestionIds"
        />
      </v-expansion-panel>
    </v-container>
  </v-card>
</template>

<script type="text/babel">
import FilterSpecifier from "./FilterSpecifier";
import FilterStepperMixin from "./FilterStepperMixin.vue";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("filter");

export default {
  name: "filter-specifiers",
  components: {
    FilterSpecifier
  },
  mixins: [FilterStepperMixin],
  data() {
    return {
      selected: null,
      alwaysVisibleQuestionIds: [],
      alwaysVisibleQuestions: []
    };
  },
  created() {
    if (!this.areAlwaysVisibleQuestionsLoaded) {
      this.$store.dispatch("filter/fetchAlwaysVisibleQuestions");
    }
  },
  computed: {
    ...mapGetters(["areAlwaysVisibleQuestionsLoaded", "filterCategories"])
  }
};
</script>
<style scoped></style>
