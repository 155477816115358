<template>
  <div class="mt-3">
    <invoice-credit-note
      class="mb-3"
      v-for="(creditNote, i) in creditNotes"
      :key="i"
      :credit-note="creditNote"
      :company-id="companyId"
    />
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
import InvoiceCreditNote from "./InvoiceCreditNote";
const { mapGetters } = VuexNamespacedHelpers("invoice");

export default {
  name: "invoice-credit-notes",
  components: { InvoiceCreditNote },
  inject: ["$validator"],
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["creditNotes"])
  },
  methods: {}
};
</script>
