<template>
  <div>
    <v-card color="white">
      <v-toolbar dark color="primary" dense>
        <v-icon class="white--text">fas fa-file-invoice</v-icon>
        <v-toolbar-title
          ><span class="primary--text text--lighten-2"> CREATE </span>NEW DRAFT
          INVOICE</v-toolbar-title
        >
      </v-toolbar>
      <invoice-create @savable="savable" :company-id="companyId" />
    </v-card>
    <v-fab-transition>
      <v-btn
        v-show="canSave"
        :loading="loading"
        color="success"
        dark
        fixed
        bottom
        right
        fab
        @click="createDraft"
      >
        <v-icon class="display-center">fas fa-check</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script type="text/babel">
import invoiceModule from "@/store/modules/invoice";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "@/views/helpers";
import InvoiceCreate from "../../components/invoice/InvoiceCreate";
import { SAVE } from "../../store/templates/crud/action-types";
import { isNil } from "lodash";

const { mapGetters } = createNamespacedHelpers("invoice");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "invoice",
  invoiceModule
);

export default {
  name: "invoice-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  components: {
    InvoiceCreate
  },
  data: () => ({
    canSave: false,
    loading: false
  }),
  computed: {
    ...mapGetters([]),
    isCompanyView() {
      return !isNil(this.companyId);
    }
  },
  methods: {
    savable(val) {
      this.canSave = val;
    },
    async createDraft() {
      try {
        this.loading = true;
        const id = await this.$store.dispatch(
          `invoice/${SAVE}`,
          this.invoiceId
        );
        this.$router.push({
          name: this.isCompanyView ? "company.invoices.show" : "invoices.show",
          params: { invoiceId: id, companyId: this.companyId }
        });
      } catch (e) {
        this.loading = false;
        const message =
          e.exception?.response?.data?.message ??
          e.exception?.response?.data?.error?.detail;
        this.$store.dispatch("snackbar/addItem", {
          text: message,
          color: "error"
        });
      }
    }
  },
  ...createViewLifecycleMethods
};
</script>
<style scoped>
.display-center {
  display: flex;
}
</style>
