<template>
  <v-dialog v-if="showDialog" v-model="internalDialog" max-width="500">
    <v-card>
      <div class="pa-3">
        <div class="pa-2 warning">
          <p class="ma-0">
            You are about to reject offer <strong>#{{ offerId }}</strong
            >.
            <br />
            This action will cause the offer to be removed from the invoice.
            <br />
            Please make sure everything is in order.
          </p>
        </div>
        <v-autocomplete-reject-reason v-model="reason" required />
        <v-textarea
          v-model="comment"
          v-validate="'required|max:255'"
          data-vv-name="comment"
          :error-messages="errors.collect('comment')"
          :counter="255"
          outline
          label="Comment"
          auto-grow
        />
        <v-layout row justify-space-around>
          <v-btn
            color="white"
            depressed
            :loading="loading"
            @click="internalDialog = false"
            >CANCEL</v-btn
          >
          <v-btn
            v-if="!isInItem"
            color="primary"
            depressed
            :loading="loading"
            @click="validate(reject)"
            >REJECT</v-btn
          ><v-btn
            v-else
            color="primary"
            depressed
            :loading="loading"
            @click="validate(rejectAndRemove)"
            >REJECT</v-btn
          >
        </v-layout>
      </div>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import VAutocompleteRejectReason from "../reject-reason/VAutocompleteRejectReason";
import CanValidate from "@/components/mixins/validation/CanValidate";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";

export default {
  name: "invoice-create-item-offer-reject-dialog",
  components: { VAutocompleteRejectReason },
  mixins: [CanValidate, IsValidationRoot],
  props: {
    offerId: {
      type: Number,
      required: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    isInItem: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    reason: null,
    comment: null,
    loading: false
  }),
  computed: {
    internalDialog: {
      get() {
        return this.showDialog;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  methods: {
    async reject() {
      this.loading = true;
      await this.$store.dispatch(`invoice/rejectOffer`, {
        offerId: this.offerId,
        reason: this.reason,
        comment: this.comment
      });
      this.loading = false;
      this.$emit("close");
    },
    async rejectAndRemove() {
      this.loading = true;
      await this.$store.dispatch(`invoice/rejectAndRemoveOfferFromItem`, {
        offerId: this.offerId,
        reason: this.reason,
        comment: this.comment
      });
      this.loading = false;
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.toolbar-title {
  font-size: 16px !important;
}
.warning {
  background-color: var(--v-warning-base);
  border: solid #bc5a01 2px !important;
  color: #bc5a01;
  text-align: center;
}
</style>
