<template>
  <div class="wrapper">
    <v-chip
      v-for="(parent, i) in parentFilters"
      label
      small
      :key="i"
      color="accent"
    >
      <router-link
        class="mr-1 text--cursor-pointer link"
        :to="{ name: 'filters.show', params: { filterId: parent.filter } }"
      >
        <v-icon small class="fa-icon">far fa-filter</v-icon>
        {{ filter(parent.filter).name }}</router-link
      >
    </v-chip>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("filter");

export default {
  name: "filter-parent",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["parentFilters"])
  },
  methods: {
    filter(id) {
      return this.$store.state.filter.filter[id];
    }
  }
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  color: white;
  display: flex;
  background-color: var(--v-info-base);
  padding: 10px 20px 10px 20px;
}
.link {
  text-decoration: none;
}
</style>
