<template>
  <v-layout row>
    <v-flex xs1>
      <v-combobox
        v-model="vatPrefix"
        :items="prefixes"
        label="Prefix"
        v-validate="'required'"
        :error-messages="errors.collect('prefix')"
        data-vv-name="prefix"
      />
    </v-flex>
    <v-flex xs11>
      <v-text-field
        v-model="number"
        v-validate="'required|max:50|validVAT'"
        data-vv-as="number"
        label="Vat number"
        :error-messages="errors.collect('number')"
        data-vv-name="number"
        required
      />
    </v-flex>
    <v-flex xs1>
      <v-switch v-model="shouldVerify" color="primary" label="Verify vat" />
    </v-flex>
  </v-layout>
</template>

<script type="text/babel">
import { Validator } from "vee-validate";
import ValidatableMixin from "@/components/mixins/ValidatableMixin";
import store from "@/store";
import { isNil } from "lodash";

let vatPrefix = "BE";
let shouldVerify = false;

const isValidVAT = value =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async resolve => {
    const valid = await store.dispatch(`company/isValidVat`, {
      vatNumber: vatPrefix + value
    });
    const company = await store.dispatch(`company/isUniqueVat`, {
      vatNumber: vatPrefix + value
    });

    if (company.result.length > 0) {
      const currentCompany = company.result.filter(company => {
        return company === store.state.company.model.id;
      })[0];
      if (isNil(currentCompany)) {
        return resolve({
          valid: false,
          data: {
            message: `VAT number is already in the DB.`
          }
        });
      }
    }

    if (shouldVerify) {
      const verified = await store.dispatch(`company/isVerifiedVat`, {
        vatNumber: vatPrefix + value
      });
      if (!verified.valid) {
        return resolve({
          valid: false,
          data: {
            message: `The given VAT number is valid but is not currently in use.`
          }
        });
      }
    }

    if (valid.valid) {
      return resolve({
        valid: true
      });
    }

    return resolve({
      valid: false,
      data: {
        message: `The given VAT number is not a valid one.`
      }
    });
  });

Validator.extend("validVAT", {
  validate: isValidVAT,
  getMessage: (fields, params, data) => data.message
});

export default {
  name: "vat-input",
  mixins: [ValidatableMixin],
  inject: ["$validator"],
  props: {
    vatNumber: {
      type: String,
      default: "BE"
    }
  },
  data() {
    return {
      shouldVerify: shouldVerify,
      prefixes: ["BE", "FR", "NL", "EN", "DE"],
      vatPrefix: isNil(this.vatNumber)
        ? ""
        : this.vatNumber.substring(0, 2).match(/[a-zA-Z]/)
        ? this.vatNumber.substring(0, 2)
        : "",
      number: isNil(this.vatNumber)
        ? ""
        : this.vatNumber.substring(0, 2).match(/[a-zA-Z]/)
        ? this.vatNumber.substring(2)
        : this.vatNumber
    };
  },
  watch: {
    vatPrefix: {
      handler(newVal) {
        vatPrefix = newVal;
        this.$emit("change", newVal + this.number);
      },
      immediate: true
    },
    number(newVal) {
      this.$emit("change", this.vatPrefix + newVal);
    },
    shouldVerify(newVal) {
      shouldVerify = newVal;
    }
  },
  methods: {}
};
</script>
