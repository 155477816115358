<template>
  <v-card ma-0>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Facebook feed
      </v-toolbar-title>
    </v-toolbar>
    <div class="facebook-feed-container">
      <!--
      The facebook plugin causes console errors, 
      -->
      <iframe
        v-for="(url, index) in links"
        :key="index"
        :src="facebookLink(url)"
        class="facebook-feed"
        height="700"
        style="border:none;overflow:hidden;"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allow="encrypted-media"
      />
    </div>
  </v-card>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-details-facebook-feed",
  data: () => ({
    links: []
  }),
  computed: {
    ...mapGetters(["company", "facebookLinks"])
  },
  methods: {
    createLinkArray() {
      this.facebookLinks.forEach(url => {
        const splitLink = url.url.split("/").filter(element => {
          return element !== "";
        });
        this.links.push(
          splitLink[
            splitLink.findIndex(element => {
              return element.includes("facebook.com");
            }) + 1
          ]
        );
      });
    },
    facebookLink(linkName) {
      return (
        "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F" +
        linkName +
        "&tabs=timeline&width=500&height=700&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
      );
    }
  },
  created() {
    console.log(
      "Following errors might be caused by the facebook plugin. Don't worry"
    );
    this.createLinkArray();
  }
};
</script>
<style lang="scss">
.facebook-feed {
  max-width: 100%;
  width: 500px;
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }
}
</style>
