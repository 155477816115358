import geoApiClient from "@/api/geo";
import {
  convertCoordinateArrayToCoordinateObject,
  mapCoordinateArraysToCoordinateObjects
} from "@/store/geography";

export default {
  async fetchCountry({ commit }, countryId) {
    commit("setIsLoading", true);
    const { entities, result } = await geoApiClient.fetchCountry(countryId);

    Object.values(entities.country).forEach(country => {
      country.center = convertCoordinateArrayToCoordinateObject(country.center);
      country.coordinates = mapCoordinateArraysToCoordinateObjects(
        country.bounds
      );
      delete country.bounds;
    });

    Object.values(entities.province).forEach(province => {
      province.center = convertCoordinateArrayToCoordinateObject(
        province.center
      );
      province.coordinates = mapCoordinateArraysToCoordinateObjects(
        province.bounds
      );
      delete province.bounds;
    });

    Object.values(entities.city).forEach(city => {
      city.coordinates = mapCoordinateArraysToCoordinateObjects(city.bounds);
      delete city.bounds;
    });

    commit("setEntities", { entities });
    commit("setIsLoading", true);

    return result;
  }
};
