import tableGetters from "../../templates/table/getters";
import { isNil } from "lodash";

export default {
  ...tableGetters,
  country: state => companyId =>
    !isNil(state.entity[companyId].country)
      ? state.country[state.entity[companyId].country]
      : {},
  language: state => companyId =>
    !isNil(state.entity[companyId].language)
      ? state.language[state.entity[companyId].language]
      : {},
  categories: state => companyId =>
    !isNil(state.entity[companyId].categories)
      ? state.entity[companyId].categories.map(
          categoryId => state.category[categoryId]
        )
      : [[]],
  customers: state => companyId =>
    state.entity[companyId].isCustomer
      ? state.entity[companyId].customers.map(costumerId =>
          state.customer ? state.customer[costumerId] : costumerId
        )
      : null,
  primaryCustomer: (state, getters) => companyId =>
    state.entity[companyId].isCustomer
      ? getters.customers(companyId).filter(function(customer) {
          return customer.isPrimary;
        })[0]
      : null
};
