var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel-content',{attrs:{"lazy":""}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.category.name.nl_be)+" ")]),_c('v-card',[(_vm.isLoading)?_c('v-card-text',{staticClass:"text-xs-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('v-card-text',_vm._l((_vm.shownQuestions),function(question,i){return _c('filter-specifier-question',{key:question.id,attrs:{"question":question,"specifier":_vm.getSpecifierForQuestion(question),"odd":i % 2 === 1},on:{"specifier-add":function($event){return _vm.$store.commit('filter/addSpecifier', {
            category: _vm.category,
            question: question
          })},"specifier-remove":function($event){return _vm.$store.commit('filter/removeSpecifier', {
            category: _vm.category,
            question: question
          })},"specifier-update":function($event){return _vm.$store.commit('filter/updateSpecifier', {
            category: _vm.category,
            question: question,
            specifier: arguments[0]
          })}}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }