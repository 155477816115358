<template>
  <v-list dense>
    <v-subheader>{{ heading }}</v-subheader>
    <v-list-tile
      v-for="(shape, index) in shapes"
      :key="`${keyPrefix}-${index}`"
      :dark="isSelected(shape)"
      :class="{
        accent: isSelected(shape)
      }"
      @click="selectShape(shape)"
    >
      <v-list-tile-content>
        <v-list-tile-title v-if="shape.hasOwnProperty('cityId')">{{
          `${cityName(shape)} ${
            shape.hasOwnProperty("radius") ? formatRadius(shape.radius) : ""
          }`
        }}</v-list-tile-title>
        <v-list-tile-title v-else>{{
          `${titlePrefix}&nbsp;${index + 1} ${
            shape.hasOwnProperty("radius") ? formatRadius(shape.radius) : ""
          }`
        }}</v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action v-if="isSelected(shape)">
        <v-btn icon ripple @click.native="removeShape(shape)">
          <v-icon color="grey lighten-1">
            delete
          </v-icon>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>

<script type="text/babel">
import { isNil } from "lodash";
export default {
  name: "company-filter-geographic-shape-list",
  props: {
    shapes: Array,
    selectedShape: Object,
    heading: {
      type: String,
      default: "Shapes"
    },
    keyPrefix: {
      type: String,
      default: "shape"
    },
    titlePrefix: {
      type: String,
      default: "Shape"
    }
  },
  methods: {
    selectShape(shape) {
      this.$emit("select-shape", { shape: shape });
    },
    removeShape(shape) {
      this.$emit("remove-shape", { shape: shape });
    },
    isSelected(shape) {
      return this.selectedShape === shape;
    },
    formatRadius(radius) {
      const radiusInKm = (radius / 1000).toFixed(1);
      return `${radiusInKm} km`;
    },
    cityName(shape) {
      if (!isNil(shape.city)) {
        return shape.city.name;
      }
      return this.$store.getters["geo/cityName"](shape.cityId);
    }
  }
};
</script>
