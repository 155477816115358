<script>
export default {
  name: "filter-stepper-mixin",
  props: {
    cardClass: {
      type: Array,
      required: false
    },
    cardStyle: {
      type: Object,
      required: false
    },
    valid: {
      type: Boolean,
      required: true
    },
    warn: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    warnText: []
  })
};
</script>
