<template>
  <v-form>
    <v-text-field v-model="description" label="Search description" />
    <v-autocomplete-subject v-model="subjects" multiple />
    <v-autocomplete-country v-model="countries" multiple />
    <v-autocomplete-language v-model="languages" multiple />
    <between-date-input title="Offer Distributed At" v-model="date" />
    <v-checkbox
      v-model="isRejectedIgnored"
      label="Ignore rejected"
      color="primary"
      hide-details
    />
    <v-checkbox
      v-model="onlyRejected"
      label="Only rejected"
      color="primary"
      hide-details
    />
    <v-checkbox
      v-model="hasRejectionRequested"
      label="Only with rejection requested offers"
      color="primary"
      hide-details
    />
  </v-form>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteCountry from "../../country/VAutocompleteCountry";
import VAutocompleteLanguage from "../../language/VAutocompleteLanguage";
import VAutocompleteSubject from "../../subject/VAutocompleteSubject";
import BetweenDateInput from "../../generic/input/dates/BetweenDateInput";

const { mapComputedFilters } = createNamespacedHelpers("company/offers");

export default {
  name: "company-offers-view-filters",
  components: {
    BetweenDateInput,
    //BetweenDateInput,
    VAutocompleteSubject,
    VAutocompleteCountry,
    VAutocompleteLanguage
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapComputedFilters([
      "subjects",
      "countries",
      "languages",
      "date",
      "isRejectedIgnored",
      "onlyRejected",
      "description",
      "hasRejectionRequested"
    ])
  }
};
</script>
