<template>
  <v-card>
    <v-dialog v-model="internalDialog" max-width="500">
      <v-card>
        <div class="pt-3 px-5">
          <p>
            Are you sure you want to delete the draft invoice?
          </p>
          <v-layout row justify-space-around>
            <v-btn
              color="white"
              depressed
              :loading="loading"
              @click="internalDialog = false"
              >CANCEL</v-btn
            >
            <v-btn color="primary" depressed :loading="loading" @click="remove"
              >DELETE</v-btn
            >
          </v-layout>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";

const { mapGetters: mapGetters } = createNamespacedHelpers("invoice");

export default {
  name: "delete-invoice-dialog",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters(["id", "company"]),
    internalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    },
    isCompanyView() {
      return !isNil(this.companyId);
    }
  },
  methods: {
    async remove() {
      this.loading = true;
      await this.$store.dispatch("invoice/destroy", this.id);
      this.loading = false;
      this.$router.push({
        name: this.isCompanyView ? "company.invoices" : "invoices"
      });
    }
  }
};
</script>
<style scoped>
p {
  text-align: center;
  font-size: 16px;
}
.blacklisted {
  background-color: var(--v-warning-base);
  border: solid #bc5a01 2px !important;
  color: #bc5a01;
}
</style>
