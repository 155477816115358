<template>
  <v-dialog v-model="dialog" width="500px">
    <v-card>
      <template v-if="state === undefined">
        <v-layout justify-space-between>
          <v-btn
            color="primary"
            dark
            class="ma-5"
            @click.stop="state = 'EXISTING'"
          >
            EXISTING CUSTOMER
          </v-btn>
          <v-btn color="primary" dark class="ma-5" @click.stop="state = 'NEW'">
            NEW CUSTOMER
          </v-btn>
        </v-layout>
      </template>
      <template v-else-if="state === 'EXISTING'">
        <v-card-text>
          <v-autocomplete-customer v-model="existingCustomerId" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" dark @click.stop="submit">
            Submit
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text>
          <v-text-field
            v-model="newCustomerEmail"
            v-validate="'required|email|max:255'"
            label="E-mail"
            name="email"
            autocomplete="not-my-email"
            :counter="255"
            :error-messages="errors.collect('email')"
            data-vv-name="email"
            data-vv-scope="NEW"
            required
          />
          <v-text-field
            v-model="newCustomerFirstName"
            v-validate="'required|email|max:255'"
            label="first name"
            name="firstName"
            autocomplete="not-my-first-name"
            :counter="255"
            :error-messages="errors.collect('firstName')"
            data-vv-name="firstName"
            data-vv-scope="NEW"
            required
          />
          <v-text-field
            v-model="newCustomerLastName"
            v-validate="'max:255'"
            label="last name"
            name="lastName"
            autocomplete="not-my-last-name"
            :counter="255"
            :error-messages="errors.collect('lastName')"
            data-vv-name="lastName"
            data-vv-scope="NEW"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" dark @click.stop="validate(submit)">
            Submit
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script type="text/babel">
import VAutocompleteCustomer from "../../customer/VAutocompleteCustomer";
import { createNamespacedHelpers } from "vuex";
import CanValidate from "@/components/mixins/validation/CanValidate";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
const { mapGetters } = createNamespacedHelpers("company");
export default {
  name: "company-make-customer-dialog",
  components: { VAutocompleteCustomer },
  mixins: [CanValidate, IsValidationRoot],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      state: undefined,
      newCustomerEmail: null,
      newCustomerLastName: null,
      newCustomerFirstName: null,
      existingCustomerId: null,
      existingLoading: false
    };
  },
  computed: {
    ...mapGetters(["id", "emailAddresses"]),
    dialog: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("input", newValue);
        this.state = undefined;
      }
    }
  },
  created() {
    this.initEmail();
  },
  methods: {
    async submit() {
      await this.$store.dispatch("company/makeCustomer", {
        companyId: this.id,
        customerId: this.existingCustomerId,
        email: this.newCustomerEmail,
        firstName: this.newCustomerFirstName,
        lastName: this.newCustomerLastName
      });
      await this.$store.dispatch(`company/${DISPATCH_FETCH}`, { id: this.id });
      this.dialog = false;
    },
    initEmail() {
      this.newCustomerEmail = this.emailAddresses[0];
    }
  }
};
</script>
