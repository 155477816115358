<template>
  <div class="pa-3">
    <div class="blue lighten-5 pa-3">
      <v-autocomplete-company
        v-model="company"
        :states="['AFFILIATED', 'PAUSED']"
        return-object
        :clearable="!companyId"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script type="text/babel">
import VAutocompleteCompany from "../company/VAutocompleteCompany";
import { isNil } from "lodash";
import { REVERT } from "../../store/templates/crud/mutation-types";

export default {
  name: "invoice-create-header",
  components: { VAutocompleteCompany },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    company: {
      get() {
        return !isNil(this.companyId)
          ? this.companyId
          : this.$store.getters["invoice/modelCompany"];
      },
      set(newVal) {
        if (isNil(newVal)) {
          this.$store.commit(`invoice/${REVERT}`);
          this.$store.commit("invoice/setCompany", null);
          this.$store.commit("invoice/setCompanyVatRate", null);
          this.$store.commit("invoice/setCompanyRejectableTerm", null);
          this.$store.commit("invoice/setDistributedUntill", null);
          this.$store.commit("invoice/setInvoiceableOffers", []);
        } else {
          this.$store.commit("invoice/setCompany", newVal.id);
          this.$store.commit("invoice/setCompanyVatRate", newVal.vatRate);
          this.$store.commit(
            "invoice/setCompanyRejectableTerm",
            newVal.rejectableTerm
          );
          this.$store.commit("invoice/setDistributedUntill", null);
          this.$store.commit("invoice/setInvoiceableOffers", []);
        }
      }
    },
    disabled() {
      return !isNil(this.companyId);
    }
  },
  watch: {
    companyId: {
      handler(newVal) {
        if (newVal) {
          this.$store.commit("invoice/setCompany", newVal);
          const companyVatRate = this.$store.getters["company/vatRate"];
          this.$store.commit("invoice/setVat", companyVatRate);
        }
      },
      immediate: true
    }
  },
  methods: {}
};
</script>
