<template>
  <v-container fluid>
    <v-autocomplete-subject v-model="subject" />
    <v-currency v-model="price" label="Default Price" />
    <v-layout v-if="countries.length > 0" row>
      <v-flex xs2 v-for="(country, index) in countries" :key="index">
        <v-currency v-model="country.price" :label="`${country.iso} price`" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import Vue from "vue";
import CanValidate from "@/components/mixins/validation/CanValidate";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import VCurrency from "@/components/generic/input/VCurrency";
const { mapComputed: mapDeliveryMethodComputed } = createNamespacedHelpers(
  "subject"
);
import api from "@/api";
import { cloneDeep } from "lodash";

export default {
  name: "company-subject",
  components: { VCurrency, VAutocompleteSubject },
  inject: ["$validator"],
  mixins: [CanValidate],
  data: () => ({
    contactPersonIds: [],
    types: [
      { text: "Mail", value: "MAIL" },
      { text: "CSV mail", value: "CSV_MAIL" },
      { text: "Api", value: "API", disabled: true }
    ],
    countries: [],
    test: null
  }),
  beforeMount() {
    this.getCountries();
  },
  watch: {
    countries: {
      handler(newVal) {
        this.$store.commit("company/subject/setCountries", cloneDeep(newVal));
      },
      deep: true
    }
  },
  computed: {
    ...mapDeliveryMethodComputed(["subject", "price"])
  },
  methods: {
    async getCountries() {
      const data = await api.country.fetchAll();
      this.countries = data.result.map(
        countryId => data.entities.entity[countryId]
      );
      this.countries.forEach((country, index) => {
        Vue.set(this.countries[index], "price", null);
      });
    }
  }
};
</script>
