<template>
  <span>
    <v-btn
      class="log-add-button"
      depressed
      small
      color="primary lighten-2"
      @click.stop="dialog = true"
    >
      Edit
    </v-btn>
    <persistent-when-changed-dialog
      v-model="dialog"
      title="Contactpersonen"
      :changed="isChanged"
      :loading="isLoading"
      width="800px"
      @cancel="revert"
      @confirm="validate(save)"
    >
      <v-container>
        <div class="list-item-3">
          <div class="contact-persons-container">
            <span
              class="contact-person"
              v-for="(contactPerson, index) in editableContactPersons"
              :key="index"
            >
              <v-text-field
                class="contact-person-first-name"
                v-model="contactPerson.firstName"
                v-validate="'max:255'"
                autocomplete="not-my-first-name"
                :error-messages="errors.collect(`firstName${index}`)"
                :data-vv-name="`firstName${index}`"
                name="firstName"
                label="first name"
              />
              <v-text-field
                class="contact-person-last-name"
                v-model="contactPerson.lastName"
                v-validate="'max:255'"
                autocomplete="not-my-last-name"
                :error-messages="errors.collect(`lastName${index}`)"
                :data-vv-name="`lastName${index}`"
                name="lastName"
                label="last name"
              />
              <v-text-field
                class="contact-person-position"
                v-model="contactPerson.position"
                v-validate="'max:255'"
                autocomplete="not-my-position"
                :error-messages="errors.collect(`position${index}`)"
                :data-vv-name="`position${index}`"
                name="position"
                label="Position"
              />
              <span
                class="contact-person-delete"
                :title="notDeletableString(contactPerson)"
              >
                <v-btn
                  @click="deleteContactPerson(index)"
                  class="list-icon"
                  icon
                  small
                  :disabled="!!notDeletableString(contactPerson)"
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </span>
              <v-text-field
                class="contact-person-email"
                v-model="contactPerson.email"
                v-validate="'max:255'"
                autocomplete="not-my-email"
                :error-messages="errors.collect(`email${index}`)"
                :data-vv-name="`email${index}`"
                name="email"
                label="Email"
              />
              <v-text-field
                class="contact-person-phone-number"
                v-model="contactPerson.phoneNumber"
                v-validate="'max:255'"
                autocomplete="not-my-phoneNumber"
                :error-messages="errors.collect('`phoneNumber${index}`')"
                :data-vv-name="`phoneNumber${index}`"
                name="phoneNumber"
                label="Phone number"
              />
            </span>
          </div>
        </div>
        <v-btn
          slot="absolute-button"
          @click="addContactPerson"
          class="add-button"
          fab
          dark
          small
          color="primary"
        >
          <v-icon>
            far fa-fw fa-plus
          </v-icon>
        </v-btn>
      </v-container>
    </persistent-when-changed-dialog>
  </span>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isChanged } from "@/store/change";
import ValidatableMixin from "../../mixins/ValidatableMixin";
const { mapGetters } = createNamespacedHelpers("company");
import { cloneDeep } from "lodash";
import PersistentWhenChangedDialog from "@/components/generic/dialog/PersistentWhenChangedDialog";

export default {
  name: "company-edit-contact-persons",
  components: { PersistentWhenChangedDialog },
  mixins: [ValidatableMixin],
  data: () => ({
    isLoading: false,
    dialog: false,
    editableContactPersons: [],
    initData: [],
    contactPerson: {
      email: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      position: null
    }
  }),
  computed: {
    ...mapGetters(["id", "contactPersons"]),
    isChanged() {
      return isChanged(this.editableContactPersons, this.initData);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.editableContactPersons = cloneDeep(this.contactPersons);
      this.initData = cloneDeep(this.contactPersons);
    },
    addContactPerson() {
      this.editableContactPersons.push(cloneDeep(this.contactPerson));
    },
    revert() {
      this.editableContactPersons = cloneDeep(this.initData);
    },
    notDeletableString(contactPerson) {
      if (contactPerson.filterCount) {
        return "Has filters";
      }
      if (contactPerson.hasDeliveryMethods) {
        return "Has delivery methods";
      }
      return "";
    },
    async deleteContactPerson(index) {
      this.editableContactPersons.splice(index, 1);
    },
    async save() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(
          "company/updateContactPersons",
          this.editableContactPersons
        );
      } finally {
        this.isLoading = false;
        this.dialog = false;
        this.init();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.list-item-3 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.contact-person {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto 40px;
  border-bottom: 2px solid #dddddd;
  grid-auto-flow: row;
  padding: 20px 0;
  &:last-child {
    border-bottom: 0 solid #efefef;
    padding-bottom: 0;
  }
  &:first-child {
    padding-top: 0;
  }
  &-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    color: #1a1a1a;
  }
  &-delete {
    grid-row: 1 / 4;
    grid-column: 4;
    display: grid;
  }
  &-first-name {
    grid-row: 1;
    grid-column: 1;
    display: grid;
  }
  &-last-name {
    grid-row: 1;
    grid-column: 2;
    display: grid;
  }
  &-position {
    grid-row: 3;
    grid-column: 1;
    display: grid;
  }
  &-email {
    grid-row: 2;
    grid-column: 1;
    display: grid;
  }
  &-phone-number {
    grid-row: 2;
    grid-column: 2;
    display: grid;
  }
}
.list-icon {
  place-self: center;
}

.log-add-button {
  margin: 0 0 0 auto;
}
</style>
