<template>
  <div class="mt-3">
    <div class="grid-container bottom-border header pb-2">
      <div class="item-start"><b>ITEM</b></div>
      <div class="item-end"><b>QUANTITY</b></div>
      <div class="item-end"><b>PRICE</b></div>
      <div class="item-end"><b>TOTAL</b></div>
    </div>
    <div>
      <div class="items-container">
        <div v-for="(item, i) in items" :key="i">
          <invoice-item :item="item" :index="i" />
          <v-divider />
        </div>
        <invoice-create-item v-if="hasTempItem" />
        <b-date-picker
          v-if="selectingOffers"
          v-model="distributedUntill"
          label="Offers untill"
        />
        <invoice-create-item-offers
          v-if="selectingOffers"
          @items-created="selectingOffers = false"
        />
        <div v-if="selecting" class="option-grid bottom-border">
          <v-btn color="secondary" @click="selectOfferTimeFrame">OFFERS</v-btn>
          <v-btn color="primary" @click="addTempItem">MANUAL</v-btn>
        </div>
        <v-fab-transition>
          <v-btn
            v-show="
              !selecting &&
                !hasTempItem &&
                !selectingOffers &&
                state === 'DRAFT'
            "
            color="pink"
            fab
            dark
            small
            bottom
            class="add-button"
            @click="selecting = true"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-fab-transition>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
import { isNil } from "lodash";
import InvoiceItem from "./InvoiceItem";
import InvoiceCreateItem from "./InvoiceCreateItem";
import InvoiceCreateItemOffers from "./InvoiceCreateItemOffers";
const { mapGetters } = VuexNamespacedHelpers("invoice");
import { createNamespacedHelpers } from "@/store/helpers";
import BDatePicker from "../generic/input/dates/BDatePicker";
const { mapComputed } = createNamespacedHelpers("invoice");

export default {
  name: "invoice-content",
  components: {
    BDatePicker,
    InvoiceCreateItemOffers,
    InvoiceCreateItem,
    InvoiceItem
  },
  inject: ["$validator"],
  data: () => ({
    openedOffers: [],
    selectingOffers: false,
    selecting: false
  }),
  computed: {
    ...mapComputed(["distributedUntill"]),
    ...mapGetters(["invoice", "company", "items", "state"]),
    hasOffersOpen(index) {
      return this.openedOffers.filter(item => item.index === index).length > 0;
    },
    hasTempItem() {
      return !isNil(this.$store.getters["invoice/tempItem"]);
    }
  },
  watch: {
    async distributedUntill(newVal) {
      if (!isNil(newVal)) {
        await this.$store.dispatch("invoice/fetchInvoiceableOffers");
      }
    }
  },
  methods: {
    addTempItem() {
      this.selecting = false;
      this.$store.commit("invoice/addTempItem");
    },
    selectOfferTimeFrame() {
      this.selecting = false;
      this.selectingOffers = true;
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    },
    openOffers(item, index) {
      this.openedOffers.push({ itemId: item.id, index: index });
    }
  }
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.bottom-border {
  border-bottom: 1px solid lightgray;
}
.header {
  font-size: 18px;
}
.bottom-border {
  border-bottom: 1px solid lightgray;
}
.option-grid {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-gap: 10px;
}
.description-grid {
  display: grid;
  grid-template-columns: 9fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.items {
  font-size: 16px;
}
.item-end {
  justify-self: end;
}
.text-end {
  text-align: end;
}
.item-btn {
  background-color: #e8e8e8;
}
.add-button {
  left: 50%;
}
</style>
