<template>
  <v-timeline-item small>
    <v-card
      class="log-item"
      flat
      color="#efefef"
      :title="userName"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <v-icon small class="log-icon">
        {{ typeIcon }}
      </v-icon>
      <span class="log-title">
        {{ log.title }}
      </span>
      <span class="log-date">
        <template v-if="!hover || !canDelete">
          {{ occurredAt }}
        </template>
        <template v-else>
          <v-btn small icon @click="$emit('updateLog')">
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn small icon :loading="loading" @click="deleteLog">
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
      </span>
      <span class="log-description" v-if="log.description">
        {{ log.description }}
      </span>
    </v-card>
  </v-timeline-item>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
export default {
  name: "company-details-log",
  props: {
    log: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    hover: false,
    loading: false
  }),
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    occurredAt() {
      return this.log.occurredAt
        ? new Date(this.log.occurredAt).toLocaleDateString("nl", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
          })
        : "";
    },
    typeIcon() {
      switch (this.log.type) {
        case "text":
          return "far fa-comment";
        case "phone":
          return "fas fa-phone";
        case "email":
          return "far fa-envelope";
        case "FILTER":
          return "far fa-filter";
        default:
          return null;
      }
    },
    userName() {
      const user = this.log.user;
      if (!user) {
        return "System";
      }
      if (user.employee) {
        return `${user.employee.firstName} ${user.employee.lastName}`;
      }
      if (user.customer) {
        return user.customer.name;
      }
      return "Company";
    },
    canDelete() {
      if (!this.log.isDeletable) {
        return false;
      }
      if (this.hasRolesOrPermissions("company_log_delete")) {
        return true;
      }
      return (
        this.log.user && this.log.user.id === this.$store.state.auth.user.id
      );
    }
  },
  methods: {
    async deleteLog() {
      this.loading = true;
      await this.$store.dispatch(`company/deleteLog`, this.log.id);
      this.$emit("getLogs");
    }
  }
};
</script>
<style lang="scss" scoped>
.log {
  &-item {
    display: grid;
    padding: 10px;
    margin-left: -15px;
    grid-template-columns: 24px minmax(0, 1fr) 80px;
    grid-gap: 8px;
    overflow-wrap: break-word;
    align-items: start;
    color: #1a1a1a;
    animation: slide-in 0.4s ease;
  }
  &-title {
    font-weight: 600;
  }
  &-date {
    justify-self: end;
    grid-column: 3;
    display: flex;
    button {
      margin: 0;
      height: 24px;
      width: 24px;
      & + button {
        margin: 0 0 0 8px;
      }
    }
  }
  &-icon {
    line-height: inherit;
  }
  &-description {
    grid-column: span 3;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
