import crudMutations from "@/store/templates/crud/mutations";
import { REVERT, SET_MODEL } from "../../templates/crud/mutation-types";
import { cloneDeep } from "lodash";
import { setEntities } from "../../templates/entities";
import Vue from "vue";
import moment from "moment";

export default {
  setItem(state, { index, item }) {
    Vue.set(state.model.items, index, item);
  },
  setCompany(state, company) {
    Vue.set(state.model, "company", company);
  },
  addItem(state) {
    state.tempItem.totalPrice =
      state.tempItem.unitPrice * state.tempItem.quantity;
    state.model.items.push(state.tempItem);
  },
  addTempItem(state) {
    state.tempItem = state.defaultItem;
  },
  setTempItem(state, item) {
    state.tempItem = item;
  },
  removeTempItem(state) {
    state.tempItem = null;
  },
  deleteItem(state, index) {
    state.model.items.splice(index, 1);
  },
  setCompanyVatRate(state, vatRate) {
    state.companyVatRate = vatRate;
  },
  setCompanyRejectableTerm(state, rejectableTerm) {
    state.companyRejectableTerm = rejectableTerm;
  },
  removeCreditNote(state, creditNoteId) {
    state.model.creditNotes = state.model.creditNotes.filter(
      creditNote => creditNote.id !== creditNoteId
    );
  },
  setPdfDownloadable(state) {
    state.model.pdfDownloadable = true;
  },
  setCreditNotePdfDownloadable(state, creditNoteId) {
    state.model.creditNotes.map(creditNote => {
      if (creditNote.id === creditNoteId) {
        return (creditNote.pdfDownloadable = true);
      }
      return creditNote;
    });
  },
  setDistributedUntill(state, distributedUntill) {
    Vue.set(state, "distributedUntill", distributedUntill);
  },
  setUpdatingItem(state, isUpdating) {
    state.isUpdatingItem = isUpdating;
  },
  setOfferEntities(state, entities) {
    state.offer = entities.offer || {};
    state.lead = entities.lead || {};
    state.submission = entities.submission || {};
    state.address = entities.address || {};
  },
  setInvoiceableOffers(state, invoiceableOffers) {
    // Collect all offers that are invoiced in other items from the draft invoice
    let invoicedOffers = [];
    state.model.items.forEach(
      item => (invoicedOffers = invoicedOffers.concat(item.offers))
    );
    // Only allow offers to be invoiced if they are not included in other items
    invoiceableOffers = invoiceableOffers.filter(
      offerId => !invoicedOffers.includes(offerId)
    );
    state.invoiceableOffers = invoiceableOffers;
  },
  createItemsFromOffers(state) {
    const creditOffers = state.invoiceableOffers.filter(
      offerId => state.offer[offerId].isCreditingIssued
    );
    const invoiceableOffers = state.invoiceableOffers.filter(
      offerId => !state.offer[offerId].isCreditingIssued
    );
    if (invoiceableOffers.length > 0) {
      let item = {};
      const offers = invoiceableOffers
        .map(offerId => state.offer[offerId])
        .sort((a, b) => moment(a.distributedAt).diff(moment(b.distributedAt)));
      const distributedFrom = moment(offers[0].distributedAt).format(
        "DD-MM-YYYY"
      );
      const distributedTo = moment(state.distributedUntill).format(
        "DD-MM-YYYY"
      );
      item.offers = invoiceableOffers;
      item.unitPrice = offers.reduce((a, { price }) => a + price, 0);
      item.totalPrice = offers.reduce((a, { price }) => a + price, 0);
      item.quantity = 1;
      item.distributedFrom = distributedFrom;
      item.distributedTo = distributedTo;
      item.description = `Leadgeneratie (${distributedFrom} - ${distributedTo}) aantal leads: ${offers.length}`;
      item.editingDisabled = true;
      state.model.items.push(item);
    }
    if (creditOffers.length > 0) {
      let item = {};
      const offers = creditOffers.map(offerId => state.offer[offerId]);
      item.offers = creditOffers;
      item.unitPrice = -offers.reduce((a, { price }) => a + price, 0);
      item.totalPrice = -offers.reduce((a, { price }) => a + price, 0);
      item.quantity = 1;
      item.description = `Verrekening afgekeurde leads`;
      item.editingDisabled = true;
      state.model.items.push(item);
    }
    state.distributedUntill = null;
    state.invoiceableOffers = [];
  },
  removeOfferFromItem(state, offerId) {
    state.model.items = state.model.items.map(item => {
      if (item.offers.includes(offerId)) {
        item.description = `Leadgeneratie (${item.distributedFrom} - ${
          item.distributedTo
        }) aantal leads: ${item.offers.length - 1}`;
      }
      item.offers = item.offers.filter(offer => offer !== offerId);
      return item;
    });
  },
  setIsLoadingOffers(state, value) {
    state.isLoadingOffers = value;
  },
  ...crudMutations,
  [SET_MODEL](state, { entities, id }) {
    state.id = id;
    //this is done so items are reactive
    const items = entities.entity[id].items;
    delete entities.entity[id]["items"];
    Object.assign(state.model, cloneDeep(entities.entity[id]));
    if (state.model.state !== "DRAFT") {
      Vue.set(state, "showWarnings", false);
    }
    Vue.set(state.model, `items`, items);
    Object.assign(state.initialModel, cloneDeep(entities.entity[id]));
    setEntities(state, entities);
  },
  [REVERT](state) {
    state.model = cloneDeep(state.initialModel);
  }
};
