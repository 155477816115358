<template>
  <div class="pa-3">
    <invoice-header />
    <invoice-content />
    <invoice-totals />
    <invoice-warnings v-if="showWarnings" />
  </div>
</template>

<script type="text/babel">
import InvoiceHeader from "./InvoiceHeader";
import InvoiceContent from "./InvoiceContent";
import InvoiceTotals from "./InvoiceTotals";
import InvoiceWarnings from "./InvoiceWarnings";
export default {
  name: "invoice",
  components: { InvoiceWarnings, InvoiceTotals, InvoiceContent, InvoiceHeader },
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    showWarnings() {
      return this.$store.getters["invoice/showWarnings"];
    }
  },
  methods: {}
};
</script>
