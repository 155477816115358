import { flatten, isNil } from "lodash";

export default {
  isLoading: state => state.loading,
  isCountryLoaded: (state, getters) => countryId =>
    !getters.loading && !isNil(state.entities.country[countryId]),
  isProvinceLoaded: (state, getters) => provinceId =>
    !getters.loading && !isNil(state.entities.province[provinceId]),
  isCityLoaded: (state, getters) => cityId =>
    !getters.loading && !isNil(state.entities.city[cityId]),
  country: state => countryId => state.entities.country[countryId],
  countryProvinces: (state, getters) => countryId =>
    getters.isCountryLoaded(countryId)
      ? getters
          .country(countryId)
          .provinces.map(provinceId => state.entities.province[provinceId])
      : [],
  countryCities: (state, getters) => countryId => {
    return !getters.isCountryLoaded(countryId)
      ? []
      : !isNil(getters.country(countryId).cities)
      ? getters
          .country(countryId)
          .cities.map(provinceId => state.entities.province[provinceId])
      : flatten(
          getters
            .country(countryId)
            .provinces.map(provinceId => getters.provinceCities(provinceId))
        );
  },
  province: state => provinceId => state.entities.province[provinceId],
  provinceCities: (state, getters) => provinceId =>
    getters.isProvinceLoaded(provinceId)
      ? getters
          .province(provinceId)
          .cities.map(cityId => state.entities.city[cityId])
      : [],
  city: state => cityId => state.entities.city[cityId],
  cities: state => cityIds =>
    cityIds.map(cityId => state.entities.city[cityId]),
  cityName: state => cityId => state.entities.city[cityId].name
};
