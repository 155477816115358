<template>
  <v-dialog v-model="dialog" width="500" :persistent="isLoading">
    <v-btn slot="activator" icon dark>
      <v-icon class="far fa-file-download" />
    </v-btn>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Download selection as CSV
      </v-card-title>
      <v-card-text>
        <v-checkbox v-model="isSinglePage" label="Export only current page" />
        <v-select
          v-model="separator"
          :items="separatorTypes"
          label="Separator"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn flat :disabled="isLoading" @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          flat
          :loading="isLoading"
          :disabled="isLoading"
          @click="fetchCsv"
        >
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import FileSaver from "file-saver";
import api from "@/api";

export default {
  name: "company-offers-export",
  props: {
    filters: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    isSinglePage: false,
    separator: ",",
    isLoading: false,
    separatorTypes: [
      { text: ",", value: "," },
      { text: ";", value: ";" }
    ]
  }),
  computed: {
    companyId() {
      return this.$store.getters[`company/id`];
    }
  },
  methods: {
    async fetchCsv() {
      let additionalParams = {
        separator: this.separator
      };
      if (this.isSinglePage) {
        additionalParams.singlePage = true;
      }
      const { data, fileName } = await api.company.offer.exportLeads(
        this.companyId,
        {
          filters: this.filters,
          pagination: this.pagination,
          additionalParams
        }
      );

      // Let the user save the file.
      const blob = new Blob([data], { type: "text/csv" });
      FileSaver.saveAs(blob, `${fileName}.csv`);
    }
  }
};
</script>
