<template>
  <v-card color="white">
    <v-card-text>
      <v-layout row>
        <v-flex
          :xs12="reactiveWrapper.isInDatabase === false"
          :xs6="reactiveWrapper.isInDatabase"
        >
          <v-text-field
            v-model="name"
            v-validate="'required|max:255|uniqueName'"
            label="Name"
            :counter="255"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            required
          />
          <vat-input @change="val => (vatNumber = val)" />
          <div class="list-container">
            <div class="list-item-2">
              <v-icon class="list-icon">
                location_on
              </v-icon>

              <div class="list-content">
                <div class="address">
                  <v-text-field
                    v-model="street"
                    v-validate="'max:255'"
                    autocomplete="not-my-street"
                    :error-messages="errors.collect('street')"
                    data-vv-name="street"
                    name="street"
                    label="Street"
                  />
                  <v-text-field
                    v-model="streetNumber"
                    v-validate="'max:255'"
                    autocomplete="not-my-street-number"
                    :error-messages="errors.collect('streetNumber')"
                    data-vv-name="street-number"
                    name="streetNumber"
                    label="Number"
                  />
                  <v-text-field
                    v-model="box"
                    v-validate="'max:255'"
                    autocomplete="not-my-box"
                    :error-messages="errors.collect('box')"
                    data-vv-name="box"
                    name="box"
                    label="Box"
                  />
                </div>
                <div class="city">
                  <v-text-field
                    v-model="postalCode"
                    v-validate="'max:255'"
                    autocomplete="not-my-postal-code"
                    :error-messages="errors.collect('postalCode')"
                    data-vv-name="postalCode"
                    name="postalCode"
                    label="Postal"
                  />
                  <v-text-field
                    v-model="cityName"
                    v-validate="'max:255'"
                    autocomplete="not-my-city-name"
                    :error-messages="errors.collect('cityName')"
                    data-vv-name="cityName"
                    name="cityName"
                    label="City"
                  />
                </div>
                <div>
                  <v-autocomplete-country v-model="country" />
                </div>
                <div>
                  <v-autocomplete-language v-model="language" />
                </div>
              </div>
            </div>
            <div class="list-item-3">
              <v-icon class="list-icon">
                phone
              </v-icon>
              <div class="list-content">
                <v-text-field
                  v-for="(telephoneNumber, index) in telephoneNumbers"
                  :key="index"
                  v-model="telephoneNumbers[index]"
                  v-validate="'max:255'"
                  autocomplete="not-my-telephoneNumber"
                  :error-messages="errors.collect('telephoneNumber')"
                  data-vv-name="telephoneNumber"
                  name="telephoneNumber"
                  label="Telephone Number"
                />
              </div>
              <v-btn
                @click="addNumber"
                class="list-icon"
                fab
                dark
                small
                color="primary"
              >
                <v-icon>
                  far fa-fw fa-plus
                </v-icon>
              </v-btn>
            </div>
            <div class="list-item-3">
              <v-icon class="list-icon">
                email
              </v-icon>
              <div class="list-content">
                <template v-for="(emailAddress, index) in emailAddresses">
                  <v-text-field
                    :key="index"
                    v-model="emailAddresses[index]"
                    v-validate="{ required: index === 0, ...{ email: true } }"
                    :autocomplete="`not-my-emailAddresses.${index}`"
                    :error-messages="errors.collect(`emailAddresses.${index}`)"
                    :data-vv-name="`emailAddresses.${index}`"
                    :name="`emailAddresses.${index}`"
                    label="Email Address"
                  />
                </template>
              </div>
              <v-btn
                @click="addEmailAddress"
                class="list-icon"
                fab
                dark
                small
                color="primary"
              >
                <v-icon>
                  far fa-fw fa-plus
                </v-icon>
              </v-btn>
            </div>
            <div class="list-item-3">
              <v-icon class="list-icon">
                link
              </v-icon>
              <div class="list-content">
                <div class="urls" v-for="(url, index) in urls" :key="index">
                  <v-text-field
                    v-model="url.url"
                    v-validate="'max:255'"
                    maxlength="255"
                    autocomplete="not-my-url"
                    :error-messages="errors.collect('url')"
                    data-vv-name="url"
                    name="url"
                    label="URL"
                  />
                  <v-select
                    v-model="url.type"
                    :items="types"
                    data-vv-name="type"
                    label="Type"
                  />
                  <v-switch
                    v-model="url.public"
                    :label="url.public ? 'Public' : 'Private'"
                    color="primary"
                    default="true"
                  ></v-switch>
                </div>
              </div>
              <v-btn
                @click="addUrl"
                class="list-icon"
                fab
                dark
                small
                color="primary"
              >
                <v-icon>
                  far fa-fw fa-plus
                </v-icon>
              </v-btn>
            </div>
          </div>

          <v-autocomplete-category
            style="padding-left: 10px"
            v-model="categories"
            multiple
          />
        </v-flex>
        <v-flex :xs6="reactiveWrapper.isInDatabase">
          <v-list two-line>
            <v-subheader v-if="similarCompanies.length > 0">
              Did you mean ...
            </v-subheader>
            <v-list-tile
              v-for="(similarCompany, index) in similarCompanies"
              :key="index"
              :to="{
                name: 'company.show',
                params: { companyId: similarCompany.id }
              }"
            >
              <v-list-tile-content>
                <v-list-tile-title
                  ><span
                    ><span>{{ similarCompany.vatNumber }}</span
                    >&nbsp;{{ similarCompany.name }}</span
                  ></v-list-tile-title
                >
                <v-list-tile-sub-title
                  >{{
                    `${similarCompany.street} ${similarCompany.streetNumber}${
                      similarCompany.box ? ` ${similarCompany.box}` : ""
                    } ${similarCompany.postalCode}`
                  }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-flex>
      </v-layout>
      <v-flex>
        <v-toolbar
          color="light-blue"
          class="primary"
          style="margin-bottom: 10px !important;"
          dense
        >
          <v-toolbar-title class="white--text">
            Fiscal address
          </v-toolbar-title>
        </v-toolbar>
        <v-text-field
          v-model="fiscalName"
          v-validate="'max:255'"
          label="Fiscal Name"
          :counter="255"
          required
        />
        <v-layout row>
          <v-flex xs4 style="padding-right: 10px">
            <v-text-field
              prepend-icon="link"
              v-model="fiscalUrl"
              v-validate="'max:255'"
              label="Fiscal Url"
              :counter="255"
            />
          </v-flex>
          <v-flex xs4 style="padding-right: 10px">
            <v-text-field
              prepend-icon="phone"
              v-model="fiscalTelephone"
              v-validate="'max:255'"
              label="Fiscal Telephone"
            />
          </v-flex>
          <v-flex xs4>
            <v-text-field
              prepend-icon="email"
              v-model="fiscalEmail"
              v-validate="'max:255'"
              label="Fiscal Email"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs8 style="padding-right: 10px">
            <v-text-field
              v-model="fiscalStreet"
              v-validate="'max:255'"
              label="Street"
              :counter="255"
            />
          </v-flex>
          <v-flex xs3 style="padding-right: 10px">
            <v-text-field
              v-model="fiscalStreetNumber"
              v-validate="'max:255'"
              label="Nr"
            />
          </v-flex>
          <v-flex xs1>
            <v-text-field
              v-model="fiscalBox"
              v-validate="'max:255'"
              label="Box"
              data-vv-name="box"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs4 style="padding-right: 10px">
            <v-text-field v-model="fiscalPostalCode" label="Postalcode" />
          </v-flex>
          <v-flex xs8>
            <v-text-field
              v-model="fiscalCityName"
              v-validate="'max:255'"
              label="City"
              :counter="255"
            />
          </v-flex>
        </v-layout>
        <div>
          <v-autocomplete-country
            v-model="fiscalCountry"
            v-validate="'required'"
            autocomplete="not-my-fiscal-country"
            :error-messages="errors.collect('fiscalCountry')"
            data-vv-name="fiscalCountry"
            name="fiscalCountry"
            label="Country"
            required
          />
        </div>
        <v-flex xs6>
          <v-text-field
            v-model="paymentTerm"
            v-validate="'max:3'"
            label="Payment term"
          />
        </v-flex>
        <v-flex xs6>
          <v-switch
            v-model="isAutoBilled"
            label="is company automatically billed"
            color="primary"
            default="true"
          />
        </v-flex>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script type="text/babel">
import { cloneDeep, isEmpty } from "lodash";
import { Validator } from "vee-validate";
import ValidatableMixin from "@/components/mixins/ValidatableMixin";
import store from "@/store";
import { FIND_BY_IDS } from "@/store/templates/table/getter-types";
import { createNamespacedHelpers } from "@/store/helpers";
import companiesModule from "@/store/modules/companies";
import VAutocompleteCategory from "@/components/category/VAutocompleteCategory";
import VAutocompleteCountry from "@/components/country/VAutocompleteCountry";
import VAutocompleteLanguage from "@/components/language/VAutocompleteLanguage";
import VatInput from "../VatInput";

const { mapComputed } = createNamespacedHelpers("company");

let reactiveWrapper = {
  isInDatabase: false,
  similarCompanies: []
};

const isUniqueName = value =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async resolve => {
    const data = await store.dispatch(`companies/isUnique`, { name: value });

    reactiveWrapper.similarCompanies = data;

    if (isEmpty(data)) {
      return resolve({
        valid: true
      });
    }

    return resolve({
      valid: false,
      data: {
        message: `Name ${value} is already in the DB.`
      }
    });
  });

Validator.extend("uniqueName", {
  validate: isUniqueName,
  getMessage: (fields, params, data) => data.message
});

export default {
  name: "company-create",
  components: {
    VatInput,
    VAutocompleteLanguage,
    VAutocompleteCountry,
    VAutocompleteCategory
  },
  inject: ["$validator"],
  mixins: [ValidatableMixin],
  props: {
    isReverted: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    reactiveWrapper: reactiveWrapper,
    telephoneNumbers: [""],
    emailAddresses: [""],
    initUrl: { public: true, type: "other" },
    urls: [],
    types: [
      { text: "Website", value: "website" },
      { text: "Facebook", value: "facebook" },
      { text: "Instagram", value: "instagram" },
      { text: "Other", value: "other" }
    ]
  }),
  computed: {
    ...mapComputed([
      "name",
      "vatNumber",
      "street",
      "streetNumber",
      "box",
      "cityName",
      "postalCode",
      "country",
      "language",
      "fiscalName",
      "fiscalUrl",
      "fiscalTelephone",
      "fiscalEmail",
      "fiscalStreet",
      "fiscalStreetNumber",
      "fiscalBox",
      "fiscalCityName",
      "fiscalPostalCode",
      "fiscalCountry",
      "categories",
      "paymentTerm",
      "isAutoBilled"
    ]),
    similarCompanies() {
      return this.$store.getters[`companies/${FIND_BY_IDS}`](
        this.reactiveWrapper.similarCompanies
      );
    }
  },
  watch: {
    telephoneNumbers: {
      handler(newVal) {
        this.$store.commit("company/setTelephoneNumbers", cloneDeep(newVal));
      },
      deep: true
    },
    emailAddresses: {
      handler(newVal) {
        this.$store.commit("company/setEmailAddresses", cloneDeep(newVal));
      },
      deep: true
    },
    urls: {
      handler(newVal) {
        this.$store.commit("company/setUrls", cloneDeep(newVal));
      },
      deep: true
    },
    isReverted() {
      this.emailAddresses = [""];
      this.telephoneNumbers = [""];
      this.urls = [{ public: true, type: "other" }];
    }
  },
  created() {
    if (!store.state["companies"]) {
      store.registerModule("companies", companiesModule);
    }
    this.urls = cloneDeep(this.$store.state.company.model.urls);
  },
  methods: {
    addNumber() {
      this.telephoneNumbers.push("");
    },
    addEmailAddress() {
      this.emailAddresses.push("");
    },
    addUrl() {
      this.urls.push(cloneDeep(this.initUrl));
    }
  }
};
</script>
<style scoped>
.list-container {
  --base-size: 24px;
  display: grid;
  grid-template-columns: 1fr;
  font-size: 16px;
  grid-gap: var(--base-size);
}

.list-item-2 {
  display: grid;
  grid-template-columns: var(--base-size) 1fr;
  grid-gap: inherit;
}

.list-item-3 {
  display: grid;
  grid-template-columns: var(--base-size) 1fr 40px;
  grid-gap: inherit;
}

.list-icon {
  place-self: center;
}

.address {
  display: grid;
  grid-template-columns: 5fr minmax(50px, 1fr) minmax(50px, 1fr);
  grid-gap: calc(var(--base-size) / 2);
}

.city {
  display: grid;
  grid-template-columns: minmax(50px, 1fr) 5fr;
  grid-gap: calc(var(--base-size) / 2);
}

.urls {
  display: grid;
  grid-template-columns: 5fr minmax(100px, 1.5fr) 90px;
  grid-gap: calc(var(--base-size) / 2);
}
</style>
