import { render, staticRenderFns } from "./CompanyDetailsNotes.vue?vue&type=template&id=41cc1384&scoped=true&"
import script from "./CompanyDetailsNotes.vue?vue&type=script&lang=js&"
export * from "./CompanyDetailsNotes.vue?vue&type=script&lang=js&"
import style0 from "./CompanyDetailsNotes.vue?vue&type=style&index=0&id=41cc1384&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41cc1384",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VSpacer,VTextarea,VToolbar,VToolbarTitle})
