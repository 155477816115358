<template>
  <span class="log-add-button">
    <v-btn fab bottom right dark fixed color="pink" @click.stop="dialog = true">
      <v-icon>far fa-fw fa-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="500">
      <template v-if="dialog">
        <v-card ma-0>
          <v-toolbar color="light-blue" class="primary" dense>
            <v-toolbar-title class="white--text">
              Category toevoegen
            </v-toolbar-title>
            <v-btn
              depressed
              small
              :disabled="isLoading"
              :loading="isLoading"
              class="log-add-button"
              color="primary lighten-2"
              @click="addCategory"
            >
              Add
            </v-btn>
          </v-toolbar>
          <div class="log-container">
            <v-autocomplete-category v-model="category.categoryId" />
          </div>
        </v-card>
      </template>
    </v-dialog>
  </span>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import ValidatableMixin from "../../mixins/ValidatableMixin";
import VAutocompleteCategory from "../../category/VAutocompleteCategory";

const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-category-add",
  components: { VAutocompleteCategory },
  mixins: [ValidatableMixin],
  data: () => ({
    isLoading: false,
    dialog: false,
    category: {
      categoryId: null,
      price: null
    }
  }),
  computed: {
    ...mapGetters(["company"])
  },
  methods: {
    async addCategory() {
      this.isLoading = true;
      const newCategory = { ...this.category };
      newCategory.price *= 100;
      await this.$store
        .dispatch("company/addCategory", {
          companyId: this.company.id,
          category: newCategory
        })
        .finally(() => {
          this.$emit("get-categories");
          this.isLoading = false;
          this.dialog = false;
        });
    }
  }
};
</script>
<style scoped>
.log-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
}
.log-add-button {
  margin: 0 0 0 auto;
}
</style>
