<template>
  <span>
    <v-btn
      class="log-add-button"
      depressed
      small
      color="primary lighten-2"
      @click.stop="dialog = true"
    >
      Edit
    </v-btn>
    <persistent-when-changed-dialog
      v-model="dialog"
      title="Customer"
      :changed="isChanged"
      :loading="isLoading"
      width="800px"
      @cancel="revert"
      @confirm="validate(save)"
    >
      <v-container>
        <span v-for="(customer, index) in editableCustomers" :key="index">
          <v-text-field
            class="contact-person-first-name"
            v-model="customer.firstName"
            v-validate="'max:255'"
            autocomplete="not-my-first-name"
            :error-messages="errors.collect('firstName')"
            data-vv-name="firstName"
            name="firstName"
            label="first name"
          />
          <v-text-field
            class="contact-person-last-name"
            v-model="customer.lastName"
            v-validate="'max:255'"
            autocomplete="not-my-last-name"
            :error-messages="errors.collect('lastName')"
            data-vv-name="lastName"
            name="lastName"
            label="last name"
          />
          <v-divider />
        </span>
      </v-container>
    </persistent-when-changed-dialog>
  </span>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import { isChanged } from "@/store/change";
import ValidatableMixin from "../../mixins/ValidatableMixin";
const { mapComputed } = createNamespacedHelpers("company");
import PersistentWhenChangedDialog from "@/components/generic/dialog/PersistentWhenChangedDialog";
import { cloneDeep } from "lodash";

export default {
  name: "company-edit-customer",
  components: { PersistentWhenChangedDialog },
  mixins: [ValidatableMixin],
  data: () => ({
    isLoading: false,
    dialog: false,
    initData: [],
    editableCustomers: []
  }),
  computed: {
    ...mapComputed(["customers"]),
    isChanged() {
      return isChanged(this.editableCustomers, this.initData);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.editableCustomers = cloneDeep(this.customers);
      this.initData = cloneDeep(this.customers);
    },
    revert() {
      this.editableCustomers = cloneDeep(this.initData);
    },
    async save() {
      this.isLoading = true;
      await this.$store.dispatch(
        "company/updateCustomers",
        this.editableCustomers.map(e => {
          return { id: e.id, firstName: e.firstName, lastName: e.lastName };
        })
      );
      this.isLoading = false;
      this.dialog = false;
    }
  }
};
</script>
