<template>
  <div>
    <table-overview
      title="Invoices"
      :store="store"
      :headers="headers"
      force-initial-sort="number"
      :actions="actions"
      descending
      searchable
    >
      <invoices-pdf-zip-export slot="toolbar-actions" />
      <invoice-table-row
        slot="table-row"
        slot-scope="{ item }"
        :invoice-id="item"
        :company-id="companyId"
      />
      <invoices-table-filter slot="filters" />
    </table-overview>
    <div class="totals">
      <strong>Selection total: {{ totalAmount }} (VAT excl.)</strong>
    </div>
  </div>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import invoicesModule from "@/store/modules/invoices";
import InvoiceTableRow from "../../components/invoice/InvoiceTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";
import InvoicesTableFilter from "../../components/invoice/InvoiceTableFilter";
import { isNil } from "lodash";
import InvoicesPdfZipExport from "../../components/invoice/InvoicesPdfZipExport";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "invoices",
  invoicesModule
);

export default {
  name: "invoices-view",
  components: {
    InvoicesPdfZipExport,
    InvoicesTableFilter,
    InvoiceTableRow,
    TableOverview
  },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  data: () => ({}),
  computed: {
    headers() {
      let headers = [
        { text: "Invoice #", value: "number" },
        { text: "Creditor Reference", sortable: false, align: "right" },
        {
          text: "# leads invoiced",
          value: "offerCount",
          align: "right",
          sortable: false
        },
        {
          text: "Offer acceptance %",
          value: "offer_acceptance_rate",
          align: "right",
          sortable: true
        },
        {
          text: "Billed at",
          value: "invoicedAt",
          align: "right",
          sortable: false
        },
        { text: "Due at", value: "dueAt", align: "right", sortable: false },
        {
          text: "Amount (VAT excl.)",
          value: "AmountVatExcl",
          align: "right",
          sortable: false
        },
        {
          text: "Amount (VAT incl.)",
          value: "AmountVatIncl",
          align: "right",
          sortable: false
        },
        { text: "Balance", value: "Balance", align: "right", sortable: false },
        {
          text: "Actions",
          align: "right",
          sortable: false,
          class: "v-table-th--icon",
          invoicesOrPermissions: "invoices_view"
        }
      ];
      if (!this.isCompanyView) {
        headers.splice(
          1,
          0,
          {
            text: "Name",
            value: "fiscal_name"
          },
          {
            text: "Commercial Name",
            value: "name"
          }
        );
      }
      return headers;
    },
    isCompanyView() {
      return !isNil(this.companyId);
    },
    store() {
      return this.isCompanyView ? "company/invoices" : "invoices";
    },
    actions() {
      return [
        {
          icon: "far fa-fw fa-plus",
          color: "pink",
          to: this.route,
          rolesOrPermissions: "invoice_create"
        }
      ];
    },
    route() {
      return this.isCompanyView
        ? {
            name: "company.invoices.create",
            params: { companyId: this.companyId }
          }
        : { name: "invoices.create" };
    },
    totalAmount() {
      return this.formatPrice(this.$store.getters[`${this.store}/totalAmount`]);
    }
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    }
  },
  ...overviewLifecycleMethods
};
</script>
<style scoped lang="scss">
.totals {
  position: relative;
  bottom: 50px;
  padding: 0 24px;
  width: 25%;
}
</style>
