import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import tableActions from "@/store/templates/table/actions";
import { SET_PAGINATION as DISPATCH_SET_PAGINATION } from "../../../templates/table/action-types";
import { SET_PAGINATION as COMMIT_SET_PAGINATION } from "../../../templates/table/mutation-types";

const fetchCall = api.invoice.itemOffer.fetchAll;

export default {
  async reject(context, { offerId, reason, comment }) {
    await api.invoice.itemOffer.reject(offerId, reason, comment);
  },
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    return await tableActions.fetch(context, { fetchCall });
  },
  async [DISPATCH_SET_PAGINATION]({ commit }, { pagination }) {
    // Set pagination state and fetch data.
    commit(COMMIT_SET_PAGINATION, { pagination });
  }
};
