<template>
  <div class="delivery-method-form-container">
    <v-card>
      <crud-header
        :title="name"
        type="Delivery method"
        store="deliveryMethod"
        @save-success="
          $router.push({
            name: 'company.delivery-methods',
            params: { companyId: company.id }
          })
        "
      />

      <company-delivery-method-form />
    </v-card>
  </div>
</template>

<script type="text/babel">
import deliveryMethodModule from "@/store/modules/company/delivery-method";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "@/store/helpers";
import { createNamespacedHelpers as createVuexNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "../../helpers";
import CompanyDeliveryMethodForm from "@/components/company/delivery-methods/CompanyDeliveryMethodForm";
import ValidatableMixin from "@/components/mixins/validation/ValidatableMixin";

const { mapComputed } = createNamespacedHelpers("deliveryMethod");

const { mapGetters: mapCompanyGetters } = createVuexNamespacedHelpers(
  "company"
);

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "deliveryMethod",
  deliveryMethodModule
);

export default {
  name: "company-delivery-method-view",
  mixins: [ValidatableMixin],
  components: { CompanyDeliveryMethodForm, CrudHeader },
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapComputed(["companyId", "name", "type", "recipients"]),
    ...mapCompanyGetters(["company"])
  },
  created() {
    this.companyId = this.company.id;
  },
  ...createViewLifecycleMethods
};
</script>
<style scoped>
.delivery-method-form-container {
  width: 800px;
  margin: 0 auto;
  padding: 24px 0;
}
</style>
