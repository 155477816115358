<template>
  <v-card>
    <v-toolbar :color="alertColor" dense>
      <v-btn icon class="hidden-xs-only" @click="dialog = true">
        <v-icon color="white">fad fa-user-circle</v-icon>
      </v-btn>
      <v-toolbar-title class="white--text user-toolbar">
        {{ message }}
      </v-toolbar-title>
    </v-toolbar>
    <company-edit-user-data :dialog="dialog" @close="dialog = false" />
  </v-card>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CompanyEditUserData from "@/components/company/edit/CompanyEditUserData";
import { isNil } from "lodash";
const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-user-data",
  components: { CompanyEditUserData },
  computed: {
    ...mapGetters(["primaryUser"]),
    message() {
      switch (this.primaryUser.verificationStatus) {
        case "pending":
          return `${this.primaryUser.email} -> (${this.primaryUser.requestedEmail})`;
        case "unverified":
          return isNil(this.primaryUser.requestedEmail)
            ? `${this.primaryUser.email}`
            : `${this.primaryUser.email} -> (${this.primaryUser.requestedEmail})`;
        default:
          return this.primaryUser.email;
      }
    },
    alertColor() {
      if (this.primaryUser.verificationStatus === "pending") {
        return "warning";
      } else if (this.primaryUser.verificationStatus === "unverified") {
        return "error";
      } else {
        return "success";
      }
    }
  },
  data() {
    return {
      dialog: false
    };
  }
};
</script>
<style scoped>
.user-toolbar {
  font-size: 16px;
}
</style>
