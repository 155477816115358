<template>
  <v-container>
    reset email
  </v-container>
</template>
<script type="text/babel">
export default {
  name: "company-reset-email",
  components: {}
};
</script>
