<template>
  <div class="subject-table-container">
    <table-overview
      title="Subjects"
      store="company/subjects"
      :headers="[
        { text: 'Subject', sortable: false, value: 'subject' },
        { text: 'Default price', sortable: false },
        { text: 'BE price', sortable: false },
        { text: 'NL price', sortable: false },
        { text: 'FR price', sortable: false },
        { text: 'DE price', sortable: false },
        { text: 'GB price', sortable: false },
        { text: 'LU price', sortable: false },
        {
          text: 'Delete',
          sortable: false,
          value: 'delete',
          align: 'center',
          width: '100px'
        }
      ]"
      :items="subjects"
    >
      <v-progress-linear slot="progress" indeterminate :height="3" />
      <company-subject-table-row
        slot="table-row"
        slot-scope="{ item }"
        :subject-id="item"
        @delete="openDeleteDialog"
      />
    </table-overview>
    <company-subject-add @get-subjects="getSubjects()" />
    <company-subject-delete-dialog
      @success="getSubjects"
      v-if="deleteDialog"
      :dialog.sync="deleteDialog"
      :subject-id="deleteSubjectId"
    />
  </div>
</template>
<script type="text/babel">
import CompanySubjectTableRow from "@/components/company/subjects/CompanySubjectTableRow";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("company/subjects");
import TableOverview from "@/components/generic/overview/TableOverview";
import CompanySubjectDeleteDialog from "@/components/company/subjects/CompanySubjectDeleteDialog";
import CompanySubjectAdd from "../../components/company/subjects/CompanySubjectAdd";

export default {
  name: "company-subjects-view",
  components: {
    CompanySubjectAdd,
    CompanySubjectDeleteDialog,
    TableOverview,
    CompanySubjectTableRow
  },
  data() {
    return {
      subjects: [],
      deleteDialog: false,
      deleteSubjectId: null
    };
  },
  computed: {
    ...mapGetters(["subject"])
  },
  created() {
    this.getSubjects();
  },
  methods: {
    async getSubjects() {
      this.subjects = await this.$store.dispatch(
        `company/subjects/${DISPATCH_FETCH}`
      );
    },
    async openDeleteDialog(id) {
      this.deleteSubjectId = id;
      this.deleteDialog = true;
    }
  }
};
</script>
<style scoped>
.subject-table-container {
  max-width: 90%; /*looks weird either way*/
  margin: 24px auto;
}
</style>
