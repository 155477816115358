<template>
  <v-form>
    <v-autocomplete-company
      v-model="company"
      :states="['AFFILIATED', 'PAUSED']"
      multiple
      clearable
    />
    <between-date-input title="Billed between" v-model="invoiced_at" />
    <b-select
      label="States"
      v-model="state"
      multiple
      :items="['DRAFT', 'OPEN', 'PAID']"
      clearable
    />
    <v-autocomplete-country v-model="country" multiple clearable />
    <v-checkbox
      v-model="isCredited"
      label="Has credit note"
      color="primary"
      hide-details
    />
  </v-form>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteCompany from "../company/VAutocompleteCompany";
import BSelect from "../generic/BSelect";
import BetweenDateInput from "../generic/input/dates/BetweenDateInput";
import VAutocompleteCountry from "../country/VAutocompleteCountry";
const { mapComputedFilters } = createNamespacedHelpers("invoices");

export default {
  name: "invoices-table-filter",
  components: {
    VAutocompleteCountry,
    BetweenDateInput,
    BSelect,
    VAutocompleteCompany
  },
  computed: {
    ...mapComputedFilters([
      "company",
      "invoiced_at",
      "state",
      "country",
      "isCredited"
    ])
  }
};
</script>
