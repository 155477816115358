<template>
  <v-card :class="cardClass" :style="cardStyle">
    <v-container fluid>
      <v-checkbox v-model="isActive" label="Active" hide-details />
      <v-text-field
        v-model="name"
        autocomplete="filter-name"
        :counter="255"
        label="Name"
        hint="Optional name to easily identify this filter."
        :persistent-hint="true"
      />
      <v-text-field
        v-model="apiKey"
        autocomplete="filter-api-key"
        :counter="255"
        label="Api Key"
        hint="Optional api key for this filter"
        :persistent-hint="true"
      />
      <v-text-field
        v-model="priceEuro"
        autocomplete="filter-price-euro"
        :counter="255"
        prefix="€"
        label="Price"
        hint="Optional override to price, specified in euro."
        :persistent-hint="true"
      />
      <v-checkbox v-model="isAutoclaim" label="Autoclaim" />
      <v-checkbox v-model="isAutoDelivery" label="Auto Delivery" />
      <v-checkbox
        v-if="isBulditFilter"
        v-model="isPriority"
        label="Is a priority filter"
      />
      <v-text-field
        v-model="maxWeeklyLeads"
        type="number"
        autocomplete="filter-max-weekly"
        data-vv-name="max-weekly"
        label="Max Weekly Leads"
        hint="Limit how many leads this filter can autoclaim per week. Leave blank to remove any limitation."
        persistent-hint
        :disabled="!isAutoclaim"
      />
      <v-autocomplete-delivery-method
        v-if="isCompanyFilter"
        v-model="deliveryMethods"
        :company="company"
        multiple
        hint="Leave blank in order to use company default delivery methods."
      />
      <v-textarea
        v-model="remark"
        :counter="1000"
        autocomplete="filter-remark"
        label="Remarks"
        auto-grow
      ></v-textarea>
      <v-subheader>Vacation mode</v-subheader>
      <v-switch
        v-model="isPaused"
        color="primary"
        label="Vacation mode indefinitely"
      ></v-switch>
      <between-date-input v-model="betweenDates" both-required />
      <v-subheader v-if="hasParentFilters">
        Parent Filters:
      </v-subheader>
      <v-list two-line v-if="hasParentFilters">
        <template v-for="(item, index) in parentFilters">
          <router-link
            class="filter-links"
            :to="{
              name: 'filters.show',
              params: { filterId: item.filter }
            }"
            target="_blank"
            :key="filter(item.filter).name"
          >
            <v-list-tile avatar ripple>
              <v-list-tile-content>
                <v-list-tile-title>{{
                  filter(item.filter).name
                }}</v-list-tile-title>
                <v-list-tile-sub-title class="text--primary">{{
                  !filter(item.filter).isActive
                    ? "Inactive"
                    : filter(item.filter).paused
                    ? "Paused"
                    : "Active"
                }}</v-list-tile-sub-title>
                <v-list-tile-sub-title v-if="filter(item.filter).paused">
                  {{ formatDate(filter(item.filter).pausedFrom) }}
                  -
                  {{ formatDate(filter(item.filter).pausedTill) }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </router-link>
          <v-divider
            v-if="index + 1 < parentFilters.length"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
    </v-container>
  </v-card>
</template>

<script type="text/babel">
import FilterStepperMixin from "./FilterStepperMixin.vue";
import { createNamespacedHelpers } from "vuex";
import { createNamespacedHelpers as createNamespacedComputedHelpers } from "../../store/helpers";
import VAutocompleteDeliveryMethod from "./VAutocompleteDeliveryMethod";
import { isNil } from "lodash";
import BetweenDateInput from "@/components/generic/input/dates/BetweenDateInput";
import moment from "moment";

const { mapGetters } = createNamespacedHelpers("filter");
const { mapComputed } = createNamespacedComputedHelpers("filter");

export default {
  name: "filter-meta",
  components: { BetweenDateInput, VAutocompleteDeliveryMethod },
  mixins: [FilterStepperMixin],
  inject: ["$validator"],
  computed: {
    betweenDates: {
      get() {
        return { from: this.pausedFrom, to: this.pausedTill };
      },
      set(dates) {
        if (!isNil(dates.from) || !isNil(dates.to)) {
          this.isPaused = false;
        }
        this.pausedFrom = dates.from;
        this.pausedTill = dates.to;
      }
    },
    isPaused: {
      get() {
        return this.$store.getters["filter/isPaused"];
      },
      set(value) {
        if (value) {
          this.pausedFrom = null;
          this.pausedTill = null;
        }
        this.$store.commit("filter/setIsPaused", value);
      }
    },
    ...mapGetters([
      "isCompanyFilter",
      "isBulditFilter",
      "company",
      "parentFilters",
      "hasParentFilters"
    ]),
    ...mapComputed([
      "isActive",
      "name",
      "apiKey",
      "priceEuro",
      "isAutoclaim",
      "isAutoDelivery",
      "isPriority",
      "maxWeeklyLeads",
      "deliveryMethods",
      "remark",
      "pausedTill",
      "pausedFrom"
    ])
  },
  methods: {
    filter(id) {
      return this.$store.state.filter.filter[id];
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>
<style scoped>
.filter-links {
  text-decoration: none;
}
</style>
