<template>
  <v-autocomplete
    v-model="model"
    v-validate="internalValidationRules"
    :name="internalName"
    :data-vv-name="internalName"
    :data-vv-as="label"
    :error-messages="internalErrorMessages"
    :items="items"
    :label="internalLabel"
    :item-text="itemText"
    :item-value="itemValue"
    :readonly="isReadOnly"
    :disabled="isDisabled"
    :multiple="isMultiple"
    :clearable="clearable"
    :loading="isLoading"
    :search-input.sync="search"
    :return-object="returnObject"
    :no-filter="isAsync"
    :chips="isMultiple"
    persistent-hint
    :browser-autocomplete="browserAutocomplete"
    @change="onChange"
  >
    <template v-slot:selection="{ item, selected }" v-if="isMultiple">
      <v-chip :selected="selected" small label>{{ item.name }}</v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-tile-content>
        <v-list-tile-title
          v-html="addSearchHighlightMarkup(item.name)"
        ></v-list-tile-title>
        <v-list-tile-sub-title
          v-html="gatVatNumber(item)"
        ></v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-avatar v-if="!customer && !reseller && item.isCustomer">
        <img :src="logo" />
      </v-list-tile-avatar>
    </template>
  </v-autocomplete>
</template>

<script type="text/babel">
import VAutocompleteMixin from "@/components/mixins/input/VAutocompleteMixin";
import logo from "@/assets/logo/icon.svg";
import { isNil } from "lodash";

export default {
  name: "v-autocomplete-company",
  inject: ["$validator"],
  mixins: [VAutocompleteMixin],
  props: {
    customer: {
      type: Boolean,
      default: false
    },
    reseller: {
      type: Boolean,
      default: false
    },
    states: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    store: "company-select",
    defaultLabel: "company",
    defaultLabelMultiple: "companies",
    logo: logo,
    includes: ["billingData.country"]
  }),
  watch: {
    customer: {
      handler() {
        if (this.customer) {
          delete this.asyncFilters.isReseller;
          this.asyncFilters.isCustomer = true;
          this.defaultLabel = "customer";
          this.defaultLabelMultiple = undefined;
          if (this.isInitialized) {
            this.doSearch();
          }
        } else {
          delete this.asyncFilters.isCustomer;
          delete this.asyncFilters.isReseller;
          this.defaultLabel = "company";
          this.defaultLabelMultiple = "companies";
          if (this.isInitialized) {
            this.doSearch();
          }
        }
      },
      immediate: true
    },
    reseller: {
      handler() {
        if (this.reseller) {
          delete this.asyncFilters.isCustomer;
          this.asyncFilters.isReseller = true;
          this.defaultLabel = "reseller";
          this.defaultLabelMultiple = undefined;
          if (this.isInitialized) {
            this.doSearch();
          } else {
            delete this.asyncFilters.isCustomer;
            delete this.asyncFilters.isReseller;
            this.defaultLabel = "company";
            this.defaultLabelMultiple = "companies";
            if (this.isInitialized) {
              this.doSearch();
            }
          }
        }
      },
      immediate: true
    },
    states: {
      handler(newVal) {
        if (this.states.length > 0) {
          delete this.asyncFilters.isCustomer;
          delete this.asyncFilters.isReseller;
          this.asyncFilters.states = newVal;
          this.defaultLabel = "Affiliate";
          this.defaultLabelMultiple = undefined;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    gatVatNumber(item) {
      return !isNil(item.billingData?.vatNumber)
        ? this.addSearchHighlightMarkup(item.billingData.vatNumber)
        : "No VAT";
    }
  }
};
</script>
