<template>
  <v-card ma-0>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Logs
      </v-toolbar-title>
      <v-spacer />
      <company-details-log-add
        @reset="addCounter++"
        @getLogs="getLogs"
        :key="addCounter"
      />
    </v-toolbar>
    <div class="log-container">
      <v-timeline dense>
        <company-details-log
          v-for="log in logs"
          :key="log.id"
          :log="log"
          @updateLog="updateLogId = log.id"
          @getLogs="getLogs"
        />
      </v-timeline>
    </div>
    <company-details-log-update
      v-model="updateLogId"
      @reset="updateLogId = null"
      @getLogs="getLogs"
    />
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CompanyDetailsLogAdd from "./CompanyDetailsLogAdd";
import CompanyDetailsLog from "./CompanyDetailsLog";

import CompanyDetailsLogUpdate from "@/components/company/details/CompanyDetailsLogUpdate";

const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-details-logs",
  components: {
    CompanyDetailsLogUpdate,
    CompanyDetailsLog,
    CompanyDetailsLogAdd
  },
  data: () => ({
    addCounter: 0,
    updateLogId: null
  }),
  computed: {
    ...mapGetters(["logs"])
  },
  methods: {
    async getLogs() {
      await this.$store.dispatch("company/fetchLogs", [
        "text",
        "email",
        "phone"
      ]);
    }
  }
};
</script>
<style scoped>
.log-container {
  padding: 0 20px 0 10px;
  max-height: 500px;
  overflow-x: auto;
}
</style>
