var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-table-container"},[_c('table-overview',{attrs:{"title":"Delivery Methods","store":"company/deliveryMethods","headers":[
      { text: 'Type', value: 'type' },
      { text: 'Name', value: 'name' },
      { text: 'Recipients', sortable: false, value: 'recipients' },
      { text: 'Filters', sortable: false, value: 'filterNames' },
      { text: 'Url', sortable: false, value: 'url' },
      {
        text: 'Edit',
        sortable: false,
        value: 'edit',
        align: 'center',
        width: '100px'
      },
      {
        text: 'Delete',
        sortable: false,
        value: 'delete',
        align: 'center',
        width: '100px'
      }
    ],"actions":_vm.actions},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('company-delivery-method-table-row',{attrs:{"delivery-method-id":item},on:{"delete":_vm.openDeleteDialog}})}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":"","height":3},slot:"progress"})],1),(_vm.deleteDialog)?_c('company-delivery-method-delete-dialog',{attrs:{"dialog":_vm.deleteDialog,"delivery-method-id":_vm.deleteDeliveryMethodId},on:{"success":_vm.getDeliveryMethods,"update:dialog":function($event){_vm.deleteDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }