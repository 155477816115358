<template>
  <v-layout style="padding-top: 10px" row>
    <v-flex xs3 style="padding-bottom: 0px;">
      <v-navigation-drawer stateless value="true">
        <v-list subheader>
          <v-subheader>Company Settings</v-subheader>

          <v-list-tile
            v-for="(item, i) in companySettings"
            :key="`A-${i}`"
            @click="setComponent(item.component)"
          >
            <v-list-tile-action>
              <v-icon
                v-text="item.icon"
                :color="
                  dynamicComponent === item.component ? 'primary' : 'gray'
                "
              ></v-icon>
            </v-list-tile-action>
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
          </v-list-tile>

          <v-subheader>User Settings</v-subheader>
          <v-list-tile
            v-for="(item, i) in userSettings"
            :key="`B-${i}`"
            @click="setComponent(item.component)"
          >
            <v-list-tile-action>
              <v-icon
                v-text="item.icon"
                :color="
                  dynamicComponent === item.component ? 'primary' : 'gray'
                "
              ></v-icon>
            </v-list-tile-action>
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>
    </v-flex>
    <v-flex xs9>
      <component :is="dynamicComponent"></component>
    </v-flex>
  </v-layout>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("company/filters");
import CompanyResetPassword from "@/components/company/settings/CompanyResetPassword";
import CompanyResetEmail from "@/components/company/settings/CompanyResetEmail";
import CompanyVacationModeSetting from "@/components/company/settings/CompanyVacationModeSetting";
import CompanyGeneralSettings from "@/components/company/settings/CompanyGeneralSettings";

export default {
  name: "company-settings-view",
  components: {
    CompanyResetPassword,
    CompanyResetEmail,
    CompanyVacationModeSetting,
    CompanyGeneralSettings
  },
  data: () => ({
    companySettings: [
      {
        title: "General",
        icon: "fad fa-cog",
        component: "company-general-settings"
      },
      {
        title: "Vacation Mode",
        icon: "fad fa-plane",
        component: "company-vacation-mode-setting"
      }
    ],
    userSettings: [
      {
        title: "Password reset",
        icon: "fad fa-key",
        component: "company-reset-password"
      },
      {
        title: "E-mail verification",
        icon: "fad fa-envelope",
        component: "company-reset-email"
      }
    ],
    dynamicComponent: "company-general-settings"
  }),
  computed: {
    ...mapGetters(["id"])
  },
  methods: {
    setComponent(val) {
      this.dynamicComponent = val;
    }
  }
};
</script>

<style>
.v-dialog.company-filter-dialog {
  /*min-height: 60%;*/
  width: 60%;
}
</style>
