<template>
  <v-form>
    <v-autocomplete
      v-model="states"
      :items="selectStates"
      label="States"
      multiple
      clearable
    />
    <v-autocomplete-category v-model="categories" multiple />
    <v-autocomplete-country v-model="countries" multiple />
    <v-autocomplete-language v-model="languages" multiple />
    <v-checkbox
      v-model="isReseller"
      label="Resellers Only"
      color="primary"
      hide-details
    />
    <v-checkbox
      v-model="paused"
      label="Paused only"
      color="primary"
      hide-details
    />
  </v-form>
</template>
<script type="text/babel">
import { forOwn } from "lodash";
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteCountry from "../country/VAutocompleteCountry";
import VAutocompleteLanguage from "../language/VAutocompleteLanguage";
import VAutocompleteCategory from "../category/VAutocompleteCategory";

const { mapComputedFilters } = createNamespacedHelpers("companies");

export default {
  name: "companies-view-filters",
  components: {
    VAutocompleteCategory,
    VAutocompleteCountry,
    VAutocompleteLanguage
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  computed: {
    selectStates() {
      let statuses = [];
      forOwn(window.config.enums.company_states, (property, value) =>
        statuses.push({ text: property, value: value })
      );
      return statuses;
    },
    ...mapComputedFilters([
      "states",
      "categories",
      "countries",
      "languages",
      "isReseller",
      "paused"
    ])
  }
};
</script>
