<template>
  <span class="log-add-button">
    <v-btn fab bottom right dark fixed color="pink" @click.stop="dialog = true">
      <v-icon>far fa-fw fa-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="500">
      <template v-if="dialog">
        <v-card ma-0>
          <v-toolbar color="light-blue" class="primary" dense>
            <v-toolbar-title class="white--text">
              Subject toevoegen
            </v-toolbar-title>
            <v-btn
              depressed
              small
              :disabled="isLoading"
              :loading="isLoading"
              class="log-add-button"
              color="primary lighten-2"
              @click="addSubject"
            >
              Add
            </v-btn>
          </v-toolbar>
          <div class="log-container">
            <v-autocomplete-subject v-model="subject" />
          </div>
        </v-card>
      </template>
    </v-dialog>
  </span>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import ValidatableMixin from "../../mixins/ValidatableMixin";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";

const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-subject-add",
  components: { VAutocompleteSubject },
  mixins: [ValidatableMixin],
  data: () => ({
    isLoading: false,
    dialog: false,
    subject: null
  }),
  computed: {
    ...mapGetters(["company"])
  },
  methods: {
    async addSubject() {
      this.isLoading = true;
      await this.$store.dispatch("company/subjects/addSubject", {
        companyId: this.$store.state.company.model.id,
        subject: this.subject
      });
      this.isLoading = false;
      this.dialog = false;
      this.subject = null;
    }
  }
};
</script>
<style scoped>
.log-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
}
.log-add-button {
  margin: 0 0 0 auto;
}
</style>
