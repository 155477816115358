<template>
  <v-card ma-0>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Customer
      </v-toolbar-title>
      <v-spacer />
      <company-edit-customer />
    </v-toolbar>

    <div class="contact-persons-container">
      <span v-for="(customer, i) in customers" :key="i">
        <p>{{ customer.firstName }} {{ customer.lastName }}</p>
        <v-divider />
      </span>
    </div>
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CompanyEditCustomer from "../edit/CompanyEditCustomer";
const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-details-customer",
  components: { CompanyEditCustomer },
  computed: {
    ...mapGetters(["customers"])
  }
};
</script>
<style scoped>
.contact-persons-container {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  color: #1a1a1a;
}
</style>
