<template>
  <div>
    <v-data-table :items="item.offers" :rows-per-page-items="[20, 50, 100]">
      <invoice-create-item-offers-table-row
        slot="items"
        slot-scope="props"
        :offer-id="props.item"
        :is-odd="props.index % 2 !== 0"
        is-in-item
      />
    </v-data-table>
  </div>
</template>

<script type="text/babel">
import InvoiceCreateItemOffersTableRow from "./InvoiceCreateItemOffersTableRow";

export default {
  name: "invoice-create-item-offers-info",
  components: { InvoiceCreateItemOffersTableRow },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),
  methods: {}
};
</script>
<style scoped>
>>> .v-table__overflow table thead {
  display: none !important;
}
>>> .v-table__overflow table tbody tr {
  border-bottom: none !important;
}
>>> .v-table__overflow table {
  overflow: hidden;
}
</style>
