<template>
  <tr
    v-bind:class="{
      odd: isOdd,
      warning:
        !isRejectionExpired ||
        (offer.isRejectionRequested && !offer.isCreditingIssued)
    }"
    v-bind:style="
      !isRejectionExpired ||
      (offer.isRejectionRequested && !offer.isCreditingIssued)
        ? { border: 'solid #bc5a01 2px !important' }
        : {}
    "
  >
    <div class="grid-row">
      <p class="py-1 ma-0">
        <strong>#{{ offer.id }}</strong>
        -
        {{ submission.firstName }}
        {{ submission.lastName }} -
        {{ postalCode }}
        {{ city }}
        <strong v-if="!isRejectionExpired"
          >!! OFFER IS STILL IN IT'S REJECTABLE PERIOD !!</strong
        >
        <strong v-if="offer.isRejectionRequested && !offer.isCreditingIssued"
          >!! OFFER HAS AN OPEN REJECTION REQUEST !!</strong
        >
      </p>
      <v-btn
        class="reject-btn"
        flat
        color="error"
        :disabled="offer.isCreditingIssued"
        @click="showOfferDialog = !showOfferDialog"
        >{{ offer.isCreditingIssued ? "CREDITING" : "REJECT" }}</v-btn
      >
      <p class="py-1 ma-0 text-sm-right">
        {{ formatPrice(offer.price) }}
      </p>
    </div>
    <invoice-create-item-offer-reject-dialog
      :offer-id="offerId"
      :show-dialog="showOfferDialog"
      @close="showOfferDialog = false"
      :is-in-item="isInItem"
    />
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import moment from "moment";
import InvoiceCreateItemOfferRejectDialog from "./InvoiceCreateItemOfferRejectDialog";

export default {
  name: "invoice-create-item-offers-table-row",
  components: { InvoiceCreateItemOfferRejectDialog },
  props: {
    offerId: {
      type: Number,
      required: true
    },
    isOdd: {
      type: Boolean,
      required: true
    },
    isInItem: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showOfferDialog: false
  }),
  computed: {
    offer() {
      return this.$store.getters["invoice/offer"](this.offerId);
    },
    submission() {
      return this.$store.getters["invoice/submission"](this.offerId);
    },
    address() {
      return this.$store.getters["invoice/address"](this.offerId);
    },
    postalCode() {
      return this.address?.postalCode;
    },
    city() {
      return this.address?.cityName;
    },
    companyRejectableTerm() {
      return this.$store.getters["invoice/companyRejectableTerm"];
    },
    isRejectionExpired() {
      if (!isNil(this.companyRejectableTerm)) {
        const maxRejectionDate = moment().subtract(
          this.companyRejectableTerm,
          "days"
        );
        console.log(maxRejectionDate);
        return moment(this.offer.distributedAt) < maxRejectionDate;
      } else {
        return this.offer.isRejectionExpired;
      }
    }
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    }
  }
};
</script>
<style scoped>
.odd {
  background-color: #e8e8e8;
}
.odd:hover {
  background-color: #e0e0e0 !important;
}
.warning {
  background-color: var(--v-warning-base);
  border: solid #bc5a01 2px !important;
  color: #bc5a01;
}
.warning:hover {
  background-color: #e07e02 !important;
  border: solid #a85301 2px !important;
  color: #934a01;
}
.grid-row {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.reject-btn {
  height: 15px;
  max-width: 70px;
  font-size: 10px;
  padding: 0 !important;
  border-radius: 0 !important;
  min-width: 60px;
}
</style>
