<template>
  <v-card>
    <v-toolbar dense dark color="primary">
      <v-toolbar-side-icon
        ><v-icon>fad fa-file-invoice-dollar</v-icon></v-toolbar-side-icon
      >
      <v-toolbar-title class="white--text"
        ><span class="primary--text text--lighten-2">CREDIT NOTE</span>
        {{ creditNote.number }}</v-toolbar-title
      >
      <v-spacer />
      <v-menu v-if="creditNote.state === 'DRAFT'" bottom left class="pl-2">
        <v-btn slot="activator" dark icon>
          <v-icon>more_vert</v-icon>
        </v-btn>
        <v-list light>
          <v-list-tile @click.stop="deleteDialog = true">
            <v-list-tile-title>Delete draft</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-btn
        v-if="creditNote.state !== 'DRAFT'"
        :disabled="!creditNote.pdfDownloadable"
        icon
        @click="downloadDialog = true"
      >
        <v-icon>fad fa-file-pdf</v-icon>
      </v-btn>
      <v-btn icon :to="detailsRoute">
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="pa-3">
      <div :class="[headerWrapperClass, 'pa-3']">
        <div class="general-info">
          <v-icon class="invoice-icon" color="black"
            >fad fa-file-invoice-dollar</v-icon
          >
          <div class="invoiced-info">
            <div>
              <h1 class="text-uppercase">
                <mark :class="[markClass]"
                  >{{ creditNote.state }} CREDIT NOTE</mark
                >
              </h1>
              <h1 class="text-uppercase">
                TOTAL (VAT excl.): {{ formatPrice(creditNote.amount) }}
              </h1>
              <h1 class="text-uppercase">
                TOTAL (VAT incl.):
                {{ formatPrice(creditNote.totalAmount) }}
              </h1>
            </div>
            <div class="text-xs-right">
              <p class="ma-1" v-for="(auditTrail, i) in auditTrails" :key="i">
                {{ auditTrail }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <download-credit-note-dialog
      :download-dialog="downloadDialog"
      :credit-note-id="creditNote.id"
      @close="downloadDialog = false"
    />
    <delete-credit-note-dialog
      :dialog="deleteDialog"
      :credit-note-id="creditNote.id"
      @close="deleteDialog = false"
    />
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
import { isNil } from "lodash";
import moment from "moment";
import DeleteCreditNoteDialog from "./credit-note/DeleteCreditNoteDialog";
import DownloadCreditNoteDialog from "./credit-note/DownloadCreditNoteDialog";
const { mapGetters } = VuexNamespacedHelpers("invoice");

export default {
  name: "invoice-credit-note",
  components: { DownloadCreditNoteDialog, DeleteCreditNoteDialog },
  props: {
    creditNote: {
      type: Object,
      required: true
    },
    companyId: {
      type: Number,
      required: false
    }
  },
  inject: ["$validator"],
  data: () => ({
    fab: false,
    deleteDialog: false,
    downloadDialog: false
  }),
  computed: {
    ...mapGetters([]),
    auditTrails() {
      let auditTrails = [];
      if (
        this.creditNote.state === "DRAFT" ||
        this.creditNote.state === "OPEN"
      ) {
        auditTrails.push(
          `Drafted @ ${this.formatDate(this.creditNote.draftedAt)} by ${
            this.creditNote.draftedBy?.employee?.fullName
          }`
        );
      }
      if (this.creditNote.state === "OPEN") {
        auditTrails.push(
          `Finalized @ ${this.formatDate(this.creditNote.finalizedAt)} by ${
            this.creditNote.finalizedBy?.employee?.fullName
          }`
        );
      }
      return auditTrails;
    },
    headerWrapperClass() {
      if (this.creditNote.state === "DRAFT") {
        return "header-wrapper-draft";
      } else {
        return "header-wrapper-open";
      }
    },
    markClass() {
      if (this.creditNote.state === "DRAFT") {
        return "mark-draft";
      } else {
        return "mark-open";
      }
    },
    isCompanyView() {
      return !isNil(this.companyId);
    },
    detailsRoute() {
      return this.isCompanyView
        ? {
            name: "company.invoices.credit-notes.show",
            params: {
              companyId: this.companyId,
              creditNoteId: this.creditNote.id
            }
          }
        : {
            name: "invoices.credit-notes.show",
            params: {
              creditNoteId: this.creditNote.id
            }
          };
    }
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    }
  }
};
</script>
<style scoped>
.header-wrapper-draft {
  background-color: #e0e0e0 !important;
}
.header-wrapper-open {
  background-color: #e3f2fd !important;
}
.mark-draft {
  color: black !important;
}
.mark-open {
  color: #027dd6 !important;
}
.general-info {
  display: grid;
  grid-template-columns: 3% 97%;
  grid-template-rows: 100%;
}
.invoice-icon {
  grid-column-start: 1;
  justify-self: center;
  align-self: center;
  font-size: 36px;
}
.invoiced-info {
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  display: grid;
}
.invoiced-info h1 {
  line-height: 0.9;
  font-size: 20px;
  font-weight: 600;
}
.blacklisted {
  color: red;
}
mark {
  background-color: unset;
}
</style>
