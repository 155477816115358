<template>
  <div v-if="!showChild">
    <v-card color="white">
      <v-toolbar dark color="primary" dense>
        <v-icon class="white--text">fas fa-file-invoice</v-icon>
        <v-toolbar-title class="white--text"
          ><span class="primary--text text--lighten-2">INVOICE</span>
          {{ invoice.number }}</v-toolbar-title
        >
        <v-spacer />
        <v-menu v-if="state === 'DRAFT'" bottom left class="pl-2">
          <v-btn slot="activator" dark icon>
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-list light>
            <v-list-tile @click.stop="deleteDialog = true">
              <v-list-tile-title>Delete draft</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn
          v-if="state !== 'DRAFT'"
          :disabled="!pdfDownloadable"
          icon
          @click="downloadInvoiceDialog = true"
        >
          <v-icon>fas fa-file-pdf</v-icon>
        </v-btn>
      </v-toolbar>
      <invoice />
      <download-invoice-dialog
        v-if="downloadInvoiceDialog"
        :download-dialog="downloadInvoiceDialog"
        :number="invoice.number"
        @close="download = false"
      />
      <delete-invoice-dialog
        :dialog="deleteDialog"
        :company-id="companyId"
        @close="deleteDialog = false"
      />
      <invoice-actions :company-id="companyId" />
    </v-card>
    <invoice-credit-notes :company-id="companyId" />
  </div>
  <router-view v-else />
</template>

<script type="text/babel">
import invoiceModule from "@/store/modules/invoice";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import DownloadInvoiceDialog from "../../components/invoice/DownloadInvoiceDialog";
import InvoiceActions from "../../components/invoice/InvoiceActions";
import Invoice from "../../components/invoice/Invoice";
import DeleteInvoiceDialog from "../../components/invoice/DeleteInvoiceDialog";
import InvoiceCreditNotes from "../../components/invoice/InvoiceCreditNotes";

const { mapGetters: mapGetters } = createNamespacedHelpers("invoice");
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "invoice",
  invoiceModule
);

export default {
  name: "invoice-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    InvoiceCreditNotes,
    DeleteInvoiceDialog,
    Invoice,
    InvoiceActions,
    DownloadInvoiceDialog
  },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  data: () => {
    return {
      fab: false,
      show: true,
      isLoading: false,
      showContent: false,
      creditNoteContents: [],
      downloadInvoiceDialog: false,
      deleteDialog: false
    };
  },
  computed: {
    ...mapGetters([
      "invoice",
      "company",
      "hasRejectableOffers",
      "hasRejectionRequestedOffers",
      "state",
      "pdfDownloadable"
    ]),
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    }
  },
  methods: {
    async confirm(id) {
      this.isLoading = true;
      await this.$store.dispatch("invoice/confirm", {
        id: id,
        force: this.hasRejectableOffers || this.hasRejectionRequestedOffers
      });
      this.isLoading = false;
    }
  },
  ...detailViewLifecycleMethods
};
</script>
<style scoped>
.display-center {
  display: flex;
}
</style>
