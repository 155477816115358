<template>
  <tr :class="{ error: isRejected }">
    <td>{{ offer.id }}</td>
    <td>{{ offer.lead.submission.fullName }}</td>
    <td>
      <v-chip
        v-for="(leadOffer, index) in leadOffers"
        :key="index"
        label
        small
        :dark="index === 0"
        :color="index === 0 ? 'secondary' : undefined"
      >
        <i class="far fa-building fa-fw" /> {{ leadOffer.company.name }}
        <i class="far fa-dot-circle fa-fw ml-1" /> {{ leadOffer.subject.name }}
      </v-chip>
    </td>
    <td v-if="distributedAt">{{ distributedAt }}</td>
    <td v-else>
      <v-chip label small color="warning">
        UNSENT
      </v-chip>
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'submissions.show',
          params: { submissionId: offer.lead.submission.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import OfferMixin from "@/components/submission/lead/offer/OfferMixin";

export default {
  name: "company-offer-table-row",
  mixins: [OfferMixin],
  computed: {
    distributedAt() {
      return this.offer.distributedAt
        ? this.offer.distributedAt
            .slice(0, 10)
            .split("-")
            .reverse()
            .join("-")
        : "";
    },
    leadOffers() {
      const ownOffers = this.offer.lead.offers.filter(
        offer => offer.company.id === this.offer.company.id
      );
      const otherOffers = this.offer.lead.offers.filter(
        offer => offer.company.id !== this.offer.company.id
      );
      return ownOffers.concat(otherOffers);
    }
  }
};
</script>
