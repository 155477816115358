<template>
  <span>
    <v-dialog v-model="dialog" width="500">
      <template v-if="dialog">
        <v-card ma-0>
          <v-toolbar color="light-blue" class="primary" dense>
            <v-toolbar-title class="white--text">
              Log Updaten
            </v-toolbar-title>
            <v-btn
              depressed
              small
              :disabled="isLoading"
              :loading="isLoading"
              class="log-add-button"
              color="primary lighten-2"
              @click="update"
            >
              Update
            </v-btn>
          </v-toolbar>
          <div class="log-container">
            <v-flex xs12 sm6 class="py-2">
              <v-btn-toggle v-model="log.type" mandatory>
                <v-btn flat value="text">
                  <v-icon>far fa-comment</v-icon>
                </v-btn>
                <v-btn flat value="phone">
                  <v-icon>fas fa-phone</v-icon>
                </v-btn>
                <v-btn flat value="email">
                  <v-icon>far fa-envelope</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-flex>
            <v-text-field
              v-model="log.title"
              v-validate="'required|max:255'"
              autocomplete="not-my-title"
              :counter="255"
              :error-messages="errors.collect('title')"
              data-vv-name="title"
              box
              name="title"
              label="Title"
            />
            <v-textarea
              v-model="log.description"
              v-validate="'max:255'"
              autocomplete="not-my-description"
              :counter="255"
              :error-messages="errors.collect('description')"
              data-vv-name="description"
              box
              class="log-textarea"
              name="description"
              auto-grow
              label="Description"
            />
            <date-time-input
              v-model="log.occurredAt"
              v-validate="'required|max:19'"
              autocomplete="not-my-date"
              :error-messages="errors.collect('occurredAt')"
              data-vv-name="occurredAt"
            />
          </div>
        </v-card>
      </template>
    </v-dialog>
  </span>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import ValidatableMixin from "../../mixins/ValidatableMixin";
import DateTimeInput from "../../generic/input/dates/DateTimeInput";
const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-details-log-update",
  components: { DateTimeInput },
  mixins: [ValidatableMixin],
  data: () => ({
    isLoading: false,
    log: {
      id: null,
      companyId: null,
      type: "text",
      title: null,
      description: null,
      occurredAt: null,
      userId: null
    }
  }),
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters(["company"]),
    oldLog() {
      return this.$store.state.company.logs.entity[this.value];
    },
    dialog: {
      get() {
        return !!this.value;
      },
      set() {
        this.$emit("reset");
      }
    }
  },
  methods: {
    async update() {
      try {
        this.isLoading = true;
        this.log.userId = this.$store.state.auth.user.id;
        delete this.log.userName;
        await this.$store.dispatch(`company/updateLog`, this.log);
      } finally {
        this.isLoading = false;
        this.dialog = false;
        this.$emit("getLogs");
        this.$emit("reset");
      }
    }
  },
  watch: {
    value: function() {
      Object.assign(this.log, this.oldLog);
    }
  }
};
</script>
<style scoped>
.log-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
}

.log-textarea {
  margin: 0;
  padding: 0;
}

.log-add-button {
  margin: 0 0 0 auto;
}
</style>
