<template>
  <v-stepper v-model="stepperPosition" height="100%">
    <v-stepper-header>
      <v-stepper-step
        v-for="step in steps"
        :key="step.text"
        :complete="step.isComplete"
        :step="step.step"
        @click="stepperPosition = step.step"
        class="v-stepper__step--editable"
      >
        {{ step.text }}
      </v-stepper-step>
    </v-stepper-header>
    <v-alert :value="isSnapshot" type="warning">
      You're looking at a snapshot of how the filter used to be. To make any
      changes, you'll have to go
      <router-link
        :to="{
          name: $route.name,
          params: { filterId: currentVersionId }
        }"
        >the current version
      </router-link>
      .
    </v-alert>
    <filter-parent v-if="hasParentFilters" />
    <v-container fluid pt-2 pb-0>
      <v-sheet
        v-if="hasCompanyFilterComment && !isSnapshot"
        color="orange lighten-5"
        style="padding: 10px;"
      >
        <v-flex grow>
          <span class="text-uppercase font-weight-bold">
            Company filter remark
          </span>
          {{ companyFilterComment }}
        </v-flex>
      </v-sheet>
    </v-container>
    <!--<v-alert-->
    <!--:value="hasCompanyFilterComment && !isSnapshot"-->
    <!--type="info"-->
    <!--color="secondary"-->
    <!--&gt;-->
    <!--{{ companyFilterComment }}-->
    <!--</v-alert>-->
    <v-stepper-items>
      <v-stepper-content
        v-for="step in steps"
        :key="step.text"
        :step="step.step"
      >
        <component
          :is="step.component"
          v-if="step.isRendered"
          :ref="step.ref"
          :card-class="step.cardClass"
          :card-style="step.cardStyle"
          :valid="step.isValid"
          :warn="step.isWarning"
        />
        <v-layout row>
          <v-btn
            color="primary"
            flat
            :disabled="stepperPosition === 1"
            @click="stepperPosition--"
          >
            Previous
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            :loading="loading"
            :disabled="stepperPosition === steps.length"
            @click="stepperPosition++"
          >
            Next
          </v-btn>
        </v-layout>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isEmpty, isNil } from "lodash";
import FilterType from "./FilterType";
import FilterCategories from "./FilterCategories";
import FilterGeographic from "./FilterGeographic";
import FilterMeta from "./FilterMeta";
import FilterSpecifiers from "./FilterSpecifiers";
import { getHash, getValuesFromURLSearchParams } from "@/api/url";
import FilterParent from "./FilterParents";

const { mapGetters } = createNamespacedHelpers("filter");

export default {
  name: "company-filter",
  components: {
    FilterParent,
    FilterType,
    FilterSpecifiers,
    FilterMeta,
    FilterGeographic,
    FilterCategories
  },
  props: {
    id: {
      type: Number,
      required: false
    },
    companyId: {
      type: Number,
      required: false
    },
    selectableType: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stepperPosition: 1,
      stepperCardStyle: {
        "min-height": "500px"
      },
      stepperCardClass: ["elevation-0", "grey", "lighten-4"],
      isWarning: false
    };
  },
  created() {
    this.applyHash();
  },
  watch: {
    stepperPosition: {
      handler() {
        this.setHash();
      }
    },
    "filter.company": function(newValue, oldValue) {
      if (!isNil(oldValue)) {
        this.filter.deliveryMethods = [];
      }
    }
  },
  computed: {
    ...mapGetters([
      "isSnapshot",
      "currentVersionId",
      "isTypeValid",
      "isGeographyValid",
      "isCategoriesValid",
      "hasParentFilters"
    ]),
    typeStep() {
      return this.selectableType ? 1 : undefined;
    },
    categoriesStep() {
      return this.selectableType ? 2 : 1;
    },
    specifiersStep() {
      return this.selectableType ? 3 : 2;
    },
    geographyStep() {
      return this.selectableType ? 4 : 3;
    },
    metaStep() {
      return this.selectableType ? 5 : 4;
    },
    steps() {
      const steps = [];

      // Type step
      if (this.selectableType) {
        steps.push({
          step: this.typeStep,
          text: "Type",
          ref: "type",
          component: "filter-type",
          cardClass: this.stepperCardClass,
          cardStyle: this.stepperCardStyle,
          isComplete: this.isTypeValid,
          isValid: true,
          isWarning: this.isWarning,
          isRendered: true,
          onReturn: this.returnFromType,
          onContinue: this.continueFromType
        });
      }

      // Categories step
      steps.push({
        step: this.categoriesStep,
        text: "Categories",
        ref: "categories",
        component: "filter-categories",
        cardClass: this.stepperCardClass,
        cardStyle: this.stepperCardStyle,
        isComplete: this.isCategoriesValid,
        isValid: true,
        isWarning: this.isWarning,
        isRendered: this.stepperPosition === this.categoriesStep,
        onReturn: this.returnFromCategories,
        onContinue: this.continueFromCategories
      });

      // Specifiers step
      steps.push({
        step: this.specifiersStep,
        text: "Specifiers (Optional)",
        ref: "specifiers",
        component: "filter-specifiers",
        cardClass: this.stepperCardClass,
        cardStyle: this.stepperCardStyle,
        isComplete: this.isCategoriesValid,
        isValid: true,
        isWarning: this.isWarning,
        isRendered: this.stepperPosition === this.specifiersStep,
        onReturn: this.returnFromSpecifiers,
        onContinue: this.continueFromSpecifiers
      });

      // Geography step
      steps.push({
        step: this.geographyStep,
        text: "Where",
        ref: "geographic",
        component: "filter-geographic",
        cardClass: this.stepperCardClass,
        cardStyle: this.stepperCardStyle,
        isComplete: this.isGeographyValid,
        isValid: true,
        isWarning: this.isWarning,
        isRendered: this.stepperPosition === this.geographyStep,
        onReturn: this.returnFromGeographic,
        onContinue: this.continueFromGeographic
      });

      // Meta step
      steps.push({
        step: this.metaStep,
        text: "About",
        ref: undefined,
        component: "filter-meta",
        cardClass: this.stepperCardClass,
        cardStyle: this.stepperCardStyle,
        isComplete: this.isValid,
        isValid: true,
        isWarning: this.isWarning,
        isRendered: this.stepperPosition === this.metaStep,
        onReturn: this.returnFromMeta,
        onContinue: this.continueFromMeta
      });

      return steps;
    },
    isGeographicValid() {
      return (
        this.filter.isEntireCountry ||
        !isEmpty(this.filter.provinces) ||
        !isEmpty(this.filter.cities) ||
        !isEmpty(this.filter.includedPolygons) ||
        !isEmpty(this.filter.includedCircles)
      );
    },
    isValid() {
      return true;
      //                return this.isCategoriesValid && this.isGeographicValid;
    },
    hasCompanyFilterComment() {
      return !isNil(this.companyFilterComment);
    },
    companyFilterComment() {
      return this.$store.getters["filter/companyFilterComment"];
    }
  },
  methods: {
    init() {},
    setHash() {
      const searchParams = new URLSearchParams();
      searchParams.set("step", this.stepperPosition);
      // Use this approach instead of "window.location.hash = searchParams.toString()", otherwise setting the hash will
      // trigger beforeRouteUpdated.
      history.pushState(
        {},
        null,
        this.$route.path + "#" + searchParams.toString()
      );
    },
    applyHash() {
      const hash = getHash();
      const hashParams = getValuesFromURLSearchParams(
        new URLSearchParams(hash)
      );
      this.stepperPosition = hashParams.step;
    },
    returnFromType() {
      this.$emit("exit");
    },
    continueFromType() {
      if (this.$refs.type[0].validate()) {
        this.stepperPosition = this.categoriesStep;
      }
    },
    returnFromCategories() {
      if (this.selectableType) {
        this.stepperPosition = this.typeStep;
      } else {
        this.$emit("exit");
      }
    },
    continueFromCategories() {
      this.stepperPosition = this.specifiersStep;
    },
    returnFromSpecifiers() {
      this.stepperPosition = this.categoriesStep;
    },
    continueFromSpecifiers() {
      this.stepperPosition = this.geographyStep;
    },
    returnFromGeographic() {
      this.stepperPosition = this.specifiersStep;
    },
    continueFromGeographic() {
      this.stepperPosition = this.metaStep;
    },
    returnFromMeta() {
      this.stepperPosition = this.geographyStep;
    },
    continueFromMeta() {
      if (this.isValid) {
        this.syncFilter();
      } else {
        this.isWarning = true;
      }
    },
    async syncFilter() {
      this.$emit("save");
    }
  }
};
</script>
