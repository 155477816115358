<template>
  <v-card ma-0>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Notities
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        depressed
        small
        color="primary lighten-2"
        @click="updateNotes"
        :loading="loading"
        :disabled="loading"
      >
        Save
      </v-btn>
    </v-toolbar>
    <div class="notes-container">
      <v-textarea
        v-model="notes"
        box
        class="notes-textarea"
        name="notes"
        auto-grow
        label="Notitie"
      />
    </div>
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
const { mapComputed } = createNamespacedHelpers("company");
import api from "@/api";
import { SET_MODEL } from "@/store/templates/crud/mutation-types";

const updateNotesCall = api.company.updateNotes;

export default {
  name: "company-details-notes",
  data() {
    return {
      updateNotesFunction: updateNotesCall,
      loading: false
    };
  },
  computed: {
    ...mapComputed(["company", "notes"])
  },
  methods: {
    async updateNotes() {
      this.loading = true;
      const { entities, result } = await this.updateNotesFunction(
        this.company.id,
        this.notes
      );
      this.$store.commit(`company/${SET_MODEL}`, { entities, id: result });
      this.loading = false;
    }
  }
};
</script>
<style scoped>
.notes-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
}
.notes-textarea {
  margin: 0;
  padding: 0;
}
</style>
