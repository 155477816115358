<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      autocomplete="not-my-price"
      :counter="255"
      :error-messages="errors.collect('name')"
      data-vv-name="name"
      box
      name="name"
      label="name"
      required
    />
    <v-select
      v-model="type"
      v-validate="'required'"
      :items="types"
      label="Type"
      :error-messages="errors.collect('type')"
      data-vv-name="type"
      required
    />
    <v-select
      v-model="recipients"
      v-validate="'required'"
      :items="contactPersons"
      item-text="name"
      item-value="id"
      label="Contact persons"
      :error-messages="errors.collect('type')"
      data-vv-name="Contactpersons"
      chips
      required
      multiple
    >
      <template slot="selection" slot-scope="data">
        <v-chip
          :selected="data.selected"
          :key="JSON.stringify(data.item)"
          class="chip--select-multi"
          @input="data.parent.selectItem(data.item)"
        >
          {{ data.item.name }} ({{ data.item.email }})
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        {{ data.item.name }} ({{ data.item.email }})
      </template>
    </v-select>
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";

import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import CanValidate from "@/components/mixins/validation/CanValidate";
const { mapComputed: mapDeliveryMethodComputed } = createNamespacedHelpers(
  "deliveryMethod"
);

export default {
  name: "company-delivery-method-form",
  inject: ["$validator"],
  mixins: [CanValidate],
  data: () => ({
    contactPersonIds: [],
    types: [
      { text: "Mail", value: "MAIL" },
      { text: "CSV mail", value: "CSV_MAIL" },
      { text: "Api", value: "API", disabled: true }
    ]
  }),
  created() {
    this.getContactPersons();
  },
  computed: {
    ...mapDeliveryMethodComputed([
      "companyId",
      "name",
      "type",
      "recipients",
      "contactPersons"
    ])
  },
  methods: {
    async getContactPersons() {
      this.contactPersonIds = await this.$store.dispatch(
        `deliveryMethod/contactPersons/${DISPATCH_FETCH}`
      );
    },
    text: item => item.name + " — " + item.email
  }
};
</script>
