<template>
  <v-layout row pa-4>
    <v-flex xs4>
      <company-user-data v-if="isCustomer" class="ma-2 mb-4" />
      <company-details-company-data class="ma-2 mb-4" />
      <company-details-fiscal-data class="ma-2 mb-4" />
      <company-details-contact-persons class="ma-2" />
    </v-flex>
    <v-flex xs4>
      <company-details-sales-data v-if="isCustomer" class="ma-2 mb-4" />
      <company-details-customer v-if="isCustomer" class="ma-2 mb-4" />
      <company-make-customer v-if="!isCustomer" class="ma-2 mb-4" />
      <company-details-facebook-feed v-if="hasFacebookLinks" class="ma-2" />
    </v-flex>
    <v-flex xs4>
      <company-details-notes class="ma-2 mb-4" />
      <company-details-logs class="ma-2" />
    </v-flex>
    <company-reset-password />
  </v-layout>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("company");
import CompanyDetailsCompanyData from "@/components/company/details/CompanyDetailsCompanyData";
import CompanyDetailsFiscalData from "@/components/company/details/CompanyDetailsFiscalData";
import CompanyDetailsContactPersons from "@/components/company/details/CompanyDetailsContactPersons";
import CompanyDetailsSalesData from "@/components/company/details/CompanyDetailsSalesData";
import CompanyDetailsLogs from "@/components/company/details/CompanyDetailsLogs";
import CompanyDetailsNotes from "@/components/company/details/CompanyDetailsNotes";
import CompanyDetailsFacebookFeed from "@/components/company/details/CompanyDetailsFacebookFeed";
import CompanyMakeCustomer from "@/components/company/details/CompanyMakeCustomer";
import CompanyResetPassword from "@/components/company/CompanyResetPassword";
import CompanyUserData from "@/components/company/details/CompanyUserData";
import CompanyDetailsCustomer from "../../components/company/details/CompanyDetailsCustomer";

export default {
  name: "company-details-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CompanyDetailsCustomer,
    CompanyResetPassword,
    CompanyUserData,
    CompanyMakeCustomer,
    CompanyDetailsFacebookFeed,
    CompanyDetailsNotes,
    CompanyDetailsLogs,
    CompanyDetailsSalesData,
    CompanyDetailsContactPersons,
    CompanyDetailsFiscalData,
    CompanyDetailsCompanyData
  },
  computed: {
    ...mapGetters(["company", "facebookLinks", "isCustomer"]),
    hasFacebookLinks() {
      return this.facebookLinks.length;
    }
  }
};
</script>
