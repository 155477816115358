<template>
  <tr>
    <td>{{ deliveryMethod.type }}</td>
    <td>{{ deliveryMethod.name }}</td>
    <td>
      <v-chip
        v-for="(recipient, index) in maxRecipients"
        :key="index"
        label
        small
      >
        {{ recipient.name }}
      </v-chip>
      <v-tooltip v-if="recipients.length > maxChips" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <v-chip v-for="(recipient, index) in recipients" :key="index" label>
            {{ recipient.name }}
          </v-chip>
        </span>
      </v-tooltip>
    </td>
    <td>
      <v-chip v-for="(filter, index) in maxFilters" :key="index" label small>
        {{ filter }}
      </v-chip>
      <v-tooltip v-if="deliveryMethod.filterNames.length > maxChips" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <v-chip v-for="(filter, index) in filters" :key="index" label>
            {{ filter }}
          </v-chip>
        </span>
      </v-tooltip>
    </td>
    <td>{{ deliveryMethod.url }}</td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'company.delivery-method.show',
          params: { deliveryMethodId: deliveryMethod.id }
        }"
      >
        <v-icon>edit</v-icon>
      </v-btn>
    </td>
    <td>
      <v-btn icon @click="$emit('delete', deliveryMethod.id)">
        <v-icon>delete</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const {
  mapGetters: mapDeliveryMethodGetters,
  mapState: mapDeliveryMethodState
} = createNamespacedHelpers("company/deliveryMethods");

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "company-delivery-method-table-row",
  props: {
    deliveryMethodId: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      maxChips: 5,
      editDialog: false
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapDeliveryMethodGetters([FIND_BY_ID]),
    ...mapDeliveryMethodState(["company/deliveryMethod"]),
    deliveryMethod() {
      return this[FIND_BY_ID](this.deliveryMethodId);
    },
    recipients() {
      return this.$store.getters["company/deliveryMethods/recipients"](
        this.deliveryMethodId
      );
    },
    maxRecipients() {
      return this.recipients.slice(0, this.maxChips);
    },
    maxFilters() {
      return this.deliveryMethod.filterNames.slice(0, this.maxChips);
    }
  }
};
</script>
