<template>
  <v-card ma-0>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Contactpersonen
      </v-toolbar-title>
      <v-spacer />
      <company-edit-contact-persons />
    </v-toolbar>

    <div class="contact-persons-container">
      <div
        v-for="contactPerson in contactPersons"
        :key="contactPerson.id"
        class="contact-person"
      >
        <span>
          <h4>{{ contactPerson.firstName }} {{ contactPerson.lastName }}</h4>
          <template v-if="contactPerson.filterCount"
            >({{ contactPerson.filterCount }}
            {{
              contactPerson.filterCount > 1 ? "filters" : "filter"
            }})</template
          >
        </span>
        <h4 class="align-right">
          {{ contactPerson.position }}
        </h4>
        <h5>{{ contactPerson.email }}</h5>
        <h5 class="align-right">
          {{ contactPerson.phoneNumber }}
        </h5>
      </div>
    </div>
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CompanyEditContactPersons from "@/components/company/edit/CompanyEditContactPersons";
const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-details-contact-persons",
  components: { CompanyEditContactPersons },
  computed: {
    ...mapGetters(["contactPersons"])
  }
};
</script>
<style scoped>
.contact-persons-container {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  color: #1a1a1a;
}
.contact-person {
  display: grid;
  grid-template-columns: auto auto;
}

.contact-person h4 {
  font-weight: 600;
  display: inline;
  margin-right: 1em;
}
.align-right,
h4.align-right {
  justify-self: end;
  margin-right: 0;
}
.contact-person h5 {
  font-weight: 500;
  font-size: 14px;
}
</style>
