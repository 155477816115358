import tableMutations from "@/store/templates/table/mutations";
import { SET_ITEMS } from "../../../templates/table/mutation-types";
import { isNil } from "lodash";
import Vue from "vue";
import { setEntities } from "../../../templates/entities";

export default {
  ...tableMutations,
  [SET_ITEMS](state, { ids, entities, meta }) {
    if (!isNil(meta)) {
      Vue.set(state, "totalAmount", meta.total_amount);
      Object.assign(state.pagination, {
        totalItems: meta.total,
        page: meta.current_page,
        rowsPerPage: parseInt(meta.per_page)
      });
    } else {
      // @TODO find good implementation for store that do client side filtering/pagination
      Object.assign(state.pagination, {
        totalItems: ids.length,
        page: 1,
        rowsPerPage: 20
      });
    }
    if (!isNil(entities.entity)) {
      setEntities(state, entities, true);
    }
    state.ids = ids;
  }
};
