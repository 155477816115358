<template>
  <v-layout
    align-center
    :class="{
      'odd-excluded': question.isExcluded && odd,
      'even-excluded': question.isExcluded && !odd,
      'grey lighten-3': !question.isExcluded && odd
    }"
  >
    <v-flex xs2 md1>
      <v-checkbox
        v-model="enabled"
        :disabled="question.isExcluded"
        hide-details
        class="shrink mr-2"
      />
    </v-flex>
    <v-flex xs10 md6>
      <v-layout row wrap>
        <v-flex xs12 :class="{ 'grey--text': disabled }">
          {{ question.label.nl_be }}
          <v-chip label small color="warning" dark v-if="question.isExcluded">
            EXCLUDED
          </v-chip>
        </v-flex>
        <v-flex v-for="(option, i) in question.options" :key="i" xs12 md6>
          <v-checkbox
            v-model="specifierOptions"
            :label="option.label.nl_be"
            :value="option.id"
            :disabled="!enabled"
            hide-details
            class="mt-0"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 md5>
      <v-select
        v-model="specifierType"
        :items="questionSpecifierTypes"
        :disabled="!enabled"
        label="Type"
      />
    </v-flex>
  </v-layout>
</template>
<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "filter-specifier-question",
  props: {
    specifier: {
      type: Object,
      required: false
    },
    question: {
      type: Object,
      required: true
    },
    odd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      questionSpecifierType: "ANY"
    };
  },
  computed: {
    disabled() {
      return !this.enabled || this.question.isExcluded;
    },
    enabled: {
      get() {
        return !isNil(this.specifier);
      },
      set(value) {
        this.$emit(`specifier-${value ? "add" : "remove"}`, {
          question: this.question
        });
      }
    },
    specifierOptions: {
      get() {
        return this.enabled ? this.specifier.options : [];
      },
      set(value) {
        this.$emit("specifier-update", {
          type: this.specifier.type,
          options: value
        });
      }
    },
    specifierType: {
      get() {
        return this.enabled ? this.specifier.type : null;
      },
      set(value) {
        this.$emit("specifier-update", {
          type: value,
          options: this.specifier.options
        });
      }
    },
    options() {
      return this.$store.getters["entities/getQuestionOptionsByIds"]({
        ids: this.question.options
      });
    },
    questionSpecifierTypes() {
      return [
        { text: "Any", value: "ANY" },
        { text: "At least", value: "AT_LEAST" },
        { text: "Exact", value: "EXACT" },
        { text: "Except", value: "EXCEPT" }
      ];
    }
  }
};
</script>
<style scoped>
.odd-excluded {
  background: repeating-linear-gradient(
    45deg,
    #eee,
    #eee 10px,
    #ffcd00 10px,
    #ffcd00 20px
  );
}
.even-excluded {
  background: repeating-linear-gradient(
    45deg,
    #fff,
    #fff 10px,
    #ffcd00 10px,
    #ffcd00 20px
  );
}
</style>
