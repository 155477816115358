<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "submission-lead-offer-mixin",
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  computed: {
    state() {
      if (this.isRejected) {
        return "REJECTED";
      } else if (this.isRejectionRequested && !this.isRejectionDenied) {
        return "REJECTION_REQUESTED";
      } else if (this.isDistributed) {
        return "DISTRIBUTED";
      }
      return "UNSENT";
    },
    contextColor() {
      if (this.isRejected) {
        return "error";
      } else if (this.isRejectionRequested && !this.isRejectionDenied) {
        return "warning";
      } else if (this.isDistributed) {
        return "primary";
      }
      return "grey darken-2";
    },
    contextDark() {
      if (this.isRejected) {
        return true;
      } else if (this.isRejectionRequested) {
        return true;
      } else if (this.isDistributed) {
        return true;
      }
      return true;
    },
    isDistributed() {
      return !isNil(this.offer.distributedAt);
    },
    isRejectionRequested() {
      return !isNil(this.offer.rejectionRequestedAt);
    },
    isRejectionDenied() {
      return !isNil(this.offer.rejectionDeniedAt);
    },
    isRejected() {
      return !isNil(this.offer.rejectedAt);
    },
    isSoftRejected() {
      return !isNil(this.offer.softRejectedAt);
    }
  }
};
</script>
