<template>
  <div :class="[headerWrapperClass, 'pa-3']">
    <div class="general-info">
      <v-icon class="invoice-icon" color="black">fad fa-file-invoice</v-icon>
      <div class="invoiced-info">
        <div class="left-info">
          <h1 class="text-uppercase">
            <mark :class="[markClass]">{{ invoice.state }} INVOICE</mark>
            {{ hasAcceptanceRate ? ` - ${offerAcceptanceRate}% AR` : null }}
          </h1>
          <h1 class="text-uppercase">
            {{ company.name }} -
            <mark :class="[markClass]">{{ company.customerNumber }}</mark>
            <mark class="blacklisted" v-if="company.state === 'BLACKLISTED'">
              !BLACKLISTED!</mark
            >
          </h1>
          <h1 class="text-uppercase">{{ company.billingData.vatNumber }}</h1>
        </div>
        <div class="text-xs-right">
          <p class="ma-1" v-for="(auditTrail, i) in auditTrails" :key="i">
            {{ auditTrail }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
import moment from "moment";
import { isNil } from "lodash";
const { mapGetters } = VuexNamespacedHelpers("invoice");

export default {
  name: "invoice-header",
  components: {},
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapGetters([
      "invoice",
      "company",
      "state",
      "draftedByFullName",
      "createdAt",
      "openedByFullName",
      "openedAt",
      "paidByFullName",
      "paidAt",
      "type",
      "offerAcceptanceRate"
    ]),
    auditTrails() {
      let auditTrails = [];
      if (this.state === "DRAFT" || this.state === "OPEN") {
        auditTrails.push(
          `Drafted @ ${this.formatDate(this.createdAt)} by ${
            this.type === "AUTO" ? "the system" : this.draftedByFullName
          }`
        );
      }
      if (this.state === "OPEN") {
        auditTrails.push(
          `Finalized @ ${this.formatDate(this.openedAt)} by ${
            isNil(this.openedByFullName) ? "the system" : this.openedByFullName
          }`
        );
      }
      return auditTrails;
    },
    headerWrapperClass() {
      if (this.state === "DRAFT") {
        return "header-wrapper-draft";
      } else if (this.state === "OPEN") {
        return "header-wrapper-open";
      } else {
        return "header-wrapper-paid";
      }
    },
    markClass() {
      if (this.state === "DRAFT") {
        return "mark-draft";
      } else if (this.state === "OPEN") {
        return "mark-open";
      } else {
        return "mark-paid";
      }
    },
    hasAcceptanceRate() {
      return !isNil(this.offerAcceptanceRate);
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    }
  }
};
</script>
<style scoped>
.header-wrapper-draft {
  background-color: #e0e0e0 !important;
}
.header-wrapper-open {
  background-color: #e3f2fd !important;
}
.header-wrapper-paid {
  background-color: var(--v-success-lighten1) !important;
}
.mark-draft {
  color: black !important;
}
.mark-open {
  color: #027dd6 !important;
}
.mark-paid {
  color: var(--v-success-base) !important;
}
.general-info {
  display: grid;
  grid-template-columns: 3% 97%;
  grid-template-rows: 100%;
}
.invoice-icon {
  grid-column-start: 1;
  justify-self: center;
  align-self: center;
  font-size: 36px;
}
.invoiced-info {
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  display: grid;
}
.invoiced-info h1 {
  line-height: 0.9;
  font-size: 20px;
  font-weight: 600;
}
.blacklisted {
  color: red;
}
mark {
  background-color: unset;
}
</style>
