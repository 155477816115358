<template>
  <div class="category-table-container">
    <table-overview
      title="Delivery Methods"
      store="company/deliveryMethods"
      :headers="[
        { text: 'Type', value: 'type' },
        { text: 'Name', value: 'name' },
        { text: 'Recipients', sortable: false, value: 'recipients' },
        { text: 'Filters', sortable: false, value: 'filterNames' },
        { text: 'Url', sortable: false, value: 'url' },
        {
          text: 'Edit',
          sortable: false,
          value: 'edit',
          align: 'center',
          width: '100px'
        },
        {
          text: 'Delete',
          sortable: false,
          value: 'delete',
          align: 'center',
          width: '100px'
        }
      ]"
      :actions="actions"
    >
      <v-progress-linear slot="progress" indeterminate :height="3" />
      <company-delivery-method-table-row
        slot="table-row"
        slot-scope="{ item }"
        :delivery-method-id="item"
        @delete="openDeleteDialog"
      />
    </table-overview>

    <company-delivery-method-delete-dialog
      @success="getDeliveryMethods"
      v-if="deleteDialog"
      :dialog.sync="deleteDialog"
      :delivery-method-id="deleteDeliveryMethodId"
    />
  </div>
</template>
<script type="text/babel">
import CompanyDeliveryMethodTableRow from "@/components/company/delivery-methods/CompanyDeliveryMethodTableRow";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import TableOverview from "@/components/generic/overview/TableOverview";
import CompanyDeliveryMethodDeleteDialog from "@/components/company/delivery-methods/CompanyDeliveryMethodDeleteDialog";

export default {
  name: "company-categories-view",
  components: {
    CompanyDeliveryMethodDeleteDialog,
    TableOverview,
    CompanyDeliveryMethodTableRow
  },
  data() {
    return {
      deleteDialog: false,
      deleteDeliveryMethodId: null,
      actions: [
        {
          icon: "far fa-fw fa-plus",
          color: "pink",
          to: {
            name: "company.delivery-method.create"
          },
          rolesOrPermissions: "delivery_methods_create"
        }
      ]
    };
  },
  created() {
    this.getDeliveryMethods();
  },
  methods: {
    async getDeliveryMethods() {
      await this.$store.dispatch(`company/deliveryMethods/${DISPATCH_FETCH}`);
    },
    async openDeleteDialog(id) {
      this.deleteDeliveryMethodId = id;
      this.deleteDialog = true;
    }
  }
};
</script>
<style scoped>
.category-table-container {
  /*max-width: 800px;*/ /*looks weird either way*/
  margin: 24px;
}
</style>
