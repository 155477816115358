<template>
  <tr :class="{ 'warning lighten-3': company.paused }">
    <td>
      {{ company.name }}
      <small v-if="company.paused"> (paused)</small>
    </td>
    <td>{{ company.vatNumber }}</td>
    <td>{{ country.name }}</td>
    <td>{{ language.name }}</td>
    <td>{{ statuses[company.status] }}</td>
    <td>
      <v-chip
        v-for="(category, index) in maxCategories"
        :key="index"
        label
        small
      >
        {{ category.name.nl_be }}
      </v-chip>
      <v-tooltip v-if="categories.length > maxChips" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <v-chip v-for="(category, index) in categories" :key="index" label>
            {{ category.name.nl_be }}
          </v-chip>
        </span>
      </v-tooltip>
    </td>
    <td>
      <v-btn
        @click="generateMagicLink(primaryCustomer.id)"
        icon
        v-if="company.isCustomer && !magiclink"
      >
        <v-icon>far fa-user</v-icon>
      </v-btn>
      <a
        class="v-btn v-btn--icon theme--light"
        v-if="magiclink"
        :href="magiclink"
        ><v-icon>far fa-magic</v-icon></a
      >
    </td>
    <td>
      <v-btn
        icon
        :to="{ name: 'company.show', params: { companyId: company.id } }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import api from "@/api";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapCompanyGetters } = createNamespacedHelpers("companies");

export default {
  name: "company-table-row",
  props: {
    companyId: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      maxChips: 3,
      magiclink: undefined
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapCompanyGetters([FIND_BY_ID]),
    statuses() {
      return window.config.enums.company_status;
    },
    primaryCustomer() {
      return this.$store.getters["companies/primaryCustomer"](this.companyId);
    },
    company() {
      return this[FIND_BY_ID](this.companyId);
    },
    country() {
      return this.$store.getters["companies/country"](this.companyId);
    },
    language() {
      return this.$store.getters["companies/language"](this.companyId);
    },
    categories() {
      return this.$store.getters["companies/categories"](this.companyId);
    },
    maxCategories() {
      return this.categories.slice(0, this.maxChips);
    }
  },
  methods: {
    async generateMagicLink(customerId) {
      this.magiclink = await api.customer.magicLink(customerId);
    }
  }
};
</script>
