<template>
  <div>
    <v-progress-linear
      v-if="isLoadingOffers"
      class="ma-0"
      color="accent"
      indeterminate
      :height="3"
    />
    <v-data-table :items="offers" :rows-per-page-items="[20, 50, 100]">
      <invoice-create-item-offers-table-row
        slot="items"
        slot-scope="props"
        :offer-id="props.item"
        :is-odd="props.index % 2 !== 0"
      />
    </v-data-table>
    <div class="button-wrapper">
      <v-btn
        v-if="offers.length > 0"
        depressed
        color="primary"
        class="ma-0 mr-3"
        :disabled="isLoadingOffers || isUpdatingItem"
        @click="createItemsFromOffers"
        >Create item(s) from selected offers</v-btn
      >
      <v-btn
        :disabled="isUpdatingItem"
        depressed
        color="grey"
        class="ma-0"
        @click="cancel"
        >Cancel</v-btn
      >
    </div>
    <confirm-item-creation-dialog
      :amount-rejectable-offers="amountOfOffersInRejectablePeriod"
      :amount-rejection-requested-offers="amountOfOffersWithOpenRejectRequests"
      :show-dialog="confirmItemCreationDialog"
      @close="confirmItemCreationDialog = false"
      @items-created="cancel"
    />
  </div>
</template>

<script type="text/babel">
import InvoiceCreateItemOffersTableRow from "./InvoiceCreateItemOffersTableRow";
import { isNil } from "lodash";
import moment from "moment";
import ConfirmItemCreationDialog from "./ConfirmItemCreationDialog";

export default {
  name: "invoice-create-item-offers",
  components: { ConfirmItemCreationDialog, InvoiceCreateItemOffersTableRow },
  data: () => ({
    confirmItemCreationDialog: false
  }),
  computed: {
    offers() {
      return this.$store.getters["invoice/invoiceableOffers"];
    },
    offerEntities() {
      return this.$store.getters["invoice/offersById"](this.offers);
    },
    isLoadingOffers() {
      return this.$store.getters["invoice/isLoadingOffers"];
    },
    companyRejectableTerm() {
      return this.$store.getters["invoice/companyRejectableTerm"];
    },
    amountOfOffersInRejectablePeriod() {
      return this.offerEntities.filter(offer => !this.isRejectionExpired(offer))
        .length;
    },
    amountOfOffersWithOpenRejectRequests() {
      return this.offerEntities.filter(offer => offer.isRejectionRequested)
        .length;
    },
    isUpdate() {
      return !isNil(this.$store.state.invoice.model.id);
    },
    isUpdatingItem() {
      return this.$store.getters["invoice/isUpdatingItem"];
    }
  },
  methods: {
    async createItemsFromOffers() {
      if (
        this.amountOfOffersInRejectablePeriod > 0 ||
        this.amountOfOffersWithOpenRejectRequests
      ) {
        this.confirmItemCreationDialog = true;
      } else {
        if (this.isUpdate) {
          this.$store.commit("invoice/setUpdatingItem", true);
          await this.$store.dispatch("invoice/addOfferItemsToInvoice");
          this.$store.commit("invoice/setUpdatingItem", false);
          this.$emit("items-created");
        } else {
          this.$store.commit("invoice/createItemsFromOffers");
          this.$emit("items-created");
        }
      }
    },
    cancel() {
      this.$emit("items-created");
    },
    isRejectionExpired(offer) {
      if (!isNil(this.companyRejectableTerm)) {
        const maxRejectionDate = moment().subtract(
          this.companyRejectableTerm,
          "days"
        );
        console.log(maxRejectionDate);
        return moment(offer.distributedAt) < maxRejectionDate;
      } else {
        return offer.isRejectionExpired;
      }
    }
  }
};
</script>
<style scoped>
>>> .v-table__overflow table thead {
  display: none !important;
}
>>> .v-table__overflow table tbody tr {
  border-bottom: none !important;
}
.button-wrapper {
  position: relative;
  bottom: 50px;
  width: 25%;
}
>>> .v-table__overflow table {
  overflow: hidden;
}
</style>
