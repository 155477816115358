<template>
  <v-card ma-0>
    <v-toolbar color="light-blue" class="primary" dense>
      <v-toolbar-title class="white--text">
        Salesgegevens
      </v-toolbar-title>
      <v-btn
        depressed
        small
        :disabled="isLoading"
        :loading="isLoading"
        class="update-button"
        color="primary lighten-2"
        @click="updateSalesData"
      >
        Update
      </v-btn>
    </v-toolbar>

    <div class="sales-data-container">
      <h4>Laatste update</h4>
      <h5 class="align-right">
        {{ updatedAt }}
      </h5>
      <h4>Gemiddelde aantal lead /maand</h4>
      <h5 class="align-right">
        {{ company.averageAmountOfLeadsPerMonth }}
      </h5>
      <h4>Afkeurratio</h4>
      <h5 class="align-right">{{ company.offerRejectionRatio }}%</h5>
      <h4>Acceptatieratio</h4>
      <h5 class="align-right">{{ company.acceptanceRate }}%</h5>
      <h4>Gemiddelde prijs / lead</h4>
      <h5 class="align-right">€{{ company.averagePricePerOffer }}</h5>
      <h4>Totaal gefactureerd</h4>
      <h5 class="align-right">€{{ company.totalBilled }}</h5>
    </div>
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("company");

export default {
  name: "company-details-sales-data",
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapGetters(["company"]),
    updatedAt() {
      return new Date(`${this.company.salesDataUpdatedAt} UTC`)
        .toString()
        .slice(0, 25);
    }
  },
  methods: {
    async updateSalesData() {
      this.isLoading = true;
      await this.$store
        .dispatch("company/updateSalesData", this.company.id)
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
<style scoped>
.sales-data-container {
  padding: 20px;
  display: grid;
  color: #1a1a1a;
  grid-template-columns: auto auto;
}
.align-right {
  justify-self: end;
}
.contact-person h4 {
  font-weight: 600;
}
.contact-person h5 {
  font-weight: 500;
  font-size: 14px;
}
.update-button {
  margin: 0 0 0 auto;
}
</style>
