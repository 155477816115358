<template>
  <tr>
    <td>
      <v-icon v-if="hasCreditNotes" small>fad fa-file-invoice-dollar</v-icon>
      {{ invoice.number }}
    </td>
    <td v-if="!isCompanyView">
      {{ company.fiscalName }}
    </td>
    <td v-if="!isCompanyView">
      {{ company.name }}
    </td>
    <td class="text-xs-right">
      {{ invoice.referenceNumber }}
    </td>
    <td class="text-xs-right">
      {{ invoice.offerCount }}
    </td>
    <td class="text-xs-right">
      {{
        invoice.offerAcceptanceRate !== null
          ? `${invoice.offerAcceptanceRate} %`
          : null
      }}
    </td>
    <td class="text-xs-right">
      {{ month }}
    </td>
    <td class="text-xs-right">
      {{ dueAt }}
    </td>
    <td class="text-xs-right">{{ formatPrice(invoice.amount) }}</td>
    <td class="text-xs-right">
      {{ formatPrice(invoice.totalAmount) }}
    </td>
    <td class="text-xs-right">
      {{ formatPrice(invoice.balance) }}
    </td>
    <td class="px-0 actions">
      <v-btn
        :disabled="!downloadable"
        icon
        @click="downloadPdf(invoice.number)"
      >
        <v-icon>fas fa-file-pdf</v-icon>
      </v-btn>
      <v-btn
        v-if="hasRolesOrPermissions('invoice_view')"
        icon
        :to="detailsRoute"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import moment from "moment";
import { isNil } from "lodash";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapInvoiceGetters } = createNamespacedHelpers("invoices");

export default {
  name: "invoice-table-row",
  props: {
    invoiceId: {
      type: Number,
      required: true
    },
    companyId: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapInvoiceGetters([FIND_BY_ID]),
    isCompanyView() {
      return !isNil(this.companyId);
    },
    invoice() {
      return this.isCompanyView
        ? this.$store.getters[`company/invoices/${FIND_BY_ID}`](this.invoiceId)
        : this.$store.getters[`invoices/${FIND_BY_ID}`](this.invoiceId);
    },
    hasCreditNotes() {
      return this.invoice.creditNotes.length > 0;
    },
    month() {
      return isNil(this.invoice.dueAt)
        ? null
        : moment(this.invoice.invoicedAt).format("DD-MM-YYYY");
    },
    dueAt() {
      return isNil(this.invoice.dueAt)
        ? null
        : moment(this.invoice.dueAt).format("DD-MM-YYYY");
    },
    company() {
      return this.isCompanyView
        ? this.$store.getters["company/company"]
        : this.$store.getters["invoices/company"](this.invoice.company);
    },
    downloadable() {
      return this.invoice.state !== "DRAFT" && this.pdfDownloadable;
    },
    detailsRoute() {
      return this.isCompanyView
        ? {
            name: "company.invoices.show",
            params: {
              companyId: this.invoice.company,
              invoiceId: this.invoiceId
            }
          }
        : {
            name: "invoices.show",
            params: {
              invoiceId: this.invoiceId
            }
          };
    },
    pdfDownloadable() {
      return this.isCompanyView
        ? this.$store.getters["company/invoices/invoicePdfDownloadable"](
            this.invoiceId
          )
        : this.$store.getters["invoices/invoicePdfDownloadable"](
            this.invoiceId
          );
    }
  },
  mounted() {
    window.Echo.connector.pusher.config.auth.headers["Authorization"] =
      "Bearer " + this.$store.getters["auth/accessToken"];
    window.Echo.private(`Invoice.${this.invoiceId}`).listen(
      ".invoice.pdf.generated",
      e => {
        console.log(e);
        this.$store.commit("invoices/setPdfDownloadable", this.invoiceId);
      }
    );
  },
  methods: {
    async downloadPdf(number) {
      const route = this.isCompanyView
        ? "company/invoices/pdf"
        : "invoices/pdf";
      await this.$store.dispatch(route, {
        number: number,
        type: "OVERVIEW",
        locale: "nl"
      });
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    }
  }
};
</script>
<style scoped>
.actions {
  display: grid;
  grid-template-columns: auto auto;
}
</style>
