<script>
import { isNil, isFunction } from "lodash";

export default {
  name: "validatable-mixin",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  data: () => ({
    loading: false
  }),
  methods: {
    validate(successCallback, failureCallback = null) {
      this.errors.clear();
      this.$validator.validateAll().then(async isEverythingValid => {
        if (isEverythingValid) {
          try {
            await successCallback();
          } catch (err) {
            this.handleServerSideValidationErrors(err);
          }
        } else {
          if (!isNil(failureCallback) && isFunction(failureCallback)) {
            failureCallback();
          } else {
            this.focusOnFirstError();
          }
        }
      });
    },
    handleServerSideValidationErrors(err) {
      if (!isNil(err.errors)) {
        // Add all server side validation errors to the error bag.
        Object.entries(err.errors).forEach(([field, fieldErrors]) =>
          fieldErrors.forEach(msg =>
            this.errors.add({
              field,
              msg
            })
          )
        );
        this.loading = false;
        this.focusOnFirstError();
      }
    },
    focusOnFirstError() {
      if (this.errors.items.length > 0) {
        const fieldName = this.errors.items[0].field
          // eslint-disable-next-line
          .replace(/\[]/g, "\\[\\]")
          // eslint-disable-next-line
          .replace(/\./g, "\\.");
        const selector = `input[data-vv-name=${fieldName}], textarea[data-vv-name=${fieldName}]`;
        document.querySelector(selector).focus();
      }
    }
  }
};
</script>
