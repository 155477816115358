<template>
  <div class="pa-3">
    <invoice-create-header :company-id="companyId" />
    <invoice-create-items @savable="savable" />
    <invoice-create-totals />
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
const { mapComputed } = createNamespacedHelpers("invoice");
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
import InvoiceCreateItems from "./InvoiceCreateItems";
import InvoiceCreateHeader from "./InvoiceCreateHeader";
import InvoiceCreateTotals from "./InvoiceCreateTotals";
const { mapGetters } = VuexNamespacedHelpers("invoice");

export default {
  name: "invoice-create",
  components: { InvoiceCreateTotals, InvoiceCreateHeader, InvoiceCreateItems },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapComputed([]),
    ...mapGetters([])
  },
  methods: {
    savable(val) {
      this.$emit("savable", val);
    }
  }
};
</script>
