<template>
  <table-overview
    title="Companies"
    icon="business"
    store="companies"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'VAT', value: 'vatNumber', sortable: false },
      { text: 'Country', value: 'country_id' },
      { text: 'Language', value: 'language' },
      { text: 'Status', value: 'status' },
      { text: 'Categories', value: 'categories', sortable: false },
      {
        text: 'Impersonate',
        align: 'right',
        value: 'name',
        sortable: false,
        width: '100px'
      },
      {
        text: 'Actions',
        align: 'right',
        value: 'name',
        sortable: false,
        width: '100px'
      }
    ]"
    :actions="actions"
    force-initial-sort
    searchable
  >
    <v-layout slot="toolbar-additional-search" justify-end>
      <v-flex xs6>
        <v-checkbox
          v-model="isReseller"
          label="Reseller"
          color="accent"
          hide-details
        />
      </v-flex>
    </v-layout>
    <company-table-row
      slot="table-row"
      slot-scope="{ item }"
      :company-id="item"
    />

    <companies-view-filters slot="filters" store="companies" />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "@/components/generic/overview/TableOverview";
import { createNamespacedHelpers as createNamespacedHelpersFromVuex } from "vuex";
import companiesModule from "@/store/modules/companies";
import CompanyTableRow from "@/components/company/CompanyTableRow";
import { mapOverviewLifecycleMethods } from "@/views/helpers";
import { createNamespacedHelpers } from "@/store/helpers";
import { FETCH } from "@/store/templates/table/action-types";
import CompaniesViewFilters from "@/components/company/CompaniesViewFilter";

const { mapGetters } = createNamespacedHelpersFromVuex("company");
const { mapComputedFilters } = createNamespacedHelpers("companies");
const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "companies",
  companiesModule
);

export default {
  name: "companies-view",
  components: {
    CompaniesViewFilters,
    CompanyTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "companies.create" },
        rolesOrPermissions: "company_create"
      }
    ]
  }),
  computed: {
    ...mapGetters(["company"]),
    ...mapComputedFilters(["isReseller"])
  },
  watch: {
    async isCustomer() {
      await this.$store.dispatch(`companies/${FETCH}`);
    },
    async isReseller() {
      await this.$store.dispatch(`companies/${FETCH}`);
    }
  },
  ...overviewLifecycleMethods
};
</script>
