import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import { SET_FILTERS } from "@/store/templates/table/mutation-types";
import tableActions from "@/store/templates/table/actions";

const fetchCall = api.company.fetchAll;

export default {
  ...tableActions,
  async isUnique({ commit, dispatch }, filters) {
    await commit(SET_FILTERS, { filters });
    return await dispatch(DISPATCH_FETCH);
  },
  async [DISPATCH_FETCH](context) {
    return await tableActions.fetch(context, { fetchCall });
  }
};
