import crudMutations from "@/store/templates/crud/mutations";
import { SET_MODEL } from "@/store/templates/crud/mutation-types";
import { setEntities } from "@/store/templates/entities";
import { clone, cloneDeep, isNil } from "lodash";
import Vue from "vue";

export default {
  setIsFiscalDifferent(state, isDifferent) {
    state.model.isFiscalDifferent = isDifferent;
  },
  setSalesData(state, company) {
    state.model.offerRejectionRatio = company.offerRejectionRatio;
    state.model.acceptanceRate = company.acceptanceRate;
    state.model.totalBilled = company.totalBilled;
    state.model.salesDataUpdatedAt = company.salesDataUpdatedAt;
    state.model.averagePricePerOffer = company.averagePricePerOffer;
    state.model.averageAmountOfLeadsPerMonth =
      company.averageAmountOfLeadsPerMonth;
  },
  setNotes(state, notes) {
    state.model.notes = notes;
  },
  setName(state, name) {
    state.model.name = name;
  },
  setVatNumber(state, vat) {
    state.model.vatNumber = vat;
  },
  setStreet(state, street) {
    state.model.address.street = street;
  },
  setStreetNumber(state, nr) {
    state.model.address.streetNumber = nr;
  },
  setBox(state, box) {
    state.model.address.box = box;
  },
  setPostalCode(state, postalCode) {
    state.model.address.postalCode = postalCode;
  },
  setCityName(state, cityName) {
    state.model.address.cityName = cityName;
  },
  setCountry(state, country) {
    state.model.address.country = country;
  },
  setTelephoneNumbers(state, telephoneNubmers) {
    state.model.telephoneNumbers = telephoneNubmers;
  },
  setEmailAddresses(state, emailAddresses) {
    state.model.emailAddresses = emailAddresses;
  },
  setUrls(state, urls) {
    state.model.urls = urls;
  },
  setLanguage(state, language) {
    state.model.language = language;
  },
  setCategories(state, categories) {
    state.model.categories = categories;
  },
  setFiscalStreet(state, street) {
    state.model.fiscalAddress.street = street;
  },
  setFiscalStreetNumber(state, nr) {
    state.model.fiscalAddress.streetNumber = nr;
  },
  setFiscalBox(state, box) {
    state.model.fiscalAddress.box = box;
  },
  setFiscalPostalCode(state, postalCode) {
    state.model.fiscalAddress.postalCode = postalCode;
  },
  setFiscalCityName(state, cityName) {
    state.model.fiscalAddress.cityName = cityName;
  },
  setFiscalCountry(state, country) {
    state.model.fiscalAddress.country = country;
  },
  setFiscalName(state, name) {
    state.model.fiscalName = name;
  },
  setFiscalUrl(state, url) {
    state.model.fiscalUrl = url;
  },
  setFiscalEmail(state, email) {
    state.model.fiscalEmail = email;
  },
  setFiscalTelephone(state, telephone) {
    state.model.fiscalTelephone = telephone;
  },
  setFiscalAddressId(state, id) {
    state.model.fiscalAddress.id = id;
  },
  setPaymentTerm(state, paymentTerm) {
    state.model.paymentTerm = paymentTerm;
  },
  setRejectableTerm(state, rejectableTerm) {
    state.model.rejectableTerm = rejectableTerm;
  },
  setIsReseller(state, isReseller) {
    state.model.isReseller = isReseller;
  },
  setIsPaused(state, isPaused) {
    state.settings.isPaused = isPaused;
  },
  setPausedFrom(state, pausedFrom) {
    state.settings.pausedFrom = pausedFrom;
  },
  setPausedTill(state, pausedTill) {
    state.settings.pausedTill = pausedTill;
  },
  setFilterComment(state, filterComment) {
    state.model.filterComment = filterComment;
  },
  ...crudMutations,
  [SET_MODEL](state, { entities, id }) {
    const settings = {};
    settings.isPaused = entities.entity[id].isPaused;
    settings.pausedFrom = entities.entity[id].pausedFrom;
    settings.pausedTill = entities.entity[id].pausedTill;
    state.settings = settings;

    const initialModel = cloneDeep(entities.entity[id]);
    if (isNil(initialModel.address)) {
      initialModel.address = {};
      initialModel.address.id = null;
      initialModel.address.street = initialModel.street;
      initialModel.address.streetNumber = initialModel.streetNumber;
      initialModel.address.box = initialModel.box;
      initialModel.address.postalCode = initialModel.postalCode;
      initialModel.address.cityName = initialModel.cityName;
      initialModel.address.country = initialModel.country;
    } else {
      initialModel.address = clone(entities.address[initialModel.address]);
    }
    if (isNil(initialModel.fiscalAddress)) {
      initialModel.fiscalAddress = {};
      initialModel.fiscalAddress.id = null;
      initialModel.fiscalAddress.street = initialModel.fiscalStreet;
      initialModel.fiscalAddress.streetNumber = initialModel.fiscalStreetNumber;
      initialModel.fiscalAddress.box = initialModel.fiscalBox;
      initialModel.fiscalAddress.postalCode = initialModel.fiscalPostalCode;
      initialModel.fiscalAddress.cityName = initialModel.fiscalCityName;
      initialModel.fiscalAddress.country = initialModel.fiscalCountry;
    } else {
      initialModel.fiscalAddress = clone(
        entities.address[initialModel.fiscalAddress]
      );
    }

    //Adds empty fields for types that don't have a url yet
    const types = ["website", "facebook", "instagram", "other"];
    const initUrls = initialModel.urls.map(urlId => entities.url[urlId]);
    initialModel.urls = [];
    types.forEach(type => {
      const typeUrls = initUrls.filter(url => url.type === type);
      if (["other"].includes(type)) {
        //don't add a field for these types
        initialModel.urls.push(...typeUrls);
      } else {
        typeUrls.length
          ? initialModel.urls.push(...typeUrls)
          : initialModel.urls.push({ public: true, type: type });
      }
    });

    //uhh I dont want to do this.. give separate editable models its own state so I can just normalize this
    initialModel.billingData.country = initialModel.billingData.country?.id;

    Vue.set(state, "initialModel", initialModel);
    Vue.set(state, "model", cloneDeep(initialModel));
    setEntities(state, entities);
  },
  setLogs(state, { id, entities }) {
    state.log = Object.assign(state.log, entities.log);
    state.company = Object.assign(state.company, entities.company);
    state.model = Object.assign(state.model, entities.company[id]);
  },
  addLog(state, { id, logEntities }) {
    state.log = Object.assign(state.log, logEntities);
    state.model.logs.unshift(id);
  },

  //billing data
  setBillingVatNumber(state, vat) {
    state.model.billingData.vatNumber = vat;
  },
  setBillingStreet(state, street) {
    state.model.billingData.street = street;
  },
  setBillingStreetNumber(state, nr) {
    state.model.billingData.streetNumber = nr;
  },
  setBillingBox(state, box) {
    state.model.billingData.box = box;
  },
  setBillingPostalCode(state, postalCode) {
    state.model.billingData.postalCode = postalCode;
  },
  setBillingCity(state, city) {
    state.model.billingData.city = city;
  },
  setBillingCountry(state, country) {
    state.model.billingData.country = country;
  },
  setBillingName(state, name) {
    state.model.billingData.name = name;
  },
  setBillingUrl(state, url) {
    state.model.billingData.url = url;
  },
  setBillingEmailAddress(state, email) {
    state.model.billingData.emailAddress = email;
  },
  setBillingTelephoneNumber(state, telephone) {
    state.model.billingData.telephoneNumber = telephone;
  },
  setIsAutoBilled(state, isAutoBilled) {
    state.model.isAutoBilled = isAutoBilled;
  },
  setCustomers(state, customers) {
    state.customers = customers;
  },
  setInvoiceRemark(state, invoiceRemark) {
    state.model.billingData.invoiceRemark = invoiceRemark;
  }
};
