import { render, staticRenderFns } from "./FilterSpecifierQuestion.vue?vue&type=template&id=94ff1780&scoped=true&"
import script from "./FilterSpecifierQuestion.vue?vue&type=script&lang=js&"
export * from "./FilterSpecifierQuestion.vue?vue&type=script&lang=js&"
import style0 from "./FilterSpecifierQuestion.vue?vue&type=style&index=0&id=94ff1780&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94ff1780",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCheckbox,VChip,VFlex,VLayout,VSelect})
