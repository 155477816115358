<template>
  <v-container>
    reset password
  </v-container>
</template>
<script type="text/babel">
export default {
  name: "company-reset-password",
  components: {}
};
</script>
