import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import tableActions from "@/store/templates/table/actions";

const fetchCall = api.company.subject.fetchAll;

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    // OVERRIDE THE DEFAULT
    const companyId = context.rootState.company.model.id;
    return await tableActions.fetch(context, {
      fetchCall: fetchCall.bind(null, companyId)
    });
  },
  async deleteSubject({ dispatch }, { companyId, companySubjectId }) {
    await api.company.subject.remove({
      companyId,
      companySubjectId
    });
    dispatch(DISPATCH_FETCH);
  },
  async addSubject({ dispatch }, { companyId, subject }) {
    await api.company.subject.addSubject({
      companyId: companyId,
      subject: subject
    });
    dispatch(DISPATCH_FETCH);
  },
  async updatePrice({ dispatch }, { companyId, companySubjectId, price }) {
    await api.company.subject.updatePrice({
      companyId: companyId,
      companySubjectId: companySubjectId,
      price: price
    });
    dispatch(DISPATCH_FETCH);
  },
  async updateCountryPrice(
    { dispatch },
    { companyId, companySubjectId, countryCode, price }
  ) {
    await api.company.subject.updateCountryPrice({
      companyId: companyId,
      companySubjectId: companySubjectId,
      countryCode: countryCode,
      price: price
    });
    dispatch(DISPATCH_FETCH);
  }
};
