<template>
  <tr>
    <td class="text-xs-left">
      {{ company.name }}
    </td>
    <td class="text-xs-left">
      {{ filter.name }}
    </td>
    <td class="text-xs-right">
      <v-edit-dialog
        :return-value.sync="internalPercentage"
        large
        lazy
        persistent
        @save="save"
      >
        {{ percentage }}
        <div slot="input" class="mt-3 title">
          Update distribution percentage
        </div>
        <v-text-field
          slot="input"
          v-model.number="internalPercentage"
          :rules="[totalPercentageUnder101Rule]"
          label="Edit"
          single-line
          counter
          autofocus
        />
      </v-edit-dialog>
    </td>
    <td class="justify-xs-right px-0">
      <v-icon small @click="remove">
        delete
      </v-icon>
    </td>
  </tr>
</template>

<script type="text/babel">
export default {
  name: "filter-type-buldit-row",
  props: {
    filterId: {
      type: Number,
      required: true
    },
    percentage: {
      type: Number,
      required: false
    },
    remainingPercentage: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    filters: [],
    isLoading: false,
    selectedFilter: null,
    search: null,

    changedPercentage: null,

    snack: false,
    snackColor: "",
    snackText: "",
    pagination: {},
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: "Company",
        align: "left",
        sortable: false,
        value: "company"
      },
      { text: "Percentage (%)", value: "percentage" }
    ]
  }),
  computed: {
    internalPercentage: {
      get() {
        return this.percentage;
      },
      set(percentage) {
        this.changedPercentage = percentage;
      }
    },
    filter() {
      return this.$store.state.filter.filter[this.filterId];
    },
    company() {
      return this.$store.state.filter.company[this.filter.company];
    }
  },
  watch: {
    percentage: {
      immediate: true,
      handler(newValue) {
        this.changedPercentage = newValue;
      }
    }
  },
  methods: {
    save() {
      const percentage = this.changedPercentage || this.percentage;
      if (this.isTotalPercentageUnder101(percentage)) {
        this.$emit("set-percentage", { filterId: this.filterId, percentage });
      }
    },
    remove() {
      confirm("Are you sure you want to delete this item?") &&
        this.$emit("remove", { filterId: this.filterId });
    },
    isTotalPercentageUnder101(percentage) {
      const totalPercentage = parseInt(percentage) + this.remainingPercentage;
      return totalPercentage < 101;
    },
    totalPercentageUnder101Rule(percentage) {
      return (
        this.isTotalPercentageUnder101(percentage) ||
        `Total percentage exceeds 100. (${parseInt(percentage) +
          this.remainingPercentage})`
      );
    }
  }
};
</script>
