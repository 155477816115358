<template>
  <v-flex :xs6="type === null" :xs12="isCompanyFilter" class="text-xs-center">
    <v-layout row wrap>
      <v-flex xs12 class="text-xs-center">
        <v-btn
          v-if="isCompanyFilter && !isPersisted"
          flat
          icon
          @click="$emit('reset')"
        >
          <v-icon>undo</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          :disabled="type !== null"
          @click="$emit('set', { type: 'COMPANY' })"
        >
          Company Filter
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout v-if="isCompanyFilter" row wrap>
      <v-flex xs12>
        <v-autocomplete-company
          customer
          :disabled="isPersisted"
          v-model="company"
          return-object
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script type="text/babel">
import VAutocompleteCompany from "@/components/company/VAutocompleteCompany";
import { createNamespacedHelpers } from "vuex";
import { createNamespacedHelpers as createNamespacedComputedHelpers } from "@/store/helpers";

const { mapComputed } = createNamespacedComputedHelpers("filter");
const { mapGetters } = createNamespacedHelpers("filter");

export default {
  name: "filter-type-company",
  components: { VAutocompleteCompany },
  data: () => ({
    companies: [],
    isLoading: false,
    selectedCompany: null,
    search: null
  }),
  computed: {
    ...mapGetters(["isPersisted", "type", "isCompanyFilter"]),
    ...mapComputed(["company"])
  }
  // beforeMount() {
  //   if (
  //     !isNil(this.filter) &&
  //     !isNil(this.filter.id) &&
  //     !isNil(this.filter.company) &&
  //     this.companies.length === 0
  //   ) {
  //     this.companies.push(
  //       this.$store.state.entities.company[this.filter.company]
  //     );
  //   }
  // },
};
</script>
