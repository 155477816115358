<template>
  <div v-if="state === 'DRAFT'">
    <v-fab-transition>
      <v-btn
        color="success"
        round
        dark
        fixed
        bottom
        right
        large
        :disabled="isUpdatingItem"
        :loading="isUpdatingItem"
        @click="openFinalizeDialog"
      >
        Finalize
      </v-btn>
    </v-fab-transition>
    <finalize-invoice-dialog
      :dialog="finalizeDialog"
      @close="finalizeDialog = false"
    />
  </div>
  <div v-else-if="internalActions.length === 1">
    <v-fab-transition>
      <v-btn
        v-show="!internalActions[0].hidden"
        fab
        bottom
        right
        dark
        fixed
        :color="internalActions[0].color"
        :to="internalActions[0].to"
        :href="internalActions[0].href"
      >
        <v-icon class="display-center">{{ internalActions[0].icon }}</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
  <v-speed-dial
    v-else-if="internalActions.length > 1"
    v-model="fab"
    bottom
    right
    fixed
    direction="top"
    transition="slide-y-reverse-transition"
  >
    <template v-slot:activator>
      <v-btn v-model="fab" color="pink" dark fab>
        <v-icon>more_vert</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
    </template>
    <v-tooltip
      v-for="(action, index) in internalActions"
      :key="index"
      v-model="show"
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :color="action.color" :to="action.to">
          <v-icon small>{{ action.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ action.tag }}</span>
    </v-tooltip>
  </v-speed-dial>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import FinalizeInvoiceDialog from "./FinalizeInvoiceDialog";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "invoice-actions",
  components: { FinalizeInvoiceDialog },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  data: () => {
    return {
      fab: false,
      show: true,
      finalizeDialog: false
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    isUpdatingItem() {
      return this.$store.getters["invoice/isUpdatingItem"];
    },
    invoiceDue() {
      return this.$store.getters["invoice/due"];
    },
    actions() {
      return [
        {
          color: "warning",
          tag: "Create credit note",
          rolesOrPermissions: "credit_note_create",
          icon: "fas fa-file-invoice-dollar",
          hidden: this.invoiceDue == 0,
          to: {
            name: this.isCompanyView
              ? "company.invoices.credit-notes.create"
              : "invoices.credit-notes.create",
            params: {
              invoiceId: this.invoiceId,
              companyId: this.companyId
            }
          }
        }
      ];
    },
    isCompanyView() {
      return !isNil(this.companyId);
    },
    invoiceId() {
      return this.$store.getters["invoice/id"];
    },
    state() {
      return this.$store.getters["invoice/state"];
    },
    internalActions() {
      return this.actions.filter(
        action =>
          this.hasRolesOrPermissions(action.rolesOrPermissions) &&
          !action.hidden
      );
    }
  },
  methods: {
    openFinalizeDialog() {
      this.finalizeDialog = true;
    }
  }
};
</script>
<style scoped>
.display-center {
  display: flex;
}
.tooltip-center {
  top: -15px;
}
</style>
